import apiService from "./apiService"; // Adjust the path as needed

// Define the interface for a single country
export interface Country {
  value: string; // Adjust the type based on actual data
  label: string;
}

// Define the response interface for getCountries
export interface RefCountriesResponse {
  data: Country[]; // Adjust based on actual response structure
}

// Example of how you might use it

export const getCountries = async (): Promise<RefCountriesResponse> => {
  try {
    const response = await apiService.get<RefCountriesResponse>(
      "/api/ref/country",
     
      { type: "select" },
     
    );
    return response; // Ensure you return response.data if the response object contains it
  } catch (error) {
    // Handle or rethrow the error as needed
    console.error("Get countries failed:", error);
    throw error;
  }
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.guessPrizeItemContainer{
    width: 90vw;
    height:auto;
    margin-top: 0.35rem;
    margin-right: 2.5vw;
    border-radius: 16px;
    border: 1.5px solid #FFA3F6;
}
`, "",{"version":3,"sources":["webpack://./src/components/Numbers/Guess/GuessPrizeCard/GuessResultItem/GuessResultItem.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;IACX,mBAAmB;IACnB,mBAAmB;IACnB,mBAAmB;IACnB,2BAA2B;AAC/B","sourcesContent":[".guessPrizeItemContainer{\n    width: 90vw;\n    height:auto;\n    margin-top: 0.35rem;\n    margin-right: 2.5vw;\n    border-radius: 16px;\n    border: 1.5px solid #FFA3F6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

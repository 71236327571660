import React from "react";
import "./DeleteSvg.css";

interface DeleteSvgProps {
  dir: string;
  float: string;
  isRelative?: boolean;
  color?: string;
  mr?: number; // Margin right
  mt?: number; // Margin top
  ml?: number; // Margin left
  mb?: number; // Margin bottom
  onClick?: () => void;
}

const DeleteSvg: React.FC<DeleteSvgProps> = ({
  dir,
  float,
  isRelative,
  color,
  mr,
  mt,
  ml,
  mb,
  onClick,
}) => {
  return (
    <div
      dir={dir}
      className={`${isRelative ? "deleteSvg-relative" : "deleteSvg"} ${float}`}
      style={{
        marginRight: mr,
        marginTop: mt,
        marginLeft: ml,
        marginBottom: mb,
      }}
      onClick={onClick}
    >
      <svg
        width="19"
        height="20"
        viewBox="0 0 19 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.3249 7.46777C16.3249 7.46777 15.7819 14.2028 15.4669 17.0398C15.3169 18.3948 14.4799 19.1888 13.1089 19.2138C10.4999 19.2608 7.88791 19.2638 5.27991 19.2088C3.96091 19.1818 3.13791 18.3778 2.99091 17.0468C2.67391 14.1848 2.13391 7.46777 2.13391 7.46777"
          stroke={color === "white" ? "white" : `#B80000`}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.7082 4.23926H0.750244"
          stroke={color === "white" ? "white" : `#B80000`}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.4407 4.23949C13.6557 4.23949 12.9797 3.68449 12.8257 2.91549L12.5827 1.69949C12.4327 1.13849 11.9247 0.750488 11.3457 0.750488H7.1127C6.5337 0.750488 6.0257 1.13849 5.8757 1.69949L5.6327 2.91549C5.4787 3.68449 4.8027 4.23949 4.0177 4.23949"
          stroke={color === "white" ? "white" : `#B80000`}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export default DeleteSvg;

import { Grid, Typography } from "@mui/material";
import "./home.css";
import { Image } from "@mui/icons-material";
import CounterPrizeComponent from "../../components/Numbers/Counter/CounterPrizeComponent";
import GuessPrizeCard from "../../components/Numbers/Guess/GuessPrizeCard/GuessPrizeCard";
import YButton from "../../components/Button/YButton";
import BottomArrowSvg from "../../components/SVG/BottomArrowSvg/BottomArrowSvg";
import GreenLightText from "../../components/Text/GreenLightText/GreenLightText";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getActiveLottery } from "../../services/lotteryService";
import ClipLoader from "react-spinners/ClipLoader";
import { loginTelegram } from "../../services/authService";

const Home = () => {
  const numberOfCounter = 6;
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [prizes, setPrizes] = useState<number[]>([]);
  const [prizeBalance, setPrizeBalance] = useState<number>(0);
  
  const [lotteryAllAmount, setLotteryAllAmount] = useState<number>(0);
  const [timer, setTimer] = useState<Date | undefined>();

  const [remainingTime, setRemainingTime] = useState<string>('');

  async function handleActiveLottery() {
    const active = await getActiveLottery();
    if (active?.activeLottery) {
      setLotteryAllAmount(active.totalPrice.amount)
      const lotteryDate = new Date(active.activeLottery.lottery_date);
      setTimer(lotteryDate);
    }
    if(!active?.status)
      setRemainingTime('قرعه کشی فعلا برگزار نشده است...');

    setLoading(false);
    setPrizeBalance(active?.totalPrice?.amount ?? 0);
    setPrizes([
      active?.prizeAmounts?.sixthPrizeAmount ?? 0,
      active?.prizeAmounts?.fifthPrizeAmount ?? 0,
      active?.prizeAmounts?.forthPrizeAmount ?? 0,
      active?.prizeAmounts?.thirdPrizeAmount ?? 0,
      active?.prizeAmounts?.secondPrizeAmount ?? 0,
    ]);
  }

  async function handleTelegramLogin(){
    // const login = await loginTelegram('7428308651', 'ma', 'fa');
    const login = await loginTelegram(window.chat_id, window.first_name, window.last_name);
    if(login!=null&&login.status){
       localStorage.setItem('token',login.token);
      //  window.location.reload();
    }
  }

  useEffect(() => {
    if (!timer) return;
  
    const interval = setInterval(() => {
      const now = new Date();
      const timeDiff = timer.getTime() - now.getTime();
  
      if (timeDiff <= 0) {
        clearInterval(interval);
        setRemainingTime('آماده سازی برای شروع قرعه کشی...');
        navigate('/fa/lottery')
        return;
      }
  
      const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
  
      let timeString = '';
      if (days > 0) timeString += `${days} روز `;
      if (hours > 0 || days > 0) timeString += `${hours} ساعت `;
      if (minutes > 0 || hours > 0 || days > 0) timeString += `${minutes} دقیقه `;
      if (seconds > 0 || minutes > 0 || hours > 0 || days > 0) timeString += `${seconds} ثانیه `;
      
      setRemainingTime(timeString);
    }, 1000); // Update interval set to 1000 ms (1 second)
  
    return () => clearInterval(interval);
  }, [timer]);


  useEffect(() => {
    handleActiveLottery();

    handleTelegramLogin()
  }, []);

  function handleGoTo() {
    navigate("/fa/payment/choose-number");
  }

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {loading ? (
            <ClipLoader color="white"  size={100} />
          ) : (
            <h1>Content Loaded</h1>
          )}
        </div>
      ) : (
        <>
         <Grid container mt={4}>
               <Typography variant="h2" color={'white'} fontSize={16} textAlign={'center'} width={'100%'}>{remainingTime}</Typography>
         </Grid>

          <Grid container spacing={2} mt={3} mb={3} pr={6} pl={6}>
            {/* <Grid item xs={6} sm={6}>
            <YButton title={"قرعه کشی"} onClick={handleGoTo} width={142} height={50} color={'secondary'} radius={'radius-secondary'}/>
        </Grid> 
        <Grid item xs={6} sm={6}>
          <YButton title={"ماین"} onClick={handleGoTo} width={142} height={50} color={'secondary'} radius={'radius-secondary'}/>
        </Grid> */}
          </Grid>

   
          <GreenLightText title={"مقدار جایزه"} />
      

          <Grid
            container
            spacing={{ xs: 1 }}
            columnGap={1}
            rowGap={1}
            pt={4}
            pr={1}
            pl={1}
            direction="row-reverse"
            justifyContent="center"
            alignItems="center"
          >
            
            <Grid item key={0}>
              <CounterPrizeComponent
                targetNumber={"$"}
                isMainPage={true}
                fontSize={16}
                hasAnimation={false}
              />
            </Grid>

            {prizeBalance.toFixed(0)
              .toString()
              .split("")
              .map((item, index) => (
                <Grid item key={index + 1}>
                  <CounterPrizeComponent
                    targetNumber={parseFloat(item)}
                    isMainPage={true}
                    fontSize={16}
                    hasAnimation={false}
                  />
                </Grid>
              ))}
          </Grid>

          {/* <Typography variant="h5" color={"white"} mb={8} classNameName="prize-money">
        600 میلیون 769 هزار تتر{" "}
      </Typography> */}

          <GuessPrizeCard guesses={[6, 5, 4, 3, 2]} prizes={prizes} />

          <BottomArrowSvg float={"cehte"} dir="ltr" />

          <YButton
            title="بریم حدس بزنیم"
            color={"secondary-outline"}
            onClick={handleGoTo}
            align={"center"}
            radius={"radius-primary"}
            width={'45vw'}
            height={45}
            mb={2}
          />

          {/* <Grid container spacing={2} mt={3} mb={5}>

      <Grid item xs={3} sm={3}>
        <YButton
          title="دعوت"
          color="secondary-outline"
          onClick={handleGoTo}
          align="center"
          radius="radius-primary"
          width={60}
          height={60}
        />
      </Grid>
      <Grid item xs={3} sm={3}>
        <YButton
          title="درآمد"
          color="secondary-outline"
          onClick={handleGoTo}
          align="center"
          radius="radius-primary"
          width={60}
          height={60}
        />
      </Grid>
      <Grid item xs={3} sm={3}>
        <YButton
          title="وظایف"
          color="secondary-outline"
          onClick={handleGoTo}
          align="center"
          radius="radius-primary"
          width={60}
          height={60}
        />
      </Grid>
      <Grid item xs={3} sm={3}>
        <YButton
          title="پروفایل"
          color="secondary-outline"
          onClick={handleGoTo}
          align="center"
          radius="radius-primary"
          width={60}
          height={60}
        />
      </Grid>
    </Grid> */}

          <BottomArrowSvg float={"right"} dir="rtl" />
        </>
      )}
    </>
  );
};

export default Home;

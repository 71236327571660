import { Outlet, Link, useNavigate } from "react-router-dom";
import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import MailIcon from "@mui/icons-material/Mail";
import "./layout.css";
import DrawerItem from "./../components/navDrawer/DrawerItem";
import MenuIcon from "@mui/icons-material/Menu";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { Typography } from "@mui/material";
import DisableZoom from "./../DisableZoom";
import drawerItems from "./drawerItems";
import { useEffect } from "react";
import AccountLabel from "../components/navDrawer/AccountLabel/AccountLabel";

interface WhiteLayoutProps {
  backgroundImage: string; // URL of the background image
  isGradient?: boolean; // URL of the background image
  isHideTopIcons?: boolean; // URL of the background image
}

const Layout: React.FC<WhiteLayoutProps> = ({
  backgroundImage,
  isGradient = true,
  isHideTopIcons = false,
}) => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  useEffect(() => {
    // if (localStorage.getItem("token") === null) navigate("/fa/auth/step1");
  }, []);

  const DrawerList = (
    <Box
      sx={{ width: "270px" }}
      role="presentation"
      className="drawerContainer"
      onClick={toggleDrawer(false)}
    >
      <List dir="rtl">
        {drawerItems.main.map((item, i) =>
          localStorage.getItem("token") === null && item.link === "/auth/step1" ? (
            <DrawerItem key={i} title={item.title} link={item.link}/>
          ):   item.link !== "/auth/step1"&&  item.link!=null?(
            <DrawerItem key={i} title={item.title} link={item.link}/>
          ):(
            ''
          )
        )}

      <div style={{  padding: '16px 0 16px 8px' }}>
        <AccountLabel label="حساب کاربری" />
      </div>
          
       {drawerItems.account.map((item, i) =>
          localStorage.getItem("token") === null && item.link === "/auth/step1" ? (
            <DrawerItem key={i} title={item.title} link={item.link}/>
          ):   item.link !== "/auth/step1"&&  item.link!=null?(
            <DrawerItem key={i} title={item.title} link={item.link}/>
          ):(
            ''
          )
        )}
      </List>

      <Divider />
    </Box>
  );

  return (
    <main
      className="mainContainer"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <section className={`main-container ${isGradient ? "gradient" : ""}`}>
        {!isHideTopIcons ? (
          <>
            <MenuIcon
              onClick={toggleDrawer(true)}
              sx={{ color: `${isGradient ? "white" : ""}`, fontSize: "2rem" }}
              className={"rightHambergerMenu"}
            ></MenuIcon>
            <ShareOutlinedIcon
              sx={{ color: `${isGradient ? "white" : ""}`, fontSize: "2rem" }}
              className={"leftShareMenu"}
            ></ShareOutlinedIcon>
          </>
        ) : (
          ""
        )}

        <Drawer open={open} anchor={"right"} onClose={toggleDrawer(false)}>
          {DrawerList}
        </Drawer>
        <Outlet />
      </section>
    </main>
  );
};

export default Layout;

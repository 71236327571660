// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blur-text {
    filter: blur(5px);
}

.blue-text {
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/Results/ResultItems/ResultItenNumber.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,kCAA0B;YAA1B,0BAA0B;IAC1B,YAAY;AAChB","sourcesContent":[".blur-text {\n    filter: blur(5px);\n}\n\n.blue-text {\n    backdrop-filter: blur(4px);\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

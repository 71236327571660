import React from 'react';
import styled from 'styled-components';

interface AccountLabelProps {
  label: string;
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Line = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: white; // Adjust the color to match your design
  margin: 0 10px;
`;

const Label = styled.span`
  color: white; // Adjust the color to match your design
`;

const AccountLabel: React.FC<AccountLabelProps> = ({ label }) => {
  return (
    <Container>
        <Label>{label}</Label>
        <Line />
    </Container>
  );
};

export default AccountLabel;

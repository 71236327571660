import { Button } from "@mui/base";
import React from "react";
import "./BigPrizeSvg.css";

interface BigPrizeSvgProps {
  dir: string;
  float: string;
  color?: string;
}
// { title, align, onClick }
// BigPrizeSvgProps
const BigPrizeSvg: React.FC<BigPrizeSvgProps> = ({
  dir,
  float,
  color,
}) => {
  return (
    <div dir={dir} className={` ${float}`}>
      <svg
        width="88"
        height="98"
        viewBox="0 0 88 98"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.4785 65.675C16.1358 63.6804 20.4086 61.3597 22.7822 60.4756L26.4899 67.0332L36.4001 69.219C35.1658 69.219 24.6569 74.1108 20.1072 76.5052V69.1437C17.8464 67.5189 15.4785 65.675 15.4785 65.675Z"
          fill="#531CB4"
        />
        <path
          d="M72.7383 65.675C72.081 63.6804 67.8082 61.3597 65.4346 60.4756L61.7269 67.0332L51.0826 69.219C52.3169 69.219 63.5599 74.1108 68.1095 76.5052V69.1437C70.3703 67.5189 72.7383 65.675 72.7383 65.675Z"
          fill="#531CB4"
        />
        <path
          d="M31.9969 64.1187L36.4014 69.219L26.4912 67.0331L31.9969 64.1187Z"
          fill="#7266F9"
        />
        <path
          d="M55.8558 64.1187L51.0842 69.219L61.7285 67.0331L55.8558 64.1187Z"
          fill="#7266F9"
        />
        <path
          d="M43.8014 0L55.5626 13.6616H32.0402L43.8014 0Z"
          fill="url(#paint0_linear_37_6871)"
        />
        <path
          d="M43.8031 77.9624L32.0419 64.3008L55.5643 64.3008L43.8031 77.9624Z"
          fill="url(#paint1_linear_37_6871)"
        />
        <g filter="url(#filter0_f_37_6871)">
          <path
            d="M43.8016 3.64258L45.1642 7.75479L49.3073 9.10722L45.1642 10.4597L43.8016 14.5719L42.439 10.4597L38.2959 9.10722L42.439 7.75479L43.8016 3.64258Z"
            fill="white"
          />
          <path
            d="M43.8016 3.96051L45.0692 7.78624L45.0851 7.83418L45.1331 7.84985L48.985 9.10722L45.1331 10.3646L45.0851 10.'85vw'3L45.0692 10.4282L43.8016 14.2539L42.5339 10.4282L42.518 10.'85vw'3L42.47 10.3646L38.6182 9.10722L42.47 7.84985L42.518 7.83418L42.5339 7.78624L43.8016 3.96051Z"
            stroke="#FAFF00"
            stroke-width="0.2"
          />
        </g>
        <path
          d="M77.8161 58.4714L60.0153 61.7502L71.7765 41.531L77.8161 58.4714Z"
          fill="url(#paint2_linear_37_6871)"
        />
        <path
          d="M9.79035 19.4905L27.5911 16.2117L15.8299 36.4309L9.79035 19.4905Z"
          fill="url(#paint3_linear_37_6871)"
        />
        <path
          d="M77.8138 19.4909L71.7742 36.4313L60.013 16.2121L77.8138 19.4909Z"
          fill="url(#paint4_linear_37_6871)"
        />
        <path
          d="M9.79071 58.472L15.8303 41.5316L27.5915 61.7507L9.79071 58.472Z"
          fill="url(#paint5_linear_37_6871)"
        />
        <path
          d="M73.7688 38.6165C73.7688 54.8355 60.5209 67.9899 44.171 67.9899C27.8211 67.9899 14.5732 54.8355 14.5732 38.6165C14.5732 22.3976 27.8211 9.24316 44.171 9.24316C60.5209 9.24316 73.7688 22.3976 73.7688 38.6165Z"
          fill="url(#paint6_linear_37_6871)"
          stroke="url(#paint7_linear_37_6871)"
        />
        <g filter="url(#filter1_i_37_6871)">
          <ellipse
            cx="44.1675"
            cy="38.617"
            rx="26.4273"
            ry="26.2303"
            fill="url(#paint8_linear_37_6871)"
          />
        </g>
        <g filter="url(#filter2_ii_37_6871)">
          <ellipse
            cx="44.1692"
            cy="38.6164"
            rx="22.0227"
            ry="21.8586"
            fill="url(#paint9_linear_37_6871)"
          />
        </g>
        <g filter="url(#filter3_d_37_6871)">
          <path
            d="M30.4649 32.997C30.2969 32.5455 30.8016 32.1409 31.2057 32.4031L52.4034 46.1598C52.8211 46.4309 52.6291 47.0792 52.1312 47.0792H36.0543C35.8455 47.0792 35.6586 46.9494 35.5858 46.7537L30.4649 32.997Z"
            fill="url(#paint10_linear_37_6871)"
          />
          <path
            d="M57.8822 32.9909C58.0519 32.5406 57.5498 32.1339 57.1446 32.3935L35.6626 46.1582C35.2421 46.4277 35.4329 47.0792 35.9323 47.0792H52.2248C52.4329 47.0792 52.6192 46.9503 52.6927 46.7556L57.8822 32.9909Z"
            fill="url(#paint11_linear_37_6871)"
          />
          <path
            d="M43.7257 29.4585C43.9086 29.0849 44.441 29.0849 44.6239 29.4585L52.9707 46.5114C53.1333 46.8436 52.8915 47.2312 52.5216 47.2312H35.828C35.4581 47.2312 35.2163 46.8436 35.3789 46.5114L43.7257 29.4585Z"
            fill="url(#paint12_linear_37_6871)"
          />
          <mask
            id="mask0_37_6871"
            maskUnits="userSpaceOnUse"
            x="35"
            y="29"
            width="19"
            height="19"
          >
            <path
              d="M43.7228 29.4585C43.9056 29.0849 44.4381 29.0849 44.621 29.4585L52.9678 46.5114C53.1304 46.8436 52.8885 47.2312 52.5187 47.2312H35.8251C35.4552 47.2312 35.2134 46.8436 35.376 46.5114L43.7228 29.4585Z"
              fill="url(#paint13_linear_37_6871)"
            />
          </mask>
          <g mask="url(#mask0_37_6871)">
            <g filter="url(#filter4_f_37_6871)">
              <ellipse
                cx="9.58158"
                cy="2.60031"
                rx="9.58158"
                ry="2.60031"
                transform="matrix(0.204188 0.978932 -0.979534 0.201279 50.1055 30.8857)"
                fill="#FFF5D2"
                fill-opacity="0.8"
              />
            </g>
          </g>
          <ellipse
            cx="44.1752"
            cy="27.1734"
            rx="1.68403"
            ry="1.67148"
            fill="url(#paint14_linear_37_6871)"
          />
          <ellipse
            cx="30.8671"
            cy="32.0355"
            rx="1.37784"
            ry="1.36757"
            fill="url(#paint15_linear_37_6871)"
          />
          <ellipse
            cx="57.413"
            cy="32.0355"
            rx="1.37784"
            ry="1.36757"
            fill="url(#paint16_linear_37_6871)"
          />
          <rect
            x="35.6377"
            y="48.5498"
            width="16.7493"
            height="2.26697"
            rx="1.13348"
            fill="url(#paint17_linear_37_6871)"
          />
        </g>
        <path
          d="M32.423 19.6729L32.968 21.3177L34.6253 21.8587L32.968 22.3997L32.423 24.0446L31.8779 22.3997L30.2207 21.8587L31.8779 21.3177L32.423 19.6729Z"
          fill="white"
        />
        <path
          d="M25.8183 12.3867L27.0901 16.2248L30.957 17.4871L27.0901 18.7493L25.8183 22.5874L24.5466 18.7493L20.6797 17.4871L24.5466 16.2248L25.8183 12.3867Z"
          fill="white"
        />
        <g filter="url(#filter5_d_37_6871)">
          <path
            d="M20.6191 57.7276L26.4919 67.0334C42.2748 58.6543 57.3237 64.8476 61.7283 67.0334L67.601 57.7276C65.9217 55.8022 58.8842 51.9088 44.1687 51.7376C29.4531 51.5665 22.3375 55.6596 20.6191 57.7276Z"
            fill="url(#paint18_linear_37_6871)"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_37_6871"
            x="35.2959"
            y="0.642578"
            width="17.0117"
            height="16.9292"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="1.5"
              result="effect1_foregroundBlur_37_6871"
            />
          </filter>
          <filter
            id="filter1_i_37_6871"
            x="17.7402"
            y="10.3867"
            width="52.8545"
            height="54.4604"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-2" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.616667 0 0 0 0 0.407 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_37_6871"
            />
          </filter>
          <filter
            id="filter2_ii_37_6871"
            x="22.1465"
            y="14.7578"
            width="44.0459"
            height="47.7173"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.7 0 0 0 0 0.465967 0 0 0 0 0.0116667 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_37_6871"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-2" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 0.72 0 0 0 0 0 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_innerShadow_37_6871"
              result="effect2_innerShadow_37_6871"
            />
          </filter>
          <filter
            id="filter3_d_37_6871"
            x="27.4893"
            y="25.502"
            width="33.3018"
            height="29.3149"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.358333 0 0 0 0 0.1935 0 0 0 0 0 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_37_6871"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_37_6871"
              result="shape"
            />
          </filter>
          <filter
            id="filter4_f_37_6871"
            x="40.3027"
            y="25.394"
            width="18.4238"
            height="30.7896"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="3"
              result="effect1_foregroundBlur_37_6871"
            />
          </filter>
          <filter
            id="filter5_d_37_6871"
            x="0.619141"
            y="41.7324"
            width="86.9814"
            height="55.3008"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="10" />
            <feGaussianBlur stdDeviation="10" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_37_6871"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_37_6871"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_37_6871"
            x1="38.9123"
            y1="5.46464"
            x2="48.6904"
            y2="5.46464"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.484631" stop-color="#FFE590" />
            <stop offset="0.488859" stop-color="#FFC700" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_37_6871"
            x1="48.6921"
            y1="72.4978"
            x2="38.914"
            y2="72.4978"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.484631" stop-color="#FFE590" />
            <stop offset="0.488859" stop-color="#FFC700" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_37_6871"
            x1="75.4926"
            y1="51.5366"
            x2="70.6582"
            y2="59.9729"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.484631" stop-color="#FFE590" />
            <stop offset="0.488859" stop-color="#FFC700" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_37_6871"
            x1="12.1139"
            y1="26.4253"
            x2="16.9483"
            y2="17.989"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.484631" stop-color="#FFE590" />
            <stop offset="0.488859" stop-color="#FFC700" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_37_6871"
            x1="70.6012"
            y1="18.0208"
            x2="75.4356"
            y2="26.4571"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.484631" stop-color="#FFE590" />
            <stop offset="0.488859" stop-color="#FFC700" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_37_6871"
            x1="17.0033"
            y1="59.9421"
            x2="12.1689"
            y2="51.5058"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.484631" stop-color="#FFE590" />
            <stop offset="0.488859" stop-color="#FFC700" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_37_6871"
            x1="24.8776"
            y1="16.403"
            x2="64.6752"
            y2="61.1261"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FFECA9" />
            <stop offset="0.237301" stop-color="#FFD600" />
            <stop offset="1" stop-color="#FF9900" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_37_6871"
            x1="44.171"
            y1="8.74316"
            x2="44.171"
            y2="68.4899"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" stop-opacity="0.5" />
            <stop offset="1" stop-color="white" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_37_6871"
            x1="27.2834"
            y1="18.58"
            x2="60.7525"
            y2="59.2644"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FFC700" />
            <stop offset="1" stop-color="#D58500" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_37_6871"
            x1="29.5954"
            y1="20.6152"
            x2="47.5074"
            y2="60.5755"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FFB13C" />
            <stop offset="0.484375" stop-color="#B45F1D" />
            <stop offset="1" stop-color="#743110" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_37_6871"
            x1="31.386"
            y1="35.9867"
            x2="40.2779"
            y2="42.5021"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FFE176" />
            <stop offset="1" stop-color="#FFA800" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_37_6871"
            x1="56.0351"
            y1="34.1048"
            x2="50.6796"
            y2="43.817"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FFE483" />
            <stop offset="1" stop-color="#F29100" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_37_6871"
            x1="39.4948"
            y1="38.1621"
            x2="48.9885"
            y2="38.1621"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.49738" stop-color="#FCE77B" />
            <stop offset="0.49738" stop-color="#FFCC1D" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_37_6871"
            x1="39.4919"
            y1="38.1621"
            x2="48.9856"
            y2="38.1621"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.49738" stop-color="#FCE77B" />
            <stop offset="0.49738" stop-color="#FFCC1D" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_37_6871"
            x1="44.1752"
            y1="25.502"
            x2="44.1752"
            y2="28.8449"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FFCD1D" />
            <stop offset="1" stop-color="#FF971D" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_37_6871"
            x1="30.8671"
            y1="30.668"
            x2="30.8671"
            y2="33.4031"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FFCD1D" />
            <stop offset="1" stop-color="#FF971D" />
          </linearGradient>
          <linearGradient
            id="paint16_linear_37_6871"
            x1="57.413"
            y1="30.668"
            x2="57.413"
            y2="33.4031"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FFCD1D" />
            <stop offset="1" stop-color="#FF971D" />
          </linearGradient>
          <linearGradient
            id="paint17_linear_37_6871"
            x1="35.828"
            y1="49.6833"
            x2="52.5773"
            y2="49.6833"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FDE07C" />
            <stop offset="1" stop-color="#FDE07C" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint18_linear_37_6871"
            x1="23.1329"
            y1="60.5783"
            x2="65.4331"
            y2="60.1069"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#805BEB" />
            <stop offset="1" stop-color="#523AE3" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default BigPrizeSvg;

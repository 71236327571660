import { Grid } from '@mui/material';
import './GuessResultItem.css';
import { t } from 'i18next';
import YTypoGraphy from '../../../../Text/YTypography/YTypoGraphy';
import PrizeCircle from '../../PrizeCircle/PrizeCircle';
import PrizePersianCurrency from '../../PrizePersianCurrency/PrizePersianCurrency';

interface GuessResultItemProps {
  count?: number;
  numbers?: number;
  mt?: number;
  mr?: number | string;
  mb?: number;
  ml?: number;
  pt?: number;
  pr?: number;
  pb?: number;
  pl?: number;
  m?: number;
  p?: number;
}

const GuessResultItem = ({
    count=1,numbers=0, mt, mr, mb, ml,  // margin
    pt, pr, pb, pl,  // padding
    m, p // overall margin and padding
}: GuessResultItemProps): JSX.Element => {
  return (
    <section
      className='guessPrizeItemContainer'
      style={{
        marginTop: mt, marginRight: mr, marginBottom: mb, marginLeft: ml,
        paddingTop: pt, paddingRight: pr, paddingBottom: pb, paddingLeft: pl,
        margin: m, padding: p
      }}
    >
      {/* <div className='content'> */}
        <Grid container spacing={{ xs: 6 }} pt={1} pb={1} pr={3} pl={3}>
          <Grid item xs={6}>
            
            <PrizeCircle key={0} count={count} mt={1}/>
            {/* {guesses.map((count, index) => (
              <PrizeCircle key={index} count={count} mt={index === 0 ? 2 : 3} />
            ))} */}
          </Grid>
          <Grid item xs={6}>


            <YTypoGraphy color={'white'} textAlign={'left'} mt={1}>{numbers!=0?numbers+" "+t('person'): t('no_one') } </YTypoGraphy>
            {/* {prizes.map((amount, index) => (
              <PrizePersianCurrency key={index} align='center' amount={amount} mt={index === 0 ? 3 : 4} />
            ))} */}
          </Grid>
        </Grid>
      {/* </div> */}
    </section>
  );
};

export default GuessResultItem;

import { Outlet, Link, useNavigate } from "react-router-dom";
import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import MailIcon from '@mui/icons-material/Mail';
import './layout.css';
import DrawerItem from "./../components/navDrawer/DrawerItem";
import MenuIcon from '@mui/icons-material/Menu';
import drawerItems from "./drawerItems";
import YImage from "../components/Image/YImage";
import { useEffect } from "react";
import AccountLabel from "../components/navDrawer/AccountLabel/AccountLabel";


interface WhiteLayoutProps {
  backgroundImage: string; // URL of the background image
  isGradient?: boolean; // URL of the background image
}


const WhiteLayout: React.FC<WhiteLayoutProps> = ({ backgroundImage,isGradient=true }) => {

    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    
    const toggleDrawer = (newOpen: boolean) => () => {
      setOpen(newOpen);
    };

    
    useEffect(() => {
      // if (localStorage.getItem("token") === null) 
      //   navigate('/fa/auth/step1');
    }, []);

    
    const DrawerList = (
      <Box sx={{ width: '270px' }} role="presentation" className='drawerContainer' onClick={toggleDrawer(false)}>
        <List dir='rtl'>

        {drawerItems.main.map((item, i) =>
          localStorage.getItem("token") === null && item.link === "/auth/step1" ? (
            <DrawerItem key={i} title={item.title} link={item.link}/>
          ):   item.link !== "/auth/step1"&&  item.link!=null?(
            <DrawerItem key={i} title={item.title} link={item.link}/>
          ):(
            ''
          )
        )}

      <div style={{  padding: '16px 0 16px 8px' }}>
        <AccountLabel label="حساب کاربری" />
      </div>
          
       {drawerItems.account.map((item, i) =>
          localStorage.getItem("token") === null && item.link === "/auth/step1" ? (
            <DrawerItem key={i} title={item.title} link={item.link}/>
          ):   item.link !== "/auth/step1"&&  item.link!=null?(
            <DrawerItem key={i} title={item.title} link={item.link}/>
          ):(
            ''
          )
        )}
        </List>

        <Divider />
   

      </Box>
    );
  
    return (
      <main className="mainContainer gray" style={{ backgroundImage: `url(${backgroundImage})` }}>
       
        <section className={`main-container ${isGradient?'gradient':''}`}>

          <MenuIcon onClick={toggleDrawer(true)} sx={{color:'rgba(47, 47, 47, 1)',fontSize:'2rem'}} className={'rightHambergerMenu'}></MenuIcon>
          <YImage src="/images/icons/knight-helmet-armor-black.png"  alt={'logo'} width={50} height={63} className={'leftShareMenu'} mt={0.2} ml={0.5}/>

          <Drawer open={open} anchor={'right'} onClose={toggleDrawer(false)}>
            {DrawerList}
          </Drawer>
          <Outlet />
        </section>
      </main>

      
    );


};

export default WhiteLayout;
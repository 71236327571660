import { useNavigate } from 'react-router-dom';
import './DrawerItem.css';

const DrawerItem = (props:any) => {

  const navigate = useNavigate();

    return <li className='drawer-item' onClick={()=>navigate(`/fa${props.link}`)}> <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="6" cy="6" r="6" fill="white"/>
              </svg>{props.title}     
           </li>;
  };
  
  export default DrawerItem;
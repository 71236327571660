import apiService from "./apiService"; // Adjust the path as needed


// Interface for a single lottery result
interface LotteryResult {
    id: number;
    lottery_date: string; // Use Date if you plan to parse the string into a Date object
    lottery_status: "FINISHED_LOTTERY" | string; // Or replace string with any other possible statuses
    default_number: number;
    ticket_price: number;
    number_of_wins: number;
    lottery_numbers: number[];
    winners_total_amount: number;
}

export interface WinsMember {
  user_id: number;
  firstname: string;
  lastname: string;
  gender: string;
  username: string;
  win_amount: number;
  ticket_count: string;
  wins_3: string;
}

export interface LotteryResultsResponse {
  results: LotteryResult[];
  wins3: WinsMember[];
  wins4: WinsMember[];
  wins5: WinsMember[];
  status: boolean;
}

// Interface for Lottery
export interface Lottery {
    id: number;
    lottery_date: string; // Use `Date` if you're working with Date objects
    lottery_numbers: number[]; // Use `Date` if you're working with Date objects
    ticket_price: number;
    number_of_wins: number | null;
    winners_total_amount: number;
  }
  
  // Interface for Ticket
  export interface Ticket {
    id: number;
    number_1: number;
    number_2: number;
    number_3: number;
    number_4: number;
    number_5: number;
    number_6: number;
    numbers_wins: number[];
    win_amount: number;
    lottery_status: "FINISHED_LOTTERY"|"WATING_FOR_START_LOTTERY";
    lottery_id: number;
    user_id: number;
    lottery: Lottery; // Nested Lottery object
  }
  
  // Interface for the response containing multiple tickets
  export interface LotteryMyResultsResponse {
    results: Ticket[];
    status: boolean;
  }

export const getAllResulsts = async (): Promise<LotteryResultsResponse | null> => {
    try {
      const response = await apiService.get<LotteryResultsResponse>(
        "/api/app/lottery/results/all"
      );
      return response; // Assuming `response.data` contains the UserlotteryResponse
    } catch (error) {
      console.error("get results failed:", error);
      return null;
    }
  };
  

export const getMyResulsts = async (): Promise<LotteryMyResultsResponse | null> => {
    try {
      const response = await apiService.get<LotteryMyResultsResponse>(
        "/api/app/lottery/results/my-results"
      );
      return response; // Assuming `response.data` contains the UserlotteryResponse
    } catch (error) {
      console.error("get results failed:", error);
      return null;
    }
};
  
import React, { useState, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import YButton from "../../../components/Button/YButton";
import YTextField from "../../../components/TextField/YTextField";
import {  UserProfileResponse } from "../../../services/authService";
import { getCountries, RefCountriesResponse } from "../../../services/refService";

interface ProfileBottomSheetProps {
  handleUpdate: (data: UserProfileResponse | null) => void;
  data: UserProfileResponse | null;
}

const ProfileBottomSheet: React.FC<ProfileBottomSheetProps> = ({ handleUpdate, data }) => {
  const [profile, setProfile] = useState<UserProfileResponse | null>(data);
  const [countries, setCountries] = useState<RefCountriesResponse | null>(null);

  const fetchCountries = async () => {
    try {
      const countryData = await getCountries();
      setCountries(countryData);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const handleChange = (field: keyof UserProfileResponse['user'] | keyof UserProfileResponse['country_name']) => (value: string) => {
    if (profile) {
      setProfile({
        ...profile,
        user: {
          ...profile.user,
          [field]: value,
        },
        country_name: {
          ...profile.country_name,
          [field]: value,
        },
      });
    }
  };

  // const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   handleChange('country_id')(event.target.value as string);
  // };

  const Update = () => {
    handleUpdate(profile);
  };

  if (!profile || !countries) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <YTextField
        label="جنسیت"
        fullWidth
        select
        value={profile.user.gender || ''}
        setValue={handleChange('gender')}
        options={[
          { value: "MALE", label: "آقا" },
          { value: "FEMALE", label: "حانم" },
        ]}
        SelectProps={{
          IconComponent: ExpandMoreIcon,
        }}
      />

      <YTextField
        label="نام کاربری"
        value={profile.user.username || ''}
        setValue={handleChange('username')}
        type="tel"
      />

      <YTextField
        label="ایمیل"
        value={profile.user.email || ''}
        setValue={handleChange('email')}
        type="email"
      />

      <YTextField
        label="کشور"
        type="select"
        select
        fullWidth
        setDefaultID={profile.user.country_id} 
        options={countries.data} 
        value={profile.country_name?.fa_name || ''}
        setValue={handleChange('fa_name')}
      />

      <YButton
        title="ذخیره"
        color="secondary"
        width={'85vw'}
        height={50}
        border="#000 solid"
        onClick={Update}
      />
    </>
  );
};

export default ProfileBottomSheet;

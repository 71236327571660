import { Grid }  from "@mui/material";
import "./MyResults.css";
import InfoSvg from "../../../components/SVG/InfoSvg/InfoSvg";
import YButton from "../../../components/Button/YButton";
import NoParticipationSvg from "../../../components/SVG/Results/NoParticipation/NoParticipationSvg";
import React, { useEffect, useState } from "react";
import ResultItem from "../../../components/Results/ResultItems/ResultItem";
import {
  getMyResulsts,
  LotteryMyResultsResponse,
  LotteryResultsResponse,
} from "../../../services/resultsService";
import { useNavigate } from "react-router-dom";
import { toPersianDate } from "../../../utility/dateUtils";
import { getActiveLottery } from "../../../services/lotteryService";
import YTypoGraphy from "../../../components/Text/YTypography/YTypoGraphy";
import { t } from "i18next";

const MyResults = () => {
  const navigate = useNavigate();
  // const [results, setResults] = useState<any[]>([]);
  const [lotteryResults, setLotteryResults] =
    useState<LotteryMyResultsResponse>({
      results: [],
      status: true,
    });
  const [timer, setTimer] = useState<Date | undefined>();
  const [remainingTime, setRemainingTime] = useState<string>("");

  async function handleLotteryResults() {
    const fetchedResults: LotteryMyResultsResponse | null =
      await getMyResulsts();

    if (fetchedResults !== null) {
      setLotteryResults(fetchedResults);
    }
  }

  function handleGuessNumber() {
    navigate("/fa/payment/choose-number");
  }

  async function handleActiveLottery() {
    const active = await getActiveLottery();
    if (active?.activeLottery) {
      const lotteryDate = new Date(active.activeLottery.lottery_date);
      setTimer(lotteryDate);
    }
    if(!active?.status)
      setRemainingTime('قرعه کشی فعلا برگزار نشده است...');
  }


  useEffect(() => {
    if (!timer) return;

    const interval = setInterval(() => {
      const now = new Date();
      const timeDiff = timer.getTime() - now.getTime();

      if (timeDiff <= 0) {
        clearInterval(interval);
        setRemainingTime("آماده سازی برای شروع قرعه کشی...");
        return;
      }

      const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

      let timeString = "";
      if (days > 0) timeString += `${days} روز `;
      if (hours > 0 || days > 0) timeString += `${hours} ساعت `;
      if (minutes > 0 || hours > 0 || days > 0)
        timeString += `${minutes} دقیقه `;
      if (seconds > 0 || minutes > 0 || hours > 0 || days > 0)
        timeString += `${seconds} ثانیه `;

      setRemainingTime(timeString);
    }, 1000); // Update interval set to 1000 ms (1 second)

    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    handleLotteryResults();
    handleActiveLottery()
  }, []);

  function handleChangeWinTab(link: string) {
    navigate(link);
  }

  return (
    <>
      <Grid container spacing={2} mb={0} p={2}>
        <Grid item xs={6}>
          <YButton
            mt={0}
            color={"secondary-outline"}
            align={"center"}
            radius={"radius-primary"}
            width={"45vw"}
            height={53}
            mb={0.5}
            title="نتایج من"
            onClick={() => handleChangeWinTab("/fa/lottery/my-results")}
          />
        </Grid>

        <Grid item xs={6}>
          <YButton
            mt={0}
            color={"secondary-outline-not-selected"}
            align={"center"}
            radius={"radius-primary"}
            width={"45vw"}
            height={53}
            mb={0.5}
            title="نتایج همه"
            onClick={() => handleChangeWinTab("/fa/lottery/all-results")}
          />
        </Grid>
      </Grid>

      {lotteryResults.results.length > 0 ? (
        <>
          {lotteryResults.results.map((item, index) => (
            <ResultItem
              key={index}
              date={toPersianDate(new Date(item.lottery.lottery_date))}
              luckyNumbers={
                item.lottery_status != "FINISHED_LOTTERY"
                  ? [11, 11, 11, 11, 11, 11]
                  : item.lottery?.lottery_numbers
              }
              userChoosedNumbers={[
                item.number_1,
                item.number_2,
                item.number_3,
                item.number_4,
                item.number_5,
                item.number_6,
              ]}
              userGuess={item?.numbers_wins}
              numberOfCorrectGuesses={item.numbers_wins?.length}
              amountWon={item.win_amount}
              isWin={item.numbers_wins?.length > 0}
              lotteryStatus={item.lottery_status}
              lotteryRemainDate={remainingTime}
            />
          ))}
        </>
      ) : (
        <>
          <Grid textAlign={"center"} mt={15}>
            <NoParticipationSvg dir={""} float={""} />
          </Grid>

          <YTypoGraphy variant="body1" color={'white'} textAlign={"center"} pr={5} pl={5}>
           هنوز مشارکتی نداشتی! 
          </YTypoGraphy>
          <YTypoGraphy variant="body1" color={'white'} textAlign={"center"} pr={5} pl={5}>
               اعداد رو حدس بزن و شانست رو امتحان کن
          </YTypoGraphy>
          <YButton
            mt={2}
            color={"secondary-outline"}
            align={"center"}
            radius={"radius-primary"}
            width={"45vw"}
            height={50}
            mb={0.5}
            onClick={handleGuessNumber}
            title={t('guess_number')}
          />
        </>
      )}
    </>
  );
};

export default MyResults;

import { Outlet, useNavigate } from "react-router-dom";
import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { useEffect } from "react";
import YTypoGraphy from "../components/Text/YTypography/YTypoGraphy";
import AccountLabel from "../components/Menu/navDrawer/AccountLabel/AccountLabel";
import BottomNav from "../components/Menu/bottomNavigation/BottomNav";
import DrawerItem from "../components/Menu/navDrawer/DrawerItem";
import drawerItems from "./drawerItems";
import "./layout.css";
import LanguageSelector from "../components/LanguageSelector/LanguageSelector";
import { useTranslation } from "react-i18next";

interface WhiteLayoutProps {
  backgroundImage: string; // URL of the background image
  isGradient?: boolean;
  isHideTopIcons?: boolean;
  topTitle?: string;
}

const Layout: React.FC<WhiteLayoutProps> = ({
  backgroundImage,
  isGradient = true,
  isHideTopIcons = false,
  topTitle
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const toggleDrawer = (newOpen: boolean) => () => {
    navigate("/fa/menu");
    // setOpen(newOpen);
  };

  useEffect(() => {
    // Uncomment the line below to navigate to auth page if token is not found
    // if (localStorage.getItem("token") === null) navigate("/fa/auth/step1");
  }, [navigate]);

  const DrawerList: React.ReactNode = (
    <Box
      sx={{ width: "270px" }}
      role="presentation"
      className="drawerContainer"
      onClick={toggleDrawer(false)}
    >
      <List dir="rtl">
        {drawerItems.main.map((item, i) => {
          if (localStorage.getItem("token") === null && item.link === "/auth/step1") {
            return <DrawerItem key={i} title={item.title} link={item.link} />;
          } else if (item.link !== "/auth/step1" && item.link != null) {
            return <DrawerItem key={i} title={item.title} link={item.link} />;
          }
          return null;
        })}

        <div style={{ padding: "16px 0 16px 8px" }}>
          <AccountLabel label="حساب کاربری" />
        </div>

        {drawerItems.account.map((item, i) => {
          if (localStorage.getItem("token") === null && item.link === "/auth/step1") {
            return <DrawerItem key={i} title={item.title} link={item.link} />;
          } else if (item.link !== "/auth/step1" && item.link != null) {
            return <DrawerItem key={i} title={item.title} link={item.link} />;
          }
          return null;
        })}
      </List>

      <Divider />
    </Box>
  );

  return (
    <main
      className="mainContainer"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <section className={`main-container ${isGradient ? "gradient" : ""}`}>
        {!isHideTopIcons && (
          <Grid container>
            <svg
              onClick={toggleDrawer(true)}
              style={{
                color: `${isGradient ? "white" : ""}`,
                fontSize: "2rem",
              }}
              className="rightHambergerMenu"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.666 7V6C22.666 3.79086 20.8752 2 18.666 2H6.66602C4.45688 2 2.66602 3.79086 2.66602 6V7C2.66602 9.20914 4.45688 11 6.66602 11L18.666 11C20.8752 11 22.666 9.20914 22.666 7ZM21.166 6V7C21.166 8.38071 20.0467 9.5 18.666 9.5L6.66602 9.5C5.2853 9.5 4.16602 8.38071 4.16602 7V6C4.16602 4.61929 5.2853 3.5 6.66602 3.5L18.666 3.5C20.0467 3.5 21.166 4.61929 21.166 6Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.666 18V17C22.666 14.7909 20.8752 13 18.666 13H6.66602C4.45688 13 2.66602 14.7909 2.66602 17V18C2.66602 20.2091 4.45688 22 6.66602 22H18.666C20.8752 22 22.666 20.2091 22.666 18ZM21.166 17V18C21.166 19.3807 20.0467 20.5 18.666 20.5H6.66602C5.2853 20.5 4.16602 19.3807 4.16602 18V17C4.16602 15.6193 5.2853 14.5 6.66602 14.5H18.666C20.0467 14.5 21.166 15.6193 21.166 17Z"
                fill="white"
              />
            </svg>

            <YTypoGraphy
              variant="h2"
              color={"white"}
              fontFamily={"Poppins"}
              fontWeight={800}
              mt={2}
              textAlign={"center"}
              width={"74%"}
              mb={4}
            >
             {topTitle!=''?topTitle:t('Yashans')} 
            </YTypoGraphy>

            <LanguageSelector/>
          </Grid>
        )}
        <Outlet />
        <Drawer anchor={"right"} open={open} onClose={toggleDrawer(false)}>
          {DrawerList}
        </Drawer>
        <BottomNav />
      </section>
    </main>
  );
};

export default Layout;

import { API_BASE_URL, createHeaders,handleResponse,handleError } from "../utility/apiConfig";

const get = async <T>(url: string, params?: Record<string, any>): Promise<T> => {
  const queryString = params ? new URLSearchParams(params).toString() : '';
  const fullUrl = `${API_BASE_URL}${url}?${queryString}`;

  try {
    const response = await fetch(fullUrl, {
      method: 'GET',
      headers: createHeaders(),
    });
    return await handleResponse<T>(response);
  } catch (error) {
    handleError(error as Error);
    return Promise.reject(error); // Ensure the function returns a promise rejection
  }
};

const post = async <T>(url: string, data?: Record<string, any>): Promise<T> => {
  try {
    const response = await fetch(`${API_BASE_URL}${url}`, {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify(data),
    });
    return await handleResponse<T>(response);
  } catch (error) {
    handleError(error as Error);
    return Promise.reject(error); // Ensure the function returns a promise rejection
  }
};

const put = async <T>(url: string, data?: Record<string, any>): Promise<T> => {
  try {
    const response = await fetch(`${API_BASE_URL}${url}`, {
      method: 'PUT',
      headers: createHeaders(),
      body: JSON.stringify(data),
    });
    return await handleResponse<T>(response);
  } catch (error) {
    handleError(error as Error);
    return Promise.reject(error); // Ensure the function returns a promise rejection
  }
};

const del = async <T>(url: string): Promise<T> => {
  try {
    const response = await fetch(`${API_BASE_URL}${url}`, {
      method: 'DELETE',
      headers: createHeaders(),
    });
    return await handleResponse<T>(response);
  } catch (error) {
    handleError(error as Error);
    return Promise.reject(error); // Ensure the function returns a promise rejection
  }
};

const apiService = {
  get,
  post,
  put,
  del,
};

export default apiService;

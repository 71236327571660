import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import './mobileMenu.css';
import { Link } from 'react-router-dom';

interface MobileMenuProps {
  mt?: string | number;
  mb?: string | number;
  mr?: string | number;
  ml?: string | number;
  pt?: string | number;
  pb?: string | number;
  pr?: string | number;
  pl?: string | number;
  width?: string | number;
  height?: string | number;
  icon: React.ReactNode;  // To accept any icon component
  title: string;          // Title text
  description: string;    // Short description text
  link?: string;          // Link for navigation (optional)
}

const MobileMenu: React.FC<MobileMenuProps> = ({ 
  mt, 
  mb, 
  mr, 
  ml, 
  pt, 
  pb, 
  pr, 
  pl, 
  width, 
  height, 
  icon, 
  title, 
  description,
  link,
}) => {
  useEffect(() => {
    // Add any side effects or logic if necessary
  }, []);

  const cardStyle: React.CSSProperties = {
    marginTop: mt,
    marginBottom: mb,
    marginRight: mr,
    marginLeft: ml,
    paddingTop: pt,
    paddingBottom: pb,
    paddingRight: pr,
    paddingLeft: pl,
    width: width,
    height: height,
    cursor: link ? 'pointer' : 'default',  // Add pointer cursor if link is present
  };

  const content = (
    <Box style={cardStyle}>
      <Grid container className='menu-item' height={'75px'} width={'90vw'} alignItems="center" justifyContent="space-between">
        {/* Icon */}
        <Grid item xs={2}>
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center', 
              width: 50, 
              height: 50, 
              mr:1,
              background: 'linear-gradient(180deg, #DD55FF 0%, #A43ABE 100%)',
              borderRadius: '8px' 
            }}
          >
            {icon} {/* Display the passed icon */}
          </Box>
        </Grid>

        {/* Title and Description */}
        <Grid item xs={10} color={'white'}>
          <Grid container direction="column" pr={2}>
            <Grid item pt={1}>
              <span style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>{title}</span>
            </Grid>
            <Grid item>
              <span style={{ fontSize: '0.9rem' }}>{description}</span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <>
      {link ? (
        <Link to={link} style={{ textDecoration: 'none', color: 'inherit' }}>
          {content}
        </Link>
      ) : (
        content
      )}
    </>
  );
};

export default MobileMenu;

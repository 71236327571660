import { Grid, Typography } from "@mui/material";
import "../Authentication.css";
import { Image } from "@mui/icons-material";
import CounterPrizeComponent from "../../../../components/Numbers/Counter/CounterPrizeComponent";
import GuessPrizeCard from "../../../../components/Numbers/Guess/GuessPrizeCard/GuessPrizeCard";
import YButton from "../../../../components/Button/YButton";
import BottomArrowSvg from "../../../../components/SVG/BottomArrowSvg/BottomArrowSvg";
import GreenLightText from "../../../../components/Text/GreenLightText/GreenLightText";
import { useNavigate } from "react-router-dom";
import YImage from "../../../../components/Image/YImage";
import BottomSheet from "../../../../components/BottomSheet/BottomSheet";
import { useState } from "react";
import ProfileBottomSheet from "../../Profile/ProfileBottomSheet";
import AuthBottomSheetSetPassword from "./AuthBottomSheetSetPassword";
import NotifyAlert from "../../../../components/NotifyAlert/NotifyAlert";

const SetPassword = () => {
  const numberOfCounter = 6;
  const navigate = useNavigate();

  function handleGoTo() {
    navigate("/fa/lottery");
  }

  const [open, setOpen] = useState(false);
  const [showAlert, setShowAlert] = useState({
    isEnable: false,
    message: "",
    status: false,
  });


  function handleCallback(
    message: string,
    token: string,
    status: boolean
  ) {
    setShowAlert({ isEnable: true, message: message, status: status });
    setTimeout(() => {
      if (status) navigate("/fa");
      // navigate("/fa/auth/password");
      else setShowAlert({ isEnable: false, message: message, status: status });
    }, 2000); // 2000 milliseconds = 2 seconds
  }


  return (
    <>

  {showAlert.isEnable ? (
        <NotifyAlert
          dir={""}
          float={""}
          color={showAlert.status ? `success` : "danger"}
          content={showAlert.message}
        />
      ) : (
        ""
      )}
      
      <Grid container spacing={2} mt={8}>
        <YImage
          src="/images/icons/knight-helmet-armor-white.png"
          alt={"logo"}
          width={146}
          height={197}
          className={"leftShareMenu"}
          isAuto={true}
        />
      </Grid>

      <BottomSheet
        title="رمز عبور"
        description="لطفا رمز عبور مورد نظر خود را وارد کنید"
        open={true}
        setOpen={setOpen}
        isTransparent={true}
        children={
          <AuthBottomSheetSetPassword
            handleUpdate={handleCallback}
          />
        }
      />

      <BottomArrowSvg float={"left"} dir="ltr" />

      <BottomArrowSvg float={"right"} dir="rtl" />
    </>
  );
};

export default SetPassword;

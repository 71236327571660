import React from "react";
import { Box, Typography, Paper, Card, Grid } from "@mui/material";
import ResultItemNumber from "./../ResultItems/ResultItemNumber";
import YButton from "../../Button/YButton";
import BigPrizeSvg from "../../SVG/Results/NoParticipation/BigPrizeSvg/BigPrizeSvg";

interface AllResultItemProps {
  date: string;
  luckyNumbers: number[];
  amountWon: number;
  WonCount: number;
  type: string;
}

const AllResultItem: React.FC<AllResultItemProps> = ({
  date,
  luckyNumbers,
  amountWon,
  WonCount,
  type,
}) => {
  return (
    <Paper
      elevation={2}
      sx={{ padding: 0, borderRadius: 4, maxWidth: "95%", margin: "16px auto" }}
    >
      <Box
        display={"flex"}
        sx={{
          background: `${
            type === "gold"
              ? "linear-gradient(90deg, #FFDA7C 0%, #FFA41B 23.92%, #FFDA7C 51.04%, #FFA41B 77.88%, #FFDA7C 100%);"
              : type === "silver"
              ? "linear-gradient(90deg, #CECECE 0%, #9D9D9D 23.92%, #CECECE 51.04%, #9D9D9D 77.88%, #CECECE 100%);"
              : ""
          }`,
          color: "black",
          padding: 1,
          textAlign: "center",
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
        }}
      >
    
        <Typography variant="h6" textAlign={"left"} width={"58%"}>
          {amountWon.toLocaleString()} تتر
        </Typography>
      </Box>

      <Box
        display={"flex"}
        sx={{
          color: "black",
          padding: 1,
        }}
      >
        <Box display={"flex"} width={"100%"}>

          {type === "gold" ? <BigPrizeSvg dir={""} float={""} /> : ""}

          <Box width={"100%"} mr={1}>
            <Typography variant="h6" textAlign={"right"} width={"95%"} mt={2}>
             {type==='gold'?'جایزه بزرگ':'سایر جوایز'}
            </Typography>
            <Typography
              variant="body1"
              textAlign={"right"}
              width={"95%"}
              mt={1}
              mb={0}
            >
              {WonCount} برنده
            </Typography>
          </Box>
        </Box>

        <Typography
          variant="subtitle1"
          textAlign={"left"}
          width={"95%"}
          mt={2}
          mb={0}
          ml={2}
        >
          تاریخ {date}
        </Typography>
      </Box>

      <Box sx={{ padding: 1, marginTop: type === "gold" ? -4: -2 }}>
        <Card
          sx={{
            backgroundColor: "rgba(239, 239, 239, 1)",
            width: "264px",
            textAlign: "center",
            margin: "8px auto",
            display: "block",
            borderRadius: "16px",
          }}
        >
          <Typography variant="h3" fontSize={"1rem"} mt={2}>
            اعداد شانس
          </Typography>
          <Grid container spacing={1} p={2}>
            {luckyNumbers.slice().reverse().map((number, index) => (
              <Grid item>
                <ResultItemNumber
                  luckyNumbers={luckyNumbers}
                  userGuess={[]}
                  defaultColor={"rgba(47, 47, 47, 1)"}
                  number={number}
                  index={index}
                  isBlur={false}
                />
              </Grid>
            ))}
          </Grid>
        </Card>

        <Grid container spacing={1}>
          <Grid item xs={4} textAlign={"left"}></Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default AllResultItem;

import React, { useEffect, useState } from "react";
import { Card, Avatar, Grid, Box, Button } from "@mui/material";
import { styled } from "@mui/system";
import BottomArrowSvg from "../../../components/SVG/BottomArrowSvg//BottomArrowSvg";
import "./Profile.css";
import YButton from "../../../components/Button/YButton";
import ProfileBottomSheet from "./ProfileBottomSheet";
import BottomSheet from "../../../components/BottomSheet/BottomSheet";
import {
  getProfile,
  updateProfile,
  UserProfileResponse,
} from "../../../services/authService";
import NotifyAlert from "../../../components/NotifyAlert/NotifyAlert";
import YTypoGraphy from "../../../components/Text/YTypography/YTypoGraphy";

const PageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  // width:'100vw',
  backgroundColor: "#f7f8fa",
}));

const ProfileCardContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: "2rem",
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
}));

const ProfileInfo = styled(Card)(({ theme }) => ({
  // margin: '0 auto',
  display: "block",
  padding: "32px 16px 8px 32px",
  width: "92vw",
  maxWidth: 400,
  borderRadius: "20px",
  marginRight: "16px",
  boxShadow: "0px 12px 32px 0px rgba(0, 0, 0, 0.05)",
}));

const ProfileAvatar = styled(Avatar)({
  width: 200,
  height: 200,
});

const Profile: React.FC = () => {
  const [open, setOpen] = useState(false);

  const [profile, setProfile] = useState<UserProfileResponse | null>(null);
  const [showAlert, setShowAlert] = useState({
    isEnable: false,
    message: "",
    status: false,
  });

  const fetchProfile = async () => {
    try {
      const profileData = await getProfile();
      setProfile(profileData);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  async function handleCallbackUpdateProfile(data: UserProfileResponse | null) {
    try {
      const profileData = await updateProfile(data);
      await fetchProfile();
      setOpen(false);
      setShowAlert({
        isEnable: true,
        message: profileData!.message,
        status: profileData!.status,
      });
      setTimeout(() => {
        setShowAlert({
          isEnable: false,
          message: profileData!.message,
          status: profileData!.status,
        });
      }, 2000); // 2000 milliseconds = 2 seconds
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  }

  return (
    <Box className="container">
      {showAlert.isEnable ? (
        <NotifyAlert
          dir={""}
          float={""}
          color={showAlert.status ? `success` : "danger"}
          content={showAlert.message}
        />
      ) : (
        ""
      )}

      <BottomArrowSvg float={"left"} dir="ltr" />
      <ProfileCardContainer className="profile-card">
        <ProfileAvatar
          alt={profile?.user.firstname || ""}
          sx={{ mt: 8 }}
          src="/images/avatars/avatar1.jpeg"
        />
        <YTypoGraphy variant="h4" color={"white"} mt={2} mb={2}>
          {profile?.user.firstname || ""} {profile?.user.lastname || ""}
        </YTypoGraphy>
        <ProfileInfo className="card-info">
          <Grid container spacing={2}>
            <Grid item xs={12} className="profile-item bg-gray">
              <YTypoGraphy
                variant="body2"
                fontWeight={500}
                className="profile-item-title"
              >
                جنسیت
              </YTypoGraphy>
              <YTypoGraphy
                variant="body1"
                fontWeight={500}
                className="profile-item-value"
              >
                {profile?.user.gender == "FEMALE"
                  ? "خانم"
                  : profile?.user.gender == "MALE"
                  ? "آقا"
                  : profile?.user.gender == "OTHER"
                  ? "مشخص نشده است"
                  : ""}
              </YTypoGraphy>
            </Grid>
            <Grid item xs={12} className="profile-item">
              <YTypoGraphy
                variant="body2"
                fontWeight={500}
                className="profile-item-title"
              >
                نام کاربری
              </YTypoGraphy>
              <YTypoGraphy
                variant="body1"
                fontWeight={500}
                className="profile-item-value"
              >
                {profile?.user.username}
              </YTypoGraphy>
            </Grid>
            <Grid item xs={12} className="profile-item bg-gray">
              <YTypoGraphy
                variant="body2"
                fontWeight={500}
                className="profile-item-title"
              >
                ایمیل
              </YTypoGraphy>
              <YTypoGraphy
                variant="body1"
                fontWeight={500}
                className="profile-item-value"
              >
                {profile?.user.email}
              </YTypoGraphy>
            </Grid>
            <Grid item xs={12} className="profile-item">
              <YTypoGraphy
                variant="body2"
                fontWeight={500}
                className="profile-item-title"
              >
                کشور
              </YTypoGraphy>
              <YTypoGraphy
                variant="body1"
                fontWeight={500}
                className="profile-item-value"
              >
                {profile?.country_name?.fa_name}
              </YTypoGraphy>
            </Grid>
          </Grid>
        </ProfileInfo>

        <BottomSheet
          title="ویرایش مشخصات"
          open={open}
          setOpen={setOpen}
          isDark={true}
          isTransparent={true}
          children={
            <ProfileBottomSheet
              handleUpdate={handleCallbackUpdateProfile}
              data={profile}
            />
          }
        />
      </ProfileCardContainer>

      <YButton
        title={"ویرایش مشخصات"}
        color={"secondary-outline"}
        onClick={() => setOpen(true)}
        align={"center"}
        radius={"radius-primary"}
        width={"82vw"}
        height={50}
        mb={2}
        mt={0}
      />
    </Box>
  );
};

export default Profile;

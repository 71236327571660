// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.receiption-container {
    position: sticky;
      top: 20%;
  }
  
  .lbl-payID,
  .lbl-price,
  .lbl-ticket,
  .lbl-date ,
  .lbl-receiption{
    position: absolute;
    color: white;
  }

  .lbl-payID {
    top: 46%;
    right: 33%;
  }
  
  .lbl-price {
    bottom: 33%;
    left: 22%;
  }
  
  .lbl-ticket {
    bottom: 33%;
    right: 25%;
  }
  
  .lbl-date {
    bottom: 22%;
    right: 25%;
  }
  .lbl-receiption {
    bottom: 11.5%;
    right: 33.8%;
    font-size: 1.2rem;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Payment/Receiption/Failed/ReceiptionFailed.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;MACd,QAAQ;EACZ;;EAEA;;;;;IAKE,kBAAkB;IAClB,YAAY;EACd;;EAEA;IACE,QAAQ;IACR,UAAU;EACZ;;EAEA;IACE,WAAW;IACX,SAAS;EACX;;EAEA;IACE,WAAW;IACX,UAAU;EACZ;;EAEA;IACE,WAAW;IACX,UAAU;EACZ;EACA;IACE,aAAa;IACb,YAAY;IACZ,iBAAiB;EACnB","sourcesContent":[".receiption-container {\n    position: sticky;\n      top: 20%;\n  }\n  \n  .lbl-payID,\n  .lbl-price,\n  .lbl-ticket,\n  .lbl-date ,\n  .lbl-receiption{\n    position: absolute;\n    color: white;\n  }\n\n  .lbl-payID {\n    top: 46%;\n    right: 33%;\n  }\n  \n  .lbl-price {\n    bottom: 33%;\n    left: 22%;\n  }\n  \n  .lbl-ticket {\n    bottom: 33%;\n    right: 25%;\n  }\n  \n  .lbl-date {\n    bottom: 22%;\n    right: 25%;\n  }\n  .lbl-receiption {\n    bottom: 11.5%;\n    right: 33.8%;\n    font-size: 1.2rem;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

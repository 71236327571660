import React from 'react';
import './BottomArrowSvg.css';

interface BottomArrowSvgProps {
  dir: string;
  float: string;
}

const BottomArrowSvg: React.FC<BottomArrowSvgProps> = ({ dir, float }) => {
  return (
    <div dir={dir} className={`bottomArrow ${float}`}>
      <svg width="24" height="36" viewBox="0 0 24 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19 20.5L12 27.5L5 20.5"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g opacity="0.5">
          <path
            d="M16.6666 12.6665L11.9999 17.3332L7.33325 12.6665"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g opacity="0.3">
          <path
            d="M16.0834 4.9585L12.0001 9.04183L7.91675 4.9585"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </div>
  );
};

export default BottomArrowSvg;

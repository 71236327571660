import { Button } from "@mui/base";
import React from "react";
import "./LeftSideSvg.css";

interface LeftSideSvgProps {
  dir: string;
  float: string;
}
// { title, align, onClick }
// LeftSideSvgProps
const LeftSideSvg: React.FC<LeftSideSvgProps> = ({ dir, float }) => {
  return (
    <div dir={dir} className={`leftSideArrow ${float}`}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Iconly/Light-Outline/Arrow - Left 3">
          <g id="Arrow - Left 2">
            <path
              id="Stroke 1"
              d="M16.0303 4.46967C16.2966 4.73594 16.3208 5.1526 16.1029 5.44621L16.0303 5.53033L9.561 12L16.0303 18.4697C16.2966 18.7359 16.3208 19.1526 16.1029 19.4462L16.0303 19.5303C15.7641 19.7966 15.3474 19.8208 15.0538 19.6029L14.9697 19.5303L7.96967 12.5303C7.7034 12.2641 7.6792 11.8474 7.89705 11.5538L7.96967 11.4697L14.9697 4.46967C15.2626 4.17678 15.7374 4.17678 16.0303 4.46967Z"
              fill="white"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default LeftSideSvg;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotifyAlert{
    display: flex;
    position: fixed;
    top: 12%;
    left: 5%;
    right: inherit;
    z-index: 2;
}

.NotifyAlert .content{
    width: 80vw;
}

.NotifyAlert-container{
    position: fixed;
    top: 12%;
    left: 5%;
    z-index: 2;
}

.NotifyAlert-container .svg-light{
    position: fixed;
    top: 3%;
    left: 30%;
}`, "",{"version":3,"sources":["webpack://./src/components/NotifyAlert/NotifyAlert.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,QAAQ;IACR,QAAQ;IACR,cAAc;IACd,UAAU;AACd;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;IACf,QAAQ;IACR,QAAQ;IACR,UAAU;AACd;;AAEA;IACI,eAAe;IACf,OAAO;IACP,SAAS;AACb","sourcesContent":[".NotifyAlert{\n    display: flex;\n    position: fixed;\n    top: 12%;\n    left: 5%;\n    right: inherit;\n    z-index: 2;\n}\n\n.NotifyAlert .content{\n    width: 80vw;\n}\n\n.NotifyAlert-container{\n    position: fixed;\n    top: 12%;\n    left: 5%;\n    z-index: 2;\n}\n\n.NotifyAlert-container .svg-light{\n    position: fixed;\n    top: 3%;\n    left: 30%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { styled } from '@mui/system';

interface YTextFieldProps {
  fullWidth?: boolean;
  value: string;
  type?: 'text' | 'password' | 'tel' | 'email' | 'decimal';
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setValue: (value: string) => void;
  label?: string;
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'rgba(220, 220, 220, 1) 0.65px solid',
      borderRadius: '32px',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      borderRadius: '32px',
    },
    '& input': {
      color: '#fff',
      fontSize: '16px',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#fff',
    fontSize: '16px',
  },
}));

const YTextField: React.FC<YTextFieldProps> = ({
  fullWidth = true,
  setValue,
  value,
  type = 'text',
  onChange,
  label,
}) => {
  const [initialTyping, setInitialTyping] = useState<boolean>(true);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    if (initialTyping && newValue !== '0.00') {
      setInitialTyping(false);
    }

    if (type === 'decimal') {
      const validValue = newValue.replace(/[^0-9.]/g, ''); // Allow only numbers and dots
      setValue(validValue);
    } else {
      setValue(newValue);
    }
  };

  const handleFocus = () => {
    if (value === '0.00') {
      setValue('');
      setInitialTyping(false);
    }
  };

  return (
    <StyledTextField
      fullWidth={fullWidth}
      value={value}
      onChange={handleInputChange}
      onFocus={handleFocus}
      type="text" // Always use "text" as the type, since we're handling validation manually
      label={label}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <span style={{ color: '#fff' }}>$</span>
          </InputAdornment>
        ),
        inputMode: 'decimal', // Helps mobile keyboards show a numeric keypad with a decimal point
      }}
      sx={{
        mb: 2,
        '& input': {
          textAlign: 'left',
        },
      }}
    />
  );
};

export default YTextField;

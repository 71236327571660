import { Box, Card, Grid, Typography } from "@mui/material";
import "./BuyNumber.css";
import { Image } from "@mui/icons-material";
import YButton from "../../../components/Button/YButton";
import BottomArrowSvg from "../../../components/SVG/BottomArrowSvg/BottomArrowSvg";
import YImage from "../../../components/Image/YImage";

const BuyNumber = () => {
  const numberOfCounter = 6;

  function handleGoTo() {
    alert("handleGoTo");
  }

  return (
    <>
      {/* <img className="greenDot" src={'/images/svgs/greenRadial.svg'} /> */}

      <Box className="container">
        <svg
          width="396"
          height="10"
          viewBox="0 0 396 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="134" cy="5" r="5" fill="#7E7E7E" />
          <circle cx="263" cy="5" r="5" fill="#7E7E7E" />
          <circle cx="5" cy="5" r="5" fill="#7E7E7E" />
          <line
            x1="386.5"
            y1="5.5"
            x2="268.5"
            y2="5.49999"
            stroke="#7E7E7E"
            stroke-linecap="round"
            stroke-dasharray="5 5"
          />
          <line
            x1="257.5"
            y1="5.5"
            x2="139.5"
            y2="5.49999"
            stroke="#7E7E7E"
            stroke-linecap="round"
            stroke-dasharray="5 5"
          />
          <line
            x1="128.5"
            y1="5.5"
            x2="10.5"
            y2="5.49999"
            stroke="#7E7E7E"
            stroke-linecap="round"
            stroke-dasharray="5 5"
          />
          <circle cx="391" cy="5" r="5" fill="#0038FF" />
        </svg>
      </Box>

      <Grid container pl={1}>
        <Typography
          variant="h3"
          mt={4}
          mr={2}
          fontSize={"1.2rem"}
          width={"100vw"}
        >
          انتخاب صرافی
        </Typography>
        <Typography variant="body1" mt={2} mr={2} fontSize={"1rem"}>
          {" "}
          یکی از صرافی های زیر را انتخاب کنید.
        </Typography>
        <Typography variant="body1" mt={2} mr={2} fontSize={"1rem"}>
          {" "}
          صرافی های لیست شده، جز صرافی های قدیمی و معتبر ایرانی هستند که از بانک
          مرکزی جمهوری اسلامی ایران مجوز فعالیت دارند.
        </Typography>
      </Grid>

      <Grid container spacing={2} p={2} mt={5}>
        <Grid item xs={6}>
          <Card style={{ height: "136px", borderRadius: "16px" }}>
            <YImage
              src={"/images/exchanges/nobitex.jpg"}
              objectFit={"contain"}
              height={"100%"}
              alt={""}
            />
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card style={{ height: "136px", borderRadius: "16px" }}>
            <YImage src={"/images/exchanges/binance.png"} alt={""} />
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card style={{ height: "136px", borderRadius: "16px" }}>
            <YImage src={"/images/exchanges/binance.png"} alt={""} />
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card style={{ height: "136px", borderRadius: "16px" }}>
            <YImage
              src={"/images/exchanges/nobitex.jpg"}
              objectFit={"contain"}
              height={"100%"}
              alt={""}
            />
          </Card>
        </Grid>
      </Grid>

      <YButton
        mt={2}
        color={"primary"}
        align={"center"}
        radius={"radius-primary"}
        width={"395px"}
        height={50}
        mb={0.5}
        title="ادامه"
      />

      <BottomArrowSvg float={"left"} dir="ltr" />

      <BottomArrowSvg float={"right"} dir="rtl" />
    </>
  );
};

export default BuyNumber;

import React from 'react';
import { styled } from '@mui/system';

interface ImageProps {
  src: string;
  alt: string;
  objectFit?: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
  width?: number | string;
  height?: number | string;
  borderRadius?: number | string;
  mt?: number | string;
  mr?: number | string;
  ml?: number | string;
  mb?: number | string;
  pt?: number | string;
  pr?: number | string;
  pl?: number | string;
  pb?: number | string;
  className?: string;
  isAuto?: boolean;
}

const ImageWrapper = styled('img')<ImageProps>(({
  objectFit = 'cover',
  borderRadius = 0,
  width = '100%',
  height = 'auto',
  mt = 0,
  mr = 0,
  ml = 0,
  mb = 0,
  pt = 0,
  pr = 0,
  pl = 0,
  pb = 0,
}) => ({
  display: 'block',
  objectFit: objectFit,
  borderRadius: borderRadius,
  width: width,
  height: height,
  margin: `${mt} ${mr} ${mb} ${ml}`,
  padding: `${pt} ${pr} ${pb} ${pl}`
}));

const YImage: React.FC<ImageProps> = ({
  src,
  alt,
  objectFit,
  width,
  height,
  borderRadius,
  mt,
  mr,
  ml,
  mb,
  pt,
  pr,
  pl,
  pb,
  className,
  isAuto,
}) => {
  return (
    <ImageWrapper
      src={src}
      alt={alt}
      objectFit={objectFit}
      width={width}
      height={height}
      borderRadius={borderRadius}
      mt={mt}
      mr={mr}
      ml={ml}
      mb={mb}
      pt={pt}
      pr={pr}
      pl={pl}
      pb={pb}
      className={className}
      style={{margin:isAuto?'0 auto':''}}
    />
  );
};

export default YImage;

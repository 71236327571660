// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-selected-filled {
    color: white !important;
    background-color: rgba(22, 95, 238, 1) !important;
}

.btn-selected {
    color: white !important;
    background-color: rgba(22, 95, 238, 0.31) !important;
}

.btn-not-selected {
    color: rgba(47, 47, 47, 1) !important;
    background-color: white !important;
}

.btn-not-selected,
.btn-selected .btn-selected-filled {
    font-size: 1.2rem !important;
}

.container {
    padding: 0 18px;
}

.card-choosed {
    width: 92vw;
    border-radius: 32px !important;
    border: 0.5px solid rgba(62, 62, 62, 1);
}
.card-payment{
    border-radius: 16px !important;
    background-color: rgba(241, 241, 241, 1) !important;
    margin-top: 8px;
}
.flex{
    display: flex;
}`, "",{"version":3,"sources":["webpack://./src/pages/Payment/ChoosedNumber/ChoosedNumber.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,iDAAiD;AACrD;;AAEA;IACI,uBAAuB;IACvB,oDAAoD;AACxD;;AAEA;IACI,qCAAqC;IACrC,kCAAkC;AACtC;;AAEA;;IAEI,4BAA4B;AAChC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,8BAA8B;IAC9B,uCAAuC;AAC3C;AACA;IACI,8BAA8B;IAC9B,mDAAmD;IACnD,eAAe;AACnB;AACA;IACI,aAAa;AACjB","sourcesContent":[".btn-selected-filled {\n    color: white !important;\n    background-color: rgba(22, 95, 238, 1) !important;\n}\n\n.btn-selected {\n    color: white !important;\n    background-color: rgba(22, 95, 238, 0.31) !important;\n}\n\n.btn-not-selected {\n    color: rgba(47, 47, 47, 1) !important;\n    background-color: white !important;\n}\n\n.btn-not-selected,\n.btn-selected .btn-selected-filled {\n    font-size: 1.2rem !important;\n}\n\n.container {\n    padding: 0 18px;\n}\n\n.card-choosed {\n    width: 92vw;\n    border-radius: 32px !important;\n    border: 0.5px solid rgba(62, 62, 62, 1);\n}\n.card-payment{\n    border-radius: 16px !important;\n    background-color: rgba(241, 241, 241, 1) !important;\n    margin-top: 8px;\n}\n.flex{\n    display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import Grid from "@mui/material/Grid";
import "./menu.css";
import BannerSection from "../../components/Banners/BannerSection";
import MobileMenu from "../../components/Menu/navDrawer/mobileMenu/MenuItem/MobileMenuItem";
import menuItemsGuess from "./menu_item_guess.json";
import socials from "./socials.json";
import YTypoGraphy from "../../components/Text/YTypography/YTypoGraphy";

const MenuPage = () => {
  return (
    <>
      <Grid mt={10}></Grid>

      {menuItemsGuess.map((item, index) => (
        <MobileMenu
          key={item.id}
          ml={16}
          mr={16}
          mb={16}
          icon={<img src={`/images/svgs/menuItem/${item.svg}`} alt="icon" />}
          title={item.title}
          link={item.link}
          description={item.short_description}
        />
      ))}

      <BannerSection />

      <Grid container p={4} mr={1}>
        {socials.map((item, index) => (
          <Grid item xs={2} mt={1}>
            <img src={`/images/svgs/menuItem/socials/${item.svg}`} alt="icon" />
          </Grid>
        ))}
      </Grid>

      <Grid container>
        <Grid item xs={9}>
          <YTypoGraphy variant="h6" color={"white"} fontSize={"0.7rem"} mr={2}>
            ساخته شده با عشق❤️ برای افزایش خوش شانسی تو
          </YTypoGraphy>
        </Grid>
        <Grid item xs={3}>
          <YTypoGraphy variant="h6" color={"white"} fontSize={"0.7rem"} mr={2}>
            نسخه 1.0.0
          </YTypoGraphy>
        </Grid>
      </Grid>

    </>
  );
};

export default MenuPage;

import { Button } from "@mui/base";
import React from "react";
import "./InfoSvg.css";

interface InfoSvgProps {
  dir: string;
  float: string;
  color?: string;
}
// { title, align, onClick }
// InfoSvgProps
const InfoSvg: React.FC<InfoSvgProps> = ({ dir, float,color }) => {

  
  return (
    <div dir={dir} className={` ${float}`}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_37_6231"
          maskUnits="userSpaceOnUse"
          x="2"
          y="2"
          width="20"
          height="20"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2 2H21.9999V22H2V2Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_37_6231)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.664 3.5C5.135 3.5 3.5 5.233 3.5 7.916V16.084C3.5 18.767 5.135 20.5 7.664 20.5H16.332C18.864 20.5 20.5 18.767 20.5 16.084V7.916C20.5 5.233 18.864 3.5 16.334 3.5H7.664ZM16.332 22H7.664C4.276 22 2 19.622 2 16.084V7.916C2 4.378 4.276 2 7.664 2H16.334C19.723 2 22 4.378 22 7.916V16.084C22 19.622 19.723 22 16.332 22Z"
            fill={`${color?color:'#0094FF'}`}
          />
        </g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.994 16.75C11.58 16.75 11.244 16.414 11.244 16V12C11.244 11.586 11.58 11.25 11.994 11.25C12.408 11.25 12.744 11.586 12.744 12V16C12.744 16.414 12.408 16.75 11.994 16.75Z"
          fill={`${color?color:'#0094FF'}`}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.9989 9.2041C11.4459 9.2041 10.9939 8.7571 10.9939 8.2041C10.9939 7.6511 11.4369 7.2041 11.9889 7.2041H11.9989C12.5519 7.2041 12.9989 7.6511 12.9989 8.2041C12.9989 8.7571 12.5519 9.2041 11.9989 9.2041Z"
          fill={`${color?color:'#0094FF'}`}
        />
      </svg>
    </div>
  );
};

export default InfoSvg;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drawer-item {
    padding: 8px 0;

}

.drawer-item svg {
    padding-left: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/navDrawer/DrawerItem.css"],"names":[],"mappings":"AAAA;IACI,cAAc;;AAElB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".drawer-item {\n    padding: 8px 0;\n\n}\n\n.drawer-item svg {\n    padding-left: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

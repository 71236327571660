import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import Layout from "./Layouts/layout";
import Home from "./pages/home/page";
// import Blogs from "./pages/blogs";
import Lottery from "./pages/Lottery/page";
import DisableZoom from "./DisableZoom";
import BuyNumber from "./pages/Payment/BuyNumber/page";
import Access from "./pages/Access/page";

import Profile from "./pages/Auth/Profile/page";
import Security from "./pages/Auth/Security/page";
import MyResults from "./pages/Lottery/MyResults/page";
import ChooseNumber from "./pages/Payment/ChooseNumber/page";
import Wallet from "./pages/Payment/Wallet/page";
import TermsOfService from "./pages/Others/TermsOfService/page";
import ReceiptionSuccess from "./pages/Payment/Receiption/Success/page";
import ReceiptionFailed from "./pages/Payment/Receiption/Failed/page";
import WhiteLayout from "./Layouts/WhiteLayout";

import AuthenticationStep1 from "./pages/Auth/Authentication/step1/page";
import AuthenticationStep2 from "./pages/Auth/Authentication/step2/page";
import AuthenticationForget from "./pages/Auth/Authentication/setpassword/page";
import ChoosedNumber from "./pages/Payment/ChoosedNumber/page";
import AllResulsts from "./pages/Lottery/AllResulsts/page";
import EditChooseNumber from "./pages/Payment/ChooseNumber/Edit/page";
import WebApp from '@twa-dev/sdk'
import Logout from "./pages/Auth/logout/page";
import MenuPage from "./pages/Menu/page";

const App: React.FC = () => {
  const mobileWidth = 428; // Adjust as needed for your project
  const [containerWidth, setContainerWidth] = useState("100%");
  const location = useLocation();
  const bgTheme1 = "/images/bgs/purple-gradient-bg.jpg";
  const bgPurpleTheme2 = "/images/bgs/purple-bg.jpg";

  useEffect(() => {

   
    
    function handleResize() {
      // if (window.innerWidth > mobileWidth) {
      //   setContainerWidth(`${mobileWidth}px`);
      // } else {
      //   setContainerWidth("100%");
      // }
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Check if the current path starts with /fa and redirect to /newpath
  if (!location.pathname.startsWith("/fa")) {
    return <Navigate to={`/fa${location.pathname.substring(3)}`} replace />;
  }

  return (
    <div style={{ width: containerWidth, margin: "0 auto" }}>
      {/* <DisableZoom /> */}
      <Routes>
        <Route path="/" element={<Layout backgroundImage={bgTheme1} />}>
          <Route index path="fa" element={<Home />} />
          <Route index path="fa/lottery" element={<Lottery />} />
          <Route index path="fa/access" element={<Access />} />
          <Route
            index
            path="fa/payment/choosed-number"
            element={<ChoosedNumber />}
          />
          

           <Route index path="fa/profile" element={<Profile />} />
           <Route index path="fa/menu" element={<MenuPage />} />

           <Route
            index
            path="fa/payment/choose-number"
            element={<ChooseNumber />}
          />

          <Route
            index
            path="fa/payment/choose-number/edit"
            element={<EditChooseNumber />}
          />

          <Route index path="fa/payment/wallet" element={<Wallet />} />

            <Route
            index
            path="fa/terms-of-service"
            element={<TermsOfService />}
          />
        </Route>

        <Route path="/" element={<Layout backgroundImage={bgTheme1} topTitle="نتایج"/>}>
          <Route index path="fa/lottery/my-results" element={<MyResults />} />
            <Route
              index
              path="fa/lottery/all-results"
              element={<AllResulsts />}
            />
        </Route>

        {/* <Route path="/" element={<WhiteLayout backgroundImage={''} isGradient={false} />}> */}

        {/* AUTH */}
        <Route
          path="/"
          element={
            <Layout backgroundImage={bgPurpleTheme2} isHideTopIcons={true} />
          }
        >
          <Route index path="fa/auth/step1" element={<AuthenticationStep1 />} />
          <Route
            index
            path="fa/auth/set-password"
            element={<AuthenticationForget />}
          />
          <Route index path="fa/security" element={<Security />} />
        </Route>

        <Route
          path="/"
          element={<Layout backgroundImage={bgTheme1} isHideTopIcons={true} />}
        >
          <Route index path="fa/auth/step2" element={<AuthenticationStep2 />} />
        </Route>

        {/* isHideTopIcons={false} */}
        <Route
          path="/"
          element={<WhiteLayout backgroundImage={""} isGradient={false} />}
        >
          {/* <Route index path="fa/profile" element={<Profile />} /> */}
         

          {/* <Route
            index
            path="fa/payment/choosed-number"
            element={<ChoosedNumber />}
          /> */}
          <Route index path="fa/payment/buy-number" element={<BuyNumber />} />

          <Route
            index
            path="fa/payment/receiption/success"
            element={<ReceiptionSuccess />}
          />

          <Route
            index
            path="fa/payment/receiption/failed"
            element={<ReceiptionFailed />}
          />

          {/* Others */}
          

          {/* Lottery */}

          {/* <Route index path="fa/lottery/my-results" element={<MyResults />} />
          <Route
            index
            path="fa/lottery/all-results"
            element={<AllResulsts />}
          /> */}

          {/* Payment */}

    
          <Route index path="fa/logout" element={<Logout />} />
        </Route>

        {/* </Route> */}
      </Routes>
    </div>
  );
};

export default App;

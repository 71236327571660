// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.balanceContainer .amount {
  position: absolute;
  top: 51px;
  left: 102px;
  font-size: 26px;
  color: white;
}

.balanceContainer .balance {
  position: absolute;
  top: 54px;
  right: 40px;
  font-size: 18px;
  color: white;
}
.balanceContainer .amount span {
    font-weight: 400;
    font-family: 'Yekan Bakh';
    font-size: 14px;
    padding: 4px;;
}

.balanceContainer .DepositWithDrawContainer{
    position: absolute;
    bottom: 16px;
    right: 4.5vw;
    width:95vw;
}`, "",{"version":3,"sources":["webpack://./src/pages/Payment/Wallet/Wallet.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,eAAe;EACf,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,eAAe;EACf,YAAY;AACd;AACA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,UAAU;AACd","sourcesContent":[".balanceContainer .amount {\n  position: absolute;\n  top: 51px;\n  left: 102px;\n  font-size: 26px;\n  color: white;\n}\n\n.balanceContainer .balance {\n  position: absolute;\n  top: 54px;\n  right: 40px;\n  font-size: 18px;\n  color: white;\n}\n.balanceContainer .amount span {\n    font-weight: 400;\n    font-family: 'Yekan Bakh';\n    font-size: 14px;\n    padding: 4px;;\n}\n\n.balanceContainer .DepositWithDrawContainer{\n    position: absolute;\n    bottom: 16px;\n    right: 4.5vw;\n    width:95vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

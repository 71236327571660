import React from "react";
import { Typography } from "@mui/material";
import "./ResultItenNumber.css";

interface ResultItemNumberProps {
  luckyNumbers: number[];
  userGuess: number[];
  number: number;
  index: number;
  isBlur: boolean;
  defaultColor?: string;
}

const ResultItemNumber: React.FC<ResultItemNumberProps> = ({
  luckyNumbers,
  userGuess,
  number,
  index,
  isBlur,
  defaultColor = "rgba(142, 142, 142, 1)",
}) => {
  return (
    <Typography
      className={isBlur ? "blur-text" : ""}
      variant="h3"
      fontWeight={600}
      fontSize={"1.05rem"}
      textAlign={"center"}
      margin={"0 auto"}
    >
      <span
        style={{
          color: userGuess?.includes(number)
            ? "rgba(0, 158, 16, 1)"
            : `${defaultColor}`,
        }}
      >
        {number}
      </span>

      {index !== luckyNumbers.length - 1 ? (
        <span
          style={{
            fontSize: "2rem",
            fontWeight: 500,
            marginTop: "8px",
            color: `${defaultColor}`,
          }}
        >
          -
        </span>
      ) : (
        <span
          style={{
            fontSize: "2rem",
            fontWeight: 500,
            marginTop: "8px",
          }}
        ></span>
      )}
    </Typography>
  );
};

export default ResultItemNumber;

import { useEffect, useState } from "react";
import { loginTelegram } from "../services/authService";

export const API_BASE_URL = "https://api.yashans.co";
// export const API_BASE_URL = "http://192.168.0.3:5000";
// export const API_BASE_URL = "http://172.20.10.2:5000";
// export const API_BASE_URL = "http://169.254.109.209:5000";

declare global {
  interface Window {
    chat_id: string;
    first_name: string;
    last_name: string;
  }
}

// Utility function to retrieve the token from localStorage
export const getToken = (): string | null => {
  return localStorage.getItem("token");
};

// Utility function to create headers for API requests
export const createHeaders = (): Record<string, string> => {
  const token = getToken();

  const headers: Record<string, string> = {
    "Content-Type": "application/json",
  };

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  return headers;
};



// Utility function to handle API responses
export const handleResponse = async <T>(response: Response): Promise<T> => {
  if (response.status === 403||response.status ==401) {
    // Access chat_id, first_name, and last_name directly from the window object
    // const login = await loginTelegram('7428308651', 'ma', 'fa');
    const login = await loginTelegram(window.chat_id, window.first_name, window.last_name);
    if(login!=null && login.status){
      localStorage.setItem('token',login.token);
       window.location.reload();
    }
      
    // localStorage.removeItem('token');
    // window.location.reload();
  }

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Unknown error');
  }

  return response.json() as Promise<T>;
};

// Custom React hook to access Telegram user data from the window object
export const useTelegramUserData = () => {
  const [chatId, setChatId] = useState<string>(window.chat_id || '');
  const [firstName, setFirstName] = useState<string>(window.first_name || '');
  const [lastName, setLastName] = useState<string>(window.last_name || '');

  // You can add additional logic here, such as fetching updated data if needed

  return { chatId, firstName, lastName };
};

export const handleError = (error: Error) => {
  console.error("API call failed. Error:", error);
  throw error;
};

import { Grid, Typography } from "@mui/material";
import "./AllResulsts.css";
import InfoSvg from "../../../components/SVG/InfoSvg/InfoSvg";
import YButton from "../../../components/Button/YButton";
import NoParticipationSvg from "../../../components/SVG/Results/NoParticipation/NoParticipationSvg";
import React, { useEffect, useState } from "react";
import ResultItem from "../../../components/Results/ResultItems/ResultItem";
import AllResultItem from "../../../components/Results/AllResultItems/AllResultItems";
import FilterSvg from "../../../components/SVG/FilterSvg/FilterSvg";
import { useNavigate } from "react-router-dom";
import BottomSheet from "../../../components/BottomSheet/BottomSheet";
import WinnersBottomSheet from "./WinnersBottomSheet";
import FilterBottomSheet from "./FilterBottomSheet";
import { getAllResulsts, LotteryResultsResponse } from "../../../services/resultsService";
import { toPersianDate } from "../../../utility/dateUtils";

const AllResulsts = () => {
  const [results, setResults] = useState<any[]>([]);
  const navigate = useNavigate();

  const [openFilter, setOpenFilter] = useState(false);
  const [openWinner, setOpenWinner] = useState(false);
  const [lotteryResults, setLotteryResults] = useState<LotteryResultsResponse>({
      results: [],
      wins3: [],
      wins4: [],
      wins5: [],
      status: true,
  });
  


  async function handleLotteryResults() {
        const fetchedResults: LotteryResultsResponse | null = await getAllResulsts();
  
        if (fetchedResults !== null) {
            setLotteryResults(fetchedResults);
        }
    }
  
    useEffect(() => {

    }, []);
  // const navigate = useNavigate();

  useEffect(() => {
    // const initialArray = [{ type: "gold" }, { type: "silver" }];
    // setResults(initialArray);
    handleLotteryResults();
  }, []);

  function filterPage() {
    setOpenFilter(!openFilter);
  }

  function winnersBottomSheet() {
    setOpenWinner(!openWinner);
  }

  function guessNumberPage() {
    navigate("/fa/payment/choose-number");
  }

  function handleChangeWinTab(link:string){
    navigate(link)
  }

  function handleCallback() {}

  return (
    <>
      <Grid container mt={10} display={"block"} textAlign={"center"}>
        <Grid display={"inline-flex"}>
          <Typography
            variant="h3"
            fontWeight={700}
            fontSize={"1.3rem"}
            textAlign={"center"}
            mt={0}
            mb={0}
            mr={7}
            pl={1}
          >
            نتایج
          </Typography>

          <InfoSvg dir={""} float={""} color="rgba(105, 105, 105, 1)" />
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={0} p={2}>
        <Grid item xs={6}>
          <YButton
            mt={0}
            color={"primary-gray"}
            align={"center"}
            radius={"radius-primary"}
            width={"45vw"}
            height={50}
            mb={0.5}
            title="نتایج من"
            onClick={()=>handleChangeWinTab('/fa/lottery/my-results')}
          />
        </Grid>

        <Grid item xs={6}>
          <YButton
            mt={0}
            color={"primary"}
            align={"center"}
            radius={"radius-primary"}
            width={"45vw"}
            height={50}
            mb={0.5}
            title="نتایج همه"
                   onClick={()=>handleChangeWinTab('/fa/lottery/all-results')}
          />
        </Grid>
      </Grid>

      <Grid container pr={2} pl={2}>
        <Grid item xs={11}>
          <Typography variant="body1">
            خوش شانس هایی که با حدس اعداد، برنده جوایز شدند را مشاهده کنید
          </Typography>
        </Grid>

        <Grid item mr={0.5} textAlign={"left"}>
          <div onClick={filterPage}>
            <FilterSvg dir={""} float={""} />
          </div>
        </Grid>
      </Grid>

      {lotteryResults?.results?.length > 0 ? (
        <>
          {lotteryResults.results.map((item, index) => (
            <AllResultItem
              key={index}
              date={toPersianDate(new Date(item.lottery_date))}
              // title={''}
              luckyNumbers={item.lottery_numbers}
              amountWon={item.winners_total_amount}
              WonCount={item.number_of_wins}
              type={'gold'}
            />
          ))}
        </>
      ) : (
        <>
          <Grid textAlign={"center"} mt={15}>
            <NoParticipationSvg dir={""} float={""} />
          </Grid>

          <Typography variant="body1" textAlign={"center"} pr={5} pl={5}>
            هنوز مشارکتی نداشتی! اعداد رو حدس بزن و شانست رو امتحان کن
          </Typography>

          <YButton
            mt={2}
            color={"primary"}
            align={"center"}
            radius={"radius-primary"}
            width={"45vw"}
            height={50}
            mb={0.5}
            title="حدس اعداد"
            onClick={guessNumberPage}
          />
        </>
      )}

      <YButton
        mt={2}
        color={"primary"}
        align={"center"}
        radius={"radius-primary"}
        width={"45vw"}
        height={50}
        mb={0.5}
        title="برندگان"
        onClick={winnersBottomSheet}
      />

      <BottomSheet
        title="برندگان"
        description=""
        open={openWinner}
        setOpen={setOpenWinner}
        isDark={true}
        isTransparent={true}
        children={<WinnersBottomSheet handleUpdate={handleCallback} data={lotteryResults} />}
      />

      <BottomSheet
        title="فیلتر"
        description=""
        open={openFilter}
        setOpen={setOpenFilter}
        isDark={true}
        isTransparent={true}
        children={<FilterBottomSheet handleUpdate={handleCallback} />}
      />
    </>
  );
};

export default AllResulsts;

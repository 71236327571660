import { Grid, Typography } from "@mui/material";
import "./Security.css";
import { Image } from "@mui/icons-material";
import CounterPrizeComponent from "../../../components/Numbers/Counter/CounterPrizeComponent";
import GuessPrizeCard from "../../../components/Numbers/Guess/GuessPrizeCard/GuessPrizeCard";
import YButton from "../../../components/Button/YButton";
import BottomArrowSvg from "../../../components/SVG/BottomArrowSvg/BottomArrowSvg";
import GreenLightText from "../../../components/Text/GreenLightText/GreenLightText";
import { useNavigate } from "react-router-dom";
import YImage from "../../../components/Image/YImage";
import BottomSheet from "../../../components/BottomSheet/BottomSheet";
import { useState } from "react";
import SecutiryBottomSheet from "./SecutiryBottomSheet";

const Security = () => {
  const numberOfCounter = 6;
  const navigate = useNavigate();

  function handleGoTo() {
    navigate("/fa/lottery");
  }

  const [open, setOpen] = useState(false);

  function handleCallbackUpdateProfile() {}

  return (
    <>
      <Grid container spacing={2} mt={8}>
        <YImage
          src="/images/icons/knight-helmet-armor-white.png"
          alt={"logo"}
          width={146}
          height={197}
          className={"leftShareMenu"}
          isAuto={true}
        />
      </Grid>

      <BottomSheet
        title="امنیت"
        description="لطفا رمز عبور مورد نظر خود را وارد کنید"
        open={true}
        setOpen={setOpen}
        isTransparent={true}
        children={
          <SecutiryBottomSheet handleUpdate={handleCallbackUpdateProfile} />
        }
      />

      <BottomArrowSvg float={"left"} dir="ltr" />

      <BottomArrowSvg float={"right"} dir="rtl" />
    </>
  );
};

export default Security;

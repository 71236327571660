import React from "react";
import { Box, Paper, Card, Grid } from "@mui/material";
import ResultItemNumber from "./../ResultItems/ResultItemNumber";
import YButton from "../../Button/YButton";
import BigPrizeSvg from "../../SVG/Results/NoParticipation/BigPrizeSvg/BigPrizeSvg";
import YTypoGraphy from "../../Text/YTypography/YTypoGraphy";
import { t } from "i18next";
import GuessPrizeCard from "../../Numbers/Guess/GuessPrizeCard/GuessPrizeCard";
import GuessResultItem from "../../Numbers/Guess/GuessPrizeCard/GuessResultItem/GuessResultItem";


interface AllResultItemProps {
  date: string;
  luckyNumbers: number[];
  amountWon: number;
  WonCount: number;
  type: string;
}

const AllResultItem: React.FC<AllResultItemProps> = ({
  date,
  luckyNumbers,
  amountWon,
  WonCount,
  type,
}) => {
  return (
    <Paper
      elevation={2}
      sx={{
        backgroundColor: "#FFFFFF1A",
        padding: 0,
        borderRadius: 4,
        maxWidth: "95%",
        margin: "16px auto",
        paddingBottom:'0.5rem'
      }}
    >
      <Box
        display={"flex"}
        sx={{
          background: `#FFFFFFCC`,
          // background: `${
          //   type === "gold"
          //     ? "linear-gradient(90deg, #FFDA7C 0%, #FFA41B 23.92%, #FFDA7C 51.04%, #FFA41B 77.88%, #FFDA7C 100%);"
          //     : type === "silver"
          //     ? "linear-gradient(90deg, #CECECE 0%, #9D9D9D 23.92%, #CECECE 51.04%, #9D9D9D 77.88%, #CECECE 100%);"
          //     : ""
          // }`,
          color: "black",
          padding: 1,
          textAlign: "center",
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
        }}
      >
        <Grid container>
          <Grid item xs={6}>
            <YTypoGraphy textAlign={"left"} width={"58%"} color={"#2E2E2E"}>
              {/* {date} */}
            </YTypoGraphy>
          </Grid>
          <Grid item xs={6}>
            <YTypoGraphy textAlign={"left"} width={"58%"} color={"#2E2E2E"}>
              {date}
            </YTypoGraphy>
          </Grid>
        </Grid>

   
        {/* <YTypoGraphy variant="h6" textAlign={"left"} width={"58%"}>
          {amountWon.toLocaleString()} تتر
        </YTypoGraphy> */}
      </Box>


      <Box p={1}>
        <Card
          sx={{
            background:
              "linear-gradient(90deg, rgba(229, 66, 255, 0.8) 0%, rgba(137, 40, 153, 0.8) 100%)",
            // backgroundColor: "rgba(239, 239, 239, 1)",
            width: "264px",
            height: "76px",
            textAlign: "center",
            margin: "8px auto",
            display: "block",
            borderRadius: "16px",
            color: "white",
          }}
        >
          <YTypoGraphy variant="h5" fontSize={"1rem"} mt={1}>
            {t("guess_numbers")}
          </YTypoGraphy>
          <Grid container spacing={1} pr={2} pl={2}>
            {luckyNumbers
              .slice()
              .reverse()
              .map((number, index) => (
                <Grid item>
                  <ResultItemNumber
                    luckyNumbers={luckyNumbers}
                    userGuess={[]}
                    defaultColor={"white"}
                    number={number}
                    index={index}
                    isBlur={false}
                  />
                </Grid>
              ))}
          </Grid>
         
        </Card>

        <Grid container spacing={1}>
          <Grid item xs={4} textAlign={"left"}></Grid>
        </Grid>
      </Box>
    
      <GuessResultItem  numbers={1} count={6}/>
      <GuessResultItem   numbers={1} count={5}/>
      <GuessResultItem   numbers={1} count={4}/>
      <GuessResultItem   numbers={1} count={3}/>
      <GuessResultItem   numbers={1} count={2}/>
      <GuessResultItem   numbers={1} count={1}/>
      
    </Paper>
  );
};

export default AllResultItem;

import React, { useState } from "react";
import { Modal, Slide, Typography, Box, Grid } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import YButton from "../../../components/Button/YButton";
import YTextField from "../../../components/TextField/YTextField";
import { ProfileData } from "../../../interfaces/auth/profileData";
import WarningSvg from "../../../components/SVG/WarningSvg/WarningSvg";
import "./WithDrawBottomSheet.css";
import YDollarTextField from "../../../components/TextField/YDollarTextField";

interface WithDrawBottomSheetProps {
  withdrawHandleCallback: (data:formWithdrawStructure) => void;
  balance: number;
}

export interface formWithdrawStructure {
  amount: string;
  address: string;
}

const WithDrawBottomSheet: React.FC<WithDrawBottomSheetProps> = ({
  withdrawHandleCallback,
  balance
}) => {

  // const [amount, setaMount] = useState<string>("0.00");

  const [formState, setFormState] = useState<formWithdrawStructure>({
    amount: "",
    address: "",
  });


  function WithDrawHandle() {
    withdrawHandleCallback(formState);
  }

  return (
    <>
      <YTextField
        label="آدرس تتر"
        value={formState.address}
        setValue={(value: string) =>
          setFormState((prevState) => ({ ...prevState, address: value }))
        }
        type="text"
      />

      <Grid container className="withDrawBalanceContainer" mb={3} mt={1}>

        
        <Typography variant="body1" className="lbl-balance">
            موجودی کیف پول
        </Typography>


        <Typography variant="h2" className="amount-dollar">
          {balance}
          <span>USDT</span>
        </Typography>

        {/* <Typography variant="body1" className="amount-toman">
            500،000 تتر
        </Typography> */}

        <img
          width={30}
          src="/images/icons/USDT.png"
          style={{
            position: "absolute",
            top: 12,
            left: 11,
          }}
        />

        <svg
          width="52"
          height="56"
          viewBox="0 0 52 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.75 1.45374C24.7611 0.292626 27.2389 0.292625 29.25 1.45374L47.3647 11.9123C49.3758 13.0734 50.6147 15.2192 50.6147 17.5415V38.4585C50.6147 40.7808 49.3758 42.9266 47.3647 44.0877L29.25 54.5463C27.2389 55.7074 24.7611 55.7074 22.75 54.5463L4.63526 44.0877C2.62416 42.9266 1.38526 40.7808 1.38526 38.4585V17.5415C1.38526 15.2192 2.62416 13.0734 4.63526 11.9123L22.75 1.45374Z"
            fill="#2F2F2F"
            stroke="#26A17B"
          />
        </svg>
      </Grid>

      <YDollarTextField
          label="مبلغ"
          value={formState.amount}
          setValue={(value: string) =>
            setFormState((prevState) => ({ ...prevState, amount: value }))
          }
          type="decimal"
      />

      <Grid container>
        <Grid item xs={6} mt={2}>
        {formState.amount !== "" && (
            <Typography
              color={"rgba(184, 184, 184, 1)"}
              component="h2"
              fontWeight={600}
              align="left"
            >
              - {formState.amount} USDT{" "}
              <Typography
                color={"rgba(184, 184, 184, 1)"}
                component="span"
                fontWeight={400}
                align="left"
                dir="rtl"
                pl={1}
              >
                {/* 500،000 <span>تتر</span>{" "} */}
              </Typography>{" "}
            </Typography>
           )}
        </Grid>
        <Grid item xs={6} mt={2}>
          <Typography
            color={"rgba(184, 184, 184, 1)"}
            component="h2"
            align="right"
          >
            مبلغ برداشتی
          </Typography>
        </Grid>

      </Grid>

      <YButton
        title="ثبت"
        color="secondary"
        width={'85vw'}
        height={50}
        mt={2}
        border="#000 solid"
        onClick={WithDrawHandle}
      />
    </>
  );
};

export default WithDrawBottomSheet;

import apiService from './apiService'; // Adjust the path as needed

interface LoginResponse {
  isNewUser: string;
  message: string;
  status: boolean;
}

interface VerifyResponse {
  token: string;
  message: string;
  status: boolean;
}

interface TelegramLoginResponse {
  token: string;
  message: string;
  status: boolean;
}

interface UpdateProfileResponse {
  message: string;
  status: boolean;
}

interface LogoutResponse {
  message: string;
  status: boolean;
}

 interface User {
  username: string;
  email: string | null;
  firstname: string | null;
  lastname: string | null;
  gender: string | null;
  country_id: number;
  balance_tether: number;
}

export interface CountryName {
  country_id: number;
  fa_name: string;
}

export interface UserProfileResponse {
  user: User;
  country_name: CountryName;
  message: string;
  status: boolean;
}


export const login = async (username: string): Promise<LoginResponse> => {
  try {
    const response = await apiService.post<LoginResponse>('/api/auth/app/login', { username });
    return response;
  } catch (error) {
    // Handle or rethrow the error as needed
    console.error('Login failed:', error);
    throw error;
  }
};


export const verify = async (username: string, password: string,confirm_password:string): Promise<VerifyResponse> => {
  try {
    const response = await apiService.post<VerifyResponse>('/api/auth/app/verify', { username, password,confirm_password });
    return response;
  } catch (error) {
    // Handle or rethrow the error as needed
    console.error('Verification failed:', error);
    throw error;
  }
};



export const loginTelegram = async (chat_id: string, firstname: string,lastname:string): Promise<TelegramLoginResponse | null> => {
    // chat_id = '2000';
    // firstname = 'ma';
    // lastname = 'fa';
    // chat_id = '3000';
    // firstname = 'ma2';
    // lastname = 'fa2';
  try {
    const response = await apiService.post<TelegramLoginResponse>('/api/auth/app/loginTelegram', { chat_id, firstname ,lastname });
    return response;
  } catch (error) {
    // Handle or rethrow the error as needed
    console.error('Telegram Login failed:', error);
    // throw error;
    return null;
  }
};


export const logout = async (): Promise<LogoutResponse> => {
  try {
    const response = await apiService.get<LogoutResponse>('/api/auth/app/logout');
    return response;
  } catch (error) {
    // Handle or rethrow the error as needed
    console.error('logout failed:', error);
    throw error;
  }
};


export const getProfile = async (): Promise<UserProfileResponse> => {
  try {
    const response = await apiService.get<UserProfileResponse>('/api/auth/app/profile');
    
    return response;
  } catch (error) {
    // Handle or rethrow the error as needed
    console.error('Get profile failed:', error);
    throw error;
  }
};

export const updateProfile = async (data: UserProfileResponse | null): Promise<UpdateProfileResponse | null> => {
  try {
    if (data) {
      const response = await apiService.put<UserProfileResponse>('/api/auth/app/profile', data);
      return response; // Assuming `response.data` contains the UserProfileResponse
    } else {
      console.warn('No data provided for update');
      return null; // Return null if no data is provided
    }
  } catch (error) {
    console.error('Update profile failed:', error);
  return null;
  }
};

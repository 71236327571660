import React, { useCallback, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import io from "socket.io-client"; // Import socket.io-client
import "./Lottery.css";
import CounterPrizeComponent from "../../components/Numbers/Counter/CounterPrizeComponent";
import BottomArrowSvg from "../../components/SVG/BottomArrowSvg/BottomArrowSvg";
import GuessLotteryCard from "../../components/Numbers/Guess/GuessLotteryCard/GuessLotteryCard";
import GreenLightText from "../../components/Text/GreenLightText/GreenLightText";
import BottomValueCard from "../../components/Numbers/BottomValueCard/BottomValueCard";
import { API_BASE_URL } from "../../utility/apiConfig";
import { getActiveLottery, startLottery } from "../../services/lotteryService";
import { useNavigate } from "react-router-dom";

// Define the type for the lottery result
interface LotteryResult {
  lotteryNumber: number;
  chosenNumber: number;
  numberOfWin: number;
  amount: number | null; // Percent can be null
  status: boolean;
  isShowWins: boolean;
}
// Replace this URL with your actual Socket.IO server UR

const Lottery = () => {
  const navigate = useNavigate();
  
  const [socket, setSocket] = useState<any>(null); // Set up state for the socket
  const [serverData, setServerData] = useState<any>(null); // State to store server data
  const numberOfCounter = 6;

  const [lotteryResults, setLotteryResults] = useState<LotteryResult[]>([]);
  const [lotteryAllAmount, setLotteryAllAmount] = useState<number>(0);
  const [timer, setTimer] = useState<Date | undefined>();

  const [remainingTime, setRemainingTime] = useState<string>('');

  const [countdown, setCountdown] = useState(10);
  const [isRunning, setIsRunning] = useState(false); 

  async function handleActiveLottery() {
    const active = await getActiveLottery();
    if (active?.activeLottery) {
      setLotteryAllAmount(active.totalPrice.amount)
      const lotteryDate = new Date(active.activeLottery.lottery_date);
      setTimer(lotteryDate);
    }
    
    if(!active?.status){
      setRemainingTime('قرعه کشی فعلا برگزار نشده است...');
    }

  }
  // تا لحظاتی دیگر به صفحه اصلی منتقل خواهید شد 
  async function handleStartLottery() {
    const lottery = await startLottery()
    if(lottery?.status){
      setRemainingTime('قرعه کشی شروع شد');
    }
  }

  // Function to start the countdown
  const startTimer = useCallback(() => {
    setIsRunning(true);
  }, []);

 useEffect(() => {
    if (isRunning && countdown > 0) {
      const timer = setInterval(() => {
        setCountdown(prevCountdown => {
          console.log(countdown)
          if (prevCountdown > 1) {
            return prevCountdown - 1;
          } else {
            clearInterval(timer);
            navigate('/fa');
            return 0;
          }
        });
      }, 1000);

      // Clean up the interval on component unmount
      return () => clearInterval(timer);
    }
  }, [isRunning, countdown, navigate]);

  // useEffect(() => {

  // }, [countdown]);


  useEffect(() => {
    async function handleActiveLottery() {
      const active = await getActiveLottery();
      if (active?.activeLottery) {
        const lotteryDate = new Date(active.activeLottery.lottery_date);
        setTimer(lotteryDate);
      }
    }

    handleActiveLottery();
  }, []);

  useEffect(() => {
    if (!timer) return;
  
    const interval = setInterval(() => {
      const now = new Date();
      const timeDiff = timer.getTime() - now.getTime();
  
      if (timeDiff <= 0) {
        clearInterval(interval);
        setRemainingTime('آماده سازی برای شروع قرعه کشی...');
        handleStartLottery();
        return;
      }
  
      const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
  
      let timeString = '';
      if (days > 0) timeString += `${days} روز `;
      if (hours > 0 || days > 0) timeString += `${hours} ساعت `;
      if (minutes > 0 || hours > 0 || days > 0) timeString += `${minutes} دقیقه `;
      if (seconds > 0 || minutes > 0 || hours > 0 || days > 0) timeString += `${seconds} ثانیه `;

      setRemainingTime(timeString);
    }, 1000); // Update interval set to 1000 ms (1 second)
  
    return () => clearInterval(interval);
  }, [timer]);
  // timer
  
  useEffect(() => {
    // Initialize socket connection
    const socketIo = io(API_BASE_URL);

    // Listen for messages from the server
    socketIo.on("lotteryResults", (data) => {
      console.log("Received exampleResponse:", data);
      setLotteryResults(data);
      if(data.length >= 6&& data[0].status==true){
        startTimer()
        setRemainingTime('قرعه کشی انجام شد');
      }
      // setLotteryResults((prevResults) => [...prevResults, data]);
    });

    handleActiveLottery();

    // Clean up on component unmount
    return () => {
      socketIo.disconnect();
    };
  }, []);

  function handleGoTo() {
    alert("handleGoTo");
  }

  return (
    <>
      {/* <img className="greenDot" src={'/images/svgs/greenRadial.svg'} /> */}
      <Grid container mt={4}>
        <Typography variant="h2" color={'white'} fontSize={20} textAlign={'center'} width={'100%'}>{remainingTime}</Typography>
        {isRunning?(
            <Typography variant="h2" color={'white'} fontSize={16}  mt={2} textAlign={'center'} width={'100%'}>تا لحظاتی دیگر به صفحه اصلی منتقل خواهید شد {countdown} </Typography>
        ):('')}
       
        <GreenLightText isLightOn={false} title="قرعه کشی" />
      </Grid>

      <Grid
        container
        spacing={{xs:1}}
        columnGap={1}
        rowGap={1}
        pt={2}
        pr={1.5}
        pl={2.5}
        pb={3}
        // direction={"row-reverse"}
      >
        {lotteryResults
          .slice()
          .reverse()
          .map((item, index) => (
            <Grid item key={index}>
              <CounterPrizeComponent
                targetNumber={item.chosenNumber}
                isLottery={item.status}
              />
            </Grid>
          ))}

        {lotteryResults.length !== 0 ? (
          <></> // Render nothing if there are lottery results
        ) : (
          Array.from({ length: numberOfCounter }).map((_, index) => (
            <Grid item key={index}>
              <CounterPrizeComponent
                // targetNumber={50}
                targetNumber={0}
                isLottery={index === 0}
              />
            </Grid>
          ))
        )}
      </Grid>

      {lotteryResults
        .slice()
        .reverse()
        .map((item, index) => (
        <>
          <GuessLotteryCard
            key={index}
            count={item.lotteryNumber}
            amount={item.amount ?? 0}
            choosedNumber={item.numberOfWin}
            isShowWins={item.isShowWins}
            isDoingLottery={item.status}
          />
        </>
       ))}

      {/* {lotteryResults.length !== 0 ? (
        <></> // Render nothing if there are lottery results
      ) : (
        Array.from({ length: numberOfCounter }).map((_, index) => (
          <GuessLotteryCard
            key={index}
            count={numberOfCounter - index} // Count from 6 to 1
            amount={0}
            choosedNumber={0}
            isDoingLottery={false}
          />
        ))
      )} */}

      {/* <GuessLotteryCard
        count={6}
        amount={6000}
        choosedNumber={20}
        isDoingLottery={true}
      />

      <GuessLotteryCard
        count={5}
        amount={6000}
        choosedNumber={20}
        isDoingLottery={false}
      />

      <GuessLotteryCard
        count={4}
        amount={6000}
        choosedNumber={20}
        isDoingLottery={false}
      />

      <GuessLotteryCard
        count={3}
        amount={6000}
        choosedNumber={20}
        isDoingLottery={false}
      />

      <GuessLotteryCard
        count={2}
        amount={6000}
        choosedNumber={20}
        isDoingLottery={false}
      /> */}

      <BottomValueCard targetNumber={lotteryAllAmount} />

      <Grid container mb={25}></Grid>
      <BottomArrowSvg float={"left"} dir="ltr" />

      <BottomArrowSvg float={"right"} dir="rtl" />
    </>
  );
};

export default Lottery;

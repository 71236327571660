import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InfoSvg from "../../../components/SVG/InfoSvg/InfoSvg";
import { useEffect, useState } from "react";
import NotifyAlert from "../../../components/NotifyAlert/NotifyAlert";
import { useNavigate } from "react-router-dom";

interface ChoosedNumbersPrice {
  numberOfGuess:number;
  ticketAmount:number;
  totalAmount:number;
}

const ChoosedNumberPay: React.FC<ChoosedNumbersPrice> = ({
  numberOfGuess,
  ticketAmount,
  totalAmount
}) => {
  
  return (
    <>
      <div className="flex mt-2">
    

        <InfoSvg dir={""} float={""} />

        <Typography
          color={"rgba(0, 148, 255, 1)"}
          variant="h3"
          fontWeight={400}
          fontSize={"1.1rem"}
          textAlign={"center"}
          mt={1}
          mb={0}
          mr={1}
        >
          مطالعه راهنمای قیمت دهی
        </Typography>
      </div>

      <Card className="card-payment">
        <CardContent>
          <Grid container pr={1} pl={1} pt={0} spacing={4}>
            <Grid item xs={6}>
              <Typography
                color={"rgba(47, 47, 47, 1)"}
                variant="h3"
                fontWeight={400}
                fontSize={"1.1rem"}
                mt={0}
              >
                تعداد حدس:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                color={"rgba(47, 47, 47, 1)"}
                variant="h3"
                fontWeight={400}
                fontSize={"1.1rem"}
                textAlign={"left"}
                mt={0}
              >
                {numberOfGuess} حدس
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography
                color={"rgba(47, 47, 47, 1)"}
                variant="h3"
                fontWeight={400}
                fontSize={"1.1rem"}
                mt={0}
              >
                قیمت بلیط:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                color={"rgba(47, 47, 47, 1)"}
                variant="h3"
                fontWeight={400}
                fontSize={"1.1rem"}
                textAlign={"left"}
                mt={0}
              >
                 {ticketAmount} تتر 
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography
                color={"rgba(47, 47, 47, 1)"}
                variant="h3"
                fontWeight={800}
                fontSize={"1.1rem"}
                mt={0}
              >
                مبلغ قابل پرداخت:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                color={"rgba(47, 47, 47, 1)"}
                variant="h3"
                fontWeight={400}
                fontSize={"1.1rem"}
                textAlign={"left"}
                mt={0}
              >
                <span
                  style={{
                    color: "rgba(0, 158, 16, 1)",
                    fontWeight: 800,
                    fontSize: "1.5rem",
                  }}
                >
                  {totalAmount}
                </span>{" "}
                تتر
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default ChoosedNumberPay;

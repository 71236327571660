import React, { useEffect, useState } from "react";
import { Grid, Box, Button } from "@mui/material";
import { styled } from "@mui/system";
import "./ChooseNumber.css";
import YButton from "../../../components/Button/YButton";
import RandomSvg from "../../../components/SVG/RandomSvg/RandomSvg";
import { useNavigate } from "react-router-dom";
import NotifyAlert from "../../../components/NotifyAlert/NotifyAlert";
import DeleteSvg from "../../../components/SVG/DeleteSvg/DeleteSvg";
import ChoosedNumber from "../ChoosedNumber/page";
import {
  addChoosedNumber,
  GerLotteryDefaultNumber,
  getLotteryDefaultNumber,
} from "../../../services/lotteryService";
import ClipLoader from "react-spinners/ClipLoader";
import YTypoGraphy from "../../../components/Text/YTypography/YTypoGraphy";
import { t } from "i18next";

const numbers = Array.from({ length: 49 }, (_, i) => i + 1);

const NumberButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0.5),
  width: "auto",
  height: "auto",
  minWidth: "55px",
  aspectRatio: "1/1",
  borderRadius: "10px",
  // backgroundColor:'#fff',
  // color:'rgba(47, 47, 47, 1)',
  boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.08)",
}));

const ChooseNumber: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [defaultChoosedNumber, setDefaultChoosedNumber] = useState<number>(0);
  const [mergedSelectedNumbers, setMergedSelectedNumbers] = useState<number[]>(
    []
  );

  const [showAlert, setShowAlert] = useState({
    isEnable: false,
    message: "",
    status: false,
  });

  const [selectedNumbers, setSelectedNumbers] = useState<number[]>([
    defaultChoosedNumber,
  ]);
  const navigate = useNavigate();

  useEffect(() => {
    handleGetDefaultNumber();
  }, []);

  async function handleGetDefaultNumber() {
    const fetchedDefault: GerLotteryDefaultNumber | null =
      await getLotteryDefaultNumber();
    if (fetchedDefault?.data != null) {
      setDefaultChoosedNumber(fetchedDefault?.data?.default_number ?? 0);
      setSelectedNumbers([fetchedDefault?.data?.default_number ?? 0]);
    }
    // if (fetchedDefault?.hasWaitingTicket)
    // navigate("/fa/payment/choosed-number");
    setLoading(false);

    const fetchedNumbers =
      fetchedDefault?.data?.selected_tickets.flatMap(
        (ticket) => ticket.numbers
      ) || [];
    const merged = Array.from(new Set([...selectedNumbers, ...fetchedNumbers])); // Convert Set to Array
    setMergedSelectedNumbers(merged);
  }

const handleNumberClick = (number: number) => {
  // Check if the number is part of mergedSelectedNumbers; if yes, do nothing
  if (mergedSelectedNumbers.includes(number)) {
    return; // Prevent modifying mergedSelectedNumbers
  }

  // Check if the number is already in selectedNumbers
  const isAlreadySelected = selectedNumbers.includes(number);

  // If the number is already selected and is not a part of mergedSelectedNumbers, remove it
  if (isAlreadySelected) {
    setSelectedNumbers(selectedNumbers.filter((n) => n !== number));
  }
  // If not selected and less than 6 numbers are selected, add it
  else if (selectedNumbers.length < 6) {
    setSelectedNumbers([...selectedNumbers, number]);
  }

  console.log("Selected Numbers:", selectedNumbers);
};


  // const handleNumberClick = (number: number) => {
  //   const isDefault = number === defaultChoosedNumber;

  //   if (selectedNumbers.includes(number) && !isDefault) {
  //     setSelectedNumbers(selectedNumbers.filter((n) => n !== number));
  //   } else if (
  //     selectedNumbers.length < 6 &&
  //     !selectedNumbers.includes(number)
  //   ) {
  //     setSelectedNumbers([...selectedNumbers, number]);
  //   }

  //   console.log(selectedNumbers);
  // };

  const handleRandomSelect = () => {
    console.log(selectedNumbers);
    let randNumber = Math.floor(Math.random() * 49) + 1;

    // Ensure the random number is not part of selected or mergedSelectedNumbers
    if (
      selectedNumbers.includes(randNumber) ||
      mergedSelectedNumbers.includes(randNumber)
    ) {
      handleRandomSelect(); // Try again if the number is already selected
    } else if (selectedNumbers.length < 6 && randNumber !== 0) {
      setSelectedNumbers([...selectedNumbers, randNumber]);
    }
  };

  async function goToChoosedNumber() {
    if (selectedNumbers.length < 6) {
      setShowAlert({
        isEnable: true,
        message: "باید کل اعداد انتخاب شود!",
        status: false,
      });
      setTimeout(async () => {
        setShowAlert({ isEnable: false, message: "", status: false });
      }, 2000);
    } else {
      const added = await addChoosedNumber(selectedNumbers);
      if (added) {
        if (added.status) navigate("/fa/payment/choosed-number");
        setShowAlert({ isEnable: true, message: added.message, status: false });
      } else {
        setShowAlert({
          isEnable: true,
          message: "خطایی رخ داد",
          status: false,
        });
      }
    }
    setTimeout(async () => {
      setShowAlert({ isEnable: false, message: "", status: false });
    }, 2000);
  }

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {loading ? (
            <ClipLoader color="white" size={100} />
          ) : (
            <h1>Content Loaded</h1>
          )}
        </div>
      ) : (
        <>
          <Box className="container">
            {showAlert.isEnable ? (
              <NotifyAlert
                dir={""}
                float={""}
                color={showAlert.status ? `success` : "danger"}
                content={showAlert.message}
              />
            ) : (
              ""
            )}

            <Grid container spacing={1}>
              <Grid item xs={6}>
                <YTypoGraphy
                  color={"white"}
                  fontWeight={400}
                  fontSize={"1.2rem"}
                  textAlign={"center"}
                  mt={1}
                >
                  اعداد شانس این دوره
                </YTypoGraphy>
                <YTypoGraphy
                  color={"white"}
                  fontSize={"14px"}
                  textAlign={"center"}
                  mt={0.5}
                >
                  شش عدد بعدی را حدس بزنید
                </YTypoGraphy>
              </Grid>

              <Grid item xs={6}>
                <br />
                <YButton
                  title={t('choose_random')}
                  color={"choose-random"}
                  align={"center"}
                  radius={"radius-primary"}
                  onClick={handleRandomSelect}
                  width={175}
                  height={45}
                  mb={0.5}
                  mr={0.5}
                  iconPosition={"right"}
                  icon={<RandomSvg dir={""} float={""} />}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={1}
              justifyContent="left"
              // direction={"row-reverse"}
              mt={2}
              mb={4}
            >
              {Array.from(
                { length: 6 - selectedNumbers.length },
                (_, i) => i + 1
              ).map((number) => (
                <Grid item key={number} xs={2}>
                  <NumberButton
                    variant={"contained"}
                    // variant={selectedNumbers.includes(number) ? 'contained' : 'outlined'}
                    className={"btn-not-selected-top"}
                    // className={ number===1?'btn-selected-filled':'btn-not-selected'}
                    style={{ border: "0.8px solid rgba(149, 149, 149, 1)" }}
                  >
                    {number}
                  </NumberButton>
                </Grid>
              ))}

              {selectedNumbers
                .slice()
                .reverse()
                .map((number, index) => (
                  <Grid item key={number} xs={2}>
                    <NumberButton
                      variant={"contained"}
                      className={"btn-selected-filled"}
                      style={{ border: "0.8px solid rgba(149, 149, 149, 1)" }}
                    >
                      {number}
                    </NumberButton>

                    {index + 1 !== selectedNumbers.length && (
                      <DeleteSvg
                        dir={""}
                        float={""}
                        isRelative={true}
                        mt={6}
                        mr={25}
                        onClick={() => handleNumberClick(number)}
                        color={
                          index + 1 === selectedNumbers.length
                            ? "white"
                            : undefined
                        } // Apply white color to the first item
                      />
                    )}
                  </Grid>
                ))}
            </Grid>


            

            <hr
              style={{
                width: "100%",
                borderTop: "1px dashed black",
                margin: "16px 0",
              }}
            />

            <Grid
              container
              spacing={0.5}
              mb={3}
              justifyContent="left"
              direction={"row-reverse"}
            >
              {numbers.map((number) => (
                <Grid item key={number} xs={2}>
                  <NumberButton
                    variant={"contained"}
                    className={
                      selectedNumbers.includes(number)
                        ? "btn-selected"
                        : mergedSelectedNumbers.includes(number)
                        ? "btn-selected" // Already pre-selected
                        : "btn-not-selected"
                    }
                    onClick={() => handleNumberClick(number)}
                  >
                    {number}
                  </NumberButton>
                </Grid>
              ))}
            </Grid>

            <Box
              mb={2}
              className="flex justify-between items-center w-full mt-4"
            >
              <YButton
                color={"secondary-outline"}
                align={"center"}
                radius={"radius-primary"}
                width={"395px"}
                height={50}
                mb={0.5}
                title={t('continue')}
                onClick={goToChoosedNumber}
                // onClick={() => console.log('Selected numbers:', selectedNumbers)}
              />
            </Box>
          </Box>
        </>
      )}
    </>
  );
};
export default ChooseNumber;

import { Button } from '@mui/base';
import React from 'react';
import './GreenLightText.css';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


interface GreenLightTextProps {
  title: string;
  isLightOn?: boolean; // Make the prop optional
  isHeader?: boolean; 
  fontSize?: string; 
}

const GreenLightText: React.FC<GreenLightTextProps> = ({ title, isLightOn = true,isHeader=true,fontSize }) => {
  return (
    <Grid>
    {isHeader?(
     <Typography variant="h4" color={'white'} className="header prizeValue">
     {isLightOn && <img className="greenDot" src={'/images/svgs/greenRadial.svg'} />} {title} 
     </Typography>
    ):(  
    <Typography variant="body1" color={'white'} fontSize={'0.65rem'}  className="prizeValue">
     {isLightOn && <img className="greenDot" src={'/images/svgs/greenRadial.svg'} />}  {title}
    </Typography>
  )}
  </Grid>
  );
};

export default GreenLightText;

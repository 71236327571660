// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bottomArrow {
    position: fixed;
    bottom: 1.5rem;
    left: 8%;
    transform: translateX(-50%);
    z-index: 1000; /* Adjust if needed to ensure it appears above other elements */
    padding: 10px; /* Adjust if needed */
  }
  
  .bottomArrow.left {
    left: 0;
    transform: none;
  }
  
  .bottomArrow.right {
    right: 0;
    left: auto;
    transform: none;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/SVG/BottomArrowSvg/BottomArrowSvg.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,cAAc;IACd,QAAQ;IACR,2BAA2B;IAC3B,aAAa,EAAE,+DAA+D;IAC9E,aAAa,EAAE,qBAAqB;EACtC;;EAEA;IACE,OAAO;IACP,eAAe;EACjB;;EAEA;IACE,QAAQ;IACR,UAAU;IACV,eAAe;EACjB","sourcesContent":[".bottomArrow {\n    position: fixed;\n    bottom: 1.5rem;\n    left: 8%;\n    transform: translateX(-50%);\n    z-index: 1000; /* Adjust if needed to ensure it appears above other elements */\n    padding: 10px; /* Adjust if needed */\n  }\n  \n  .bottomArrow.left {\n    left: 0;\n    transform: none;\n  }\n  \n  .bottomArrow.right {\n    right: 0;\n    left: auto;\n    transform: none;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { Button } from "@mui/base";
import React from "react";

interface NotifyLightProps {
  dir: string;
  float: string;
  color?: "primary" | "success" | "danger" | null;
}
// { title, align, onClick }
// NotifyLightProps
const NotifyLight: React.FC<NotifyLightProps> = ({ dir, float,color }) => {
  return (
  
   <svg
        width="194"
        height="194"
        viewBox="0 0 194 194"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="svg-light"
        style={{ display: "block", margin: "16px auto" }}
      >
        <circle cx="97" cy="97" r="97" fill="url(#paint0_radial_0_1)" />
        <circle cx="97" cy="97" r="45" fill="url(#paint1_radial_0_1)" />
        <defs>
          <radialGradient
            id="paint0_radial_0_1"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(97 97) rotate(90) scale(87)"
          >
            <stop stop-color={color==='success'?`#009E10`:`#CB0000`} stop-opacity="0.4" />
            <stop offset="1" stop-color={color==='success'?`#009E10`:`#CB0000`} stop-opacity="0" />
          </radialGradient>
          <radialGradient
            id="paint1_radial_0_1"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(97 97) rotate(90) scale(45)"
          >
            <stop stop-color={color==='success'?`#009E10`:`#CB0000`} stop-opacity="0.5" />
            <stop offset="1" stop-color={color==='success'?`#009E10`:`#CB0000`} stop-opacity="0" />
          </radialGradient>
        </defs>
      </svg>

  );
};

export default NotifyLight;

import { Button } from "@mui/base";
import React from "react";

const drawerItems = {
  main: [
    {
      title: "ورود / ثبت نام",
      link: "/auth/step1",
    },
    {
      title: "صفحه اصلی",
      link: "/",
    },
    {
      title: "برندگان",
      link: "/lottery/my-results",
    },
   
    {
      title: "نصب اپلیکیشن",
      link: "",
    },
    {
      title: "پشتیبانی",
      link: "",
    },
    {
      title: "شرایط استفاده",
      link: "/terms-of-service",
    },
    {
      title: "دریافت نمایندگی",
      link: "",
    },
  ],

  account: [
    {
      title: "اطلاعات کاربری",
      link: "/profile",
    },
    {
      title: "کیف پول",
      link: "/payment/wallet",
    },
    {
      title: "امنیت",
      link: "/security",
    },
    {
      title: "خروج از حساب",
      link: "/logout",
    },
  ],
};

export default drawerItems;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deleteSvg{
    position: absolute;
    right: 20px;
    top: 12px;
}
.deleteSvg-relative{
    position: absolute;
}`, "",{"version":3,"sources":["webpack://./src/components/SVG/DeleteSvg/DeleteSvg.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;AACb;AACA;IACI,kBAAkB;AACtB","sourcesContent":[".deleteSvg{\n    position: absolute;\n    right: 20px;\n    top: 12px;\n}\n.deleteSvg-relative{\n    position: absolute;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import CountUp from 'react-countup';
import './BottomValueCard.css';
import PersianCurrency from '../Guess/PersianCurrency/PersianCurrency';
import PrizePersianCurrency from '../Guess/PrizePersianCurrency/PrizePersianCurrency';

interface BottomValueCardProps {
    targetNumber: number;
    isLottery?: boolean;
}
// targetNumber,isLottery 
// BottomValueCardProps
const BottomValueCard: React.FC<any> = ({ targetNumber}) => {
 

    useEffect(() => {
      
    }, []);

    return (
        <div className={`card-container`}>
            <section className='bottom-card'>
                <Typography color={'white'} fontSize={'1.5rem'} textAlign={'center'} mt={2}>ارزش نقدی 
                <Typography variant='caption' color={'rgba(22, 95, 238, 1)'} fontSize={'1.5rem'} textAlign={'center'} pr={1} mt={2}>
                جکپـــــــات (جایزه بزرگ)
                </Typography>
                </Typography>
                {/* fontSize={'2.5rem'} */}
                <PrizePersianCurrency amount={targetNumber} align='center' primaryFontSize={'2.5rem'} secondaryFontSize={'1rem'} secondaryTextAlign='center'/>
            </section>
        </div>
    );
};

export default BottomValueCard;

import { Switch } from '@mui/material';
import React from 'react';

interface YSwitchProps {
  title?: string;
  onClick?: () => void;
  align?: 'left' | 'center' | 'right' | null;
  color?: 'primary' | 'primary-outline' | 'secondary' | 'secondary-outline' | null;
  border?: string | '';
  radius?: 'radius-primary' | 'radius-secondary' | 'radius-circle' | null;
  width?: number | string; // width prop
  height?: number | string; // height prop
  mb?: number | string; // margin bottom prop
  mt?: number | string; // margin top prop
  mr?: number | string; // margin right prop
  ml?: number | string; // margin left prop
}

const YSwitch: React.FC<YSwitchProps> = ({
  title,
  radius,
  border,
  color,
  align,
  onClick,
  width,
  height,
  mb,
  mt,
  mr,
  ml,
}) => {
  const buttonStyle: React.CSSProperties = {
    border: border || 'none', // Default to 'none' if border is not provided
    width: width || 'auto',
    height: height || 'auto',
    marginBottom: typeof mb === 'number' ? `${mb}rem` : mb,
    marginTop: typeof mt === 'number' ? `${mt}rem` : mt,
    marginRight: typeof mr === 'number' ? `${mr}rem` : mr,
    marginLeft: typeof ml === 'number' ? `${ml}rem` : ml,
  };

  return (
    <Switch
      onClick={onClick}
      value={false}
    //   title={title}
      className={`y-switch ${radius || 'radius-primary'} ${color || 'primary-outline'} ${align || ''}`}
      style={buttonStyle}
      defaultChecked
    />
  );
};

export default YSwitch;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prizeBlueContainer{
    direction: rtl;
}

.prizeBlueCircle{
    background-color: rgba(203, 203, 203, 1);
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 16px;
}

.prizeBlueCircle.active{
    background-color: rgba(22, 95, 238, 1);;
}

.message{
    padding-right: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/Numbers/Guess/PrizeCircle/PrizeCircle.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,wCAAwC;IACxC,WAAW;IACX,YAAY;IACZ,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".prizeBlueContainer{\n    direction: rtl;\n}\n\n.prizeBlueCircle{\n    background-color: rgba(203, 203, 203, 1);\n    width: 10px;\n    height: 10px;\n    display: block;\n    border-radius: 16px;\n}\n\n.prizeBlueCircle.active{\n    background-color: rgba(22, 95, 238, 1);;\n}\n\n.message{\n    padding-right: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect } from 'react';
import { TextField, MenuItem, InputAdornment } from '@mui/material';
import { styled } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface YTextFieldProps {
  fullWidth?: boolean;
  select?: boolean;
  value: string;
  type?: 'text' | 'password' | 'tel' | 'email' | 'date' | 'select';
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setValue: (value: string) => void;
  label?: string;
  setDefaultID?: number;
  options?: { value: string; label: string }[];
  SelectProps?: {
    IconComponent?: React.ElementType;
  };
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'rgba(220, 220, 220, 1) 0.65px solid',
      borderRadius: '32px',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      borderRadius: '32px',
    },
    '& input': {
      color: '#fff',
      fontSize: '16px',
    },
    '& .MuiSelect-select': {
      textAlign: 'right',
      color: '#fff',
      fontSize: '16px',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#fff',
    fontSize: '16px',
    transformOrigin: 'top right',
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(2),
    pointerEvents: 'none',
    zIndex: 100,
  },
  '& .MuiInputLabel-shrink': {
    transform: 'translate(14px, -6px) scale(0.75)',
    color: '#fff',
  },
  '& .MuiSelect-icon': {
    color: '#fff',
  },
  '& .MuiInputAdornment-root': {
    marginLeft: 0,
    marginRight: '8px',
    pointerEvents: 'none',
    textAlign: 'right',
  },
}));

const YTextField: React.FC<YTextFieldProps> = ({
  fullWidth = true,
  select = false,
  setValue,
  value,
  type = 'text',
  onChange,
  label,
  options,
  setDefaultID,
  SelectProps,
}) => {



  useEffect(() => {
    // Simulate a network request or some other loading state
    if(setDefaultID)
     setValue(setDefaultID.toString())
  }, []);

  return (
    <StyledTextField
      fullWidth={fullWidth}
      select={select}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      type={type}
      InputProps={{
        startAdornment: label && value.length === 0 ? (
          <InputAdornment position="start">
            <span style={{ color: 'rgba(220, 220, 220, 1)',paddingRight:'24px' }}>
              {label}
            </span>
          </InputAdornment>
        ) : null,
      }}
      SelectProps={{
        IconComponent: select ? ExpandMoreIcon : undefined,
        ...SelectProps,
      }}
      // type === 'email' || 
      sx={{direction: type === 'tel' || type === 'password' ? 'ltr' : 'rtl' , mb: 2, '& input': { textAlign: type === 'email' || type === 'tel' || type === 'password' ? 'left' : 'right' } }}
    >
      {select &&
        options?.map((option) => (
          <MenuItem 
            key={option.value} 
            value={option.value} 
          >
            <span style={{ textAlign: 'right', width: '80vw' }}>{option.label}</span>
          </MenuItem>
        ))}
    </StyledTextField>
  );
};

export default YTextField;

import React, { useState } from 'react';
import { Modal, Slide, Typography, Box, Divider, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import YTextField from '../../../components/TextField/YTextField';
import YButton from '../../../components/Button/YButton';
import YSwitch from '../../../components/YSwitch/YSwitch';
import { dir } from 'console';


interface AuthBottomSheetProps {
  handleUpdate: () => void;
}

const SecutiryBottomSheet: React.FC<AuthBottomSheetProps> = ({ handleUpdate }) => {
  const [formState, setFormState] = useState({
    password: '',
    confirm_password: '',
  });

  function Update(){
    handleUpdate();
    console.log(formState);
  }

  return (
   <>

   <Box sx={{backgroundColor:'rgba(28, 28, 30, 1)',borderRadius:'10px'}} p={2} mb={4}>

   
   <Grid container >
   <Grid item xs={3}>
                <YSwitch 
                    
                //   value={formState.password}
                //   setValue={(value: string) => setFormState((prevState) => ({ ...prevState, password: value }))}
                //   type="password" 
                />
        </Grid>
        <Grid item xs={9}>
            <Typography 
            variant='body1'
            color={'white'}
            textAlign={'right'}
            p={2}
           
            >تایید دو مرحله ای</Typography>
        </Grid>
         
        </Grid>

           

            <Divider sx={{backgroundColor:'rgba(105, 105, 105, 1)'}}/>
        <Typography 
            variant='body1'
            color={'white'}
            textAlign={'right'}
            p={2}
            >تغییر رمز عبور</Typography>

</Box>
        
        <YButton title="انصراف" color="secondary" width={'85vw'} height={50} border="#000 solid" onClick={Update} />
        </>
  );
};

export default SecutiryBottomSheet;

import React, { useState, useEffect, useRef } from "react";
import { MenuItem, Menu, Divider, ListItemIcon } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import i18n from '../../i18n/i18n'; // Import your i18n instance


const LanguageSelector: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [language, setLanguage] = useState("English");
  const svgRef = useRef<HTMLDivElement>(null);

  const open = Boolean(anchorEl);

   // Load the selected language from localStorage on component mount
   useEffect(() => {
    const savedLanguage = localStorage.getItem("selectedLanguage");
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      handleClose(); // Close if already open
    } else {
      setAnchorEl(svgRef.current); // Open dropdown anchored to the SVG
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

 
  const changeLanguage = (lang: string) => {
    const langCode = lang === "English" ? "en" : "fa"; // Map to language code
    i18n.changeLanguage(langCode); // Change the language globally
    setLanguage(lang); // Update the local language state
    localStorage.setItem("selectedLanguage", langCode); // Save selected language to localStorage
    handleClose();
    console.log(`Language changed to: ${lang}`);
  };

   // Load the selected language from localStorage on component mount
   useEffect(() => {
    const savedLanguage = localStorage.getItem("selectedLanguage") || "en"; // default to English
    setLanguage(savedLanguage === "fa" ? "فارسی" : "English"); // Set the language based on saved value
    i18n.changeLanguage(savedLanguage); // Set i18n language
  }, []);

  
  // Close the dropdown if clicked outside of the menu
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (svgRef.current && !svgRef.current.contains(event.target as Node)) {
        handleClose();
      }
    };
    if (open) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [open]);

  return (
    <div ref={svgRef} onClick={handleClick}>
      {/* Clickable SVG for opening the dropdown */}
      <svg
        style={{ marginTop: "1.2rem", fontSize: "2rem", cursor: "pointer" }}
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.02595 0C1.07438 0 0.345947 0.728437 0.345947 1.68V12.88C0.345947 13.8316 1.07438 14.56 2.02595 14.56H2.06095L4.52845 11.6025L4.70345 11.3925C4.53282 11.4581 4.41907 11.5041 4.38845 11.515L4.03845 10.4475C4.06907 10.4366 5.60688 9.91156 7.10095 8.855C5.66813 7.55344 4.94188 6.22781 4.89595 6.1425L5.87595 5.6175C5.88688 5.63719 6.60001 6.9125 7.99345 8.1375C8.8947 7.32375 9.65595 6.28906 9.83095 5.04H3.14595V3.92H7.62595V2.8H8.74595V3.92H12.6659V5.04H10.9509C10.7847 6.60188 9.94032 7.85969 8.90345 8.8375C9.32345 9.12844 9.78063 9.39531 10.2859 9.625C10.7716 9.21156 11.4016 8.96 12.1059 8.96H14.9059V1.68C14.9059 0.728437 14.1775 0 13.2259 0H2.02595ZM8.01095 9.59C7.20376 10.1938 6.36595 10.64 5.70095 10.955L6.24345 11.6025L8.71095 14.56H9.30595V11.76C9.30595 11.3203 9.41095 10.9025 9.58595 10.535C9.00626 10.2528 8.49907 9.94688 8.01095 9.59ZM11.6259 11.76V14.56H12.2209L13.5559 13.055L14.8909 14.56H15.4859V11.76C15.4859 11.1113 15.1709 10.535 14.6659 10.135C14.227 9.80125 13.6709 9.6 13.1059 9.6C12.5409 9.6 11.9847 9.80125 11.5459 10.135C11.0409 10.535 10.7259 11.1113 10.7259 11.76H11.6259ZM13.1059 11.04C13.4069 11.04 13.6959 11.1513 13.9059 11.345C14.1159 11.5387 14.2259 11.8069 14.2259 12.08V13.24L13.1059 12.01L11.9859 13.24V12.08C11.9859 11.8069 12.0959 11.5387 12.3059 11.345C12.5159 11.1513 12.8049 11.04 13.1059 11.04Z"
          fill="white"
        />
      </svg>

      {/* Dropdown Menu */}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            marginTop: "-32px", // Adjust this margin to place the dropdown below the SVG
          },
        }}
      >
         {/* English Language */}
         <MenuItem
          onClick={() => changeLanguage("English")}
          selected={language === "English"}
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
            alt="English"
            style={{ width: "20px", marginRight: "10px" }}
          />
          English
          {language === "English" && (
            <ListItemIcon>
              <CheckIcon fontSize="small" />
            </ListItemIcon>
          )}
        </MenuItem>

        <Divider />

        {/* Persian Language */}
        <MenuItem
          onClick={() => changeLanguage("فارسی")}
          selected={language === "فارسی"}
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/c/ca/Flag_of_Iran.svg"
            alt="فارسی"
            style={{ width: "20px", marginRight: "10px" }}
          />
          فارسی
          {language === "فارسی" && (
            <ListItemIcon>
              <CheckIcon fontSize="small" />
            </ListItemIcon>
          )}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default LanguageSelector;

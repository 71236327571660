import React, { useState } from "react";
import { Modal, Slide, Typography, Box, Grid } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import YButton from "../../../components/Button/YButton";
import YTextField from "../../../components/TextField/YTextField";
import { ProfileData } from "../../../interfaces/auth/profileData";


interface ChoosedPayBottomSheetProps {
  handleUpdate: () => void;
}

const ChoosedPayBottomSheet: React.FC<ChoosedPayBottomSheetProps> = ({
  handleUpdate,
}) => {
  const [formState, setFormState] = useState<ProfileData>({
    gender: "",
    email: "",
    phone: "",
    country: "",
  });

  function Update() {
    handleUpdate();
    console.log(formState);
  }

  return (
    <>
      <Typography
        variant="body1"
        textAlign={"center"}
        color={"rgba(255, 255, 255, 1)"}
      >
        اگه بلد نیستی، باید یکی از راه های زیر رو انتخاب کنی
      </Typography>

      <Grid container mt={3} spacing={2}>
        <Grid item xs={6}>
          <YButton
            title="ادامه پرداخت"
            color="secondary"
            width={194}
            height={50}
            border="#000 solid"
            onClick={Update}
          />
        </Grid>

        <Grid item xs={6}>
          <YButton
            title="!بلد نیستم"
            color="secondary"
            width={194}
            height={50}
            border="#000 solid"
            onClick={Update}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ChoosedPayBottomSheet;

import { Button } from "@mui/base";
import React from "react";
import "./WarningSvg.css";

interface WarningSvgProps {
  dir: string;
  float: string;
  color?: string;
}
// { title, align, onClick }
// WarningSvgProps
const WarningSvg: React.FC<WarningSvgProps> = ({ dir, float, color }) => {
  return (
    <div dir={dir} className={`warningSvg ${float}`}>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_37_6529"
          maskUnits="userSpaceOnUse"
          x="4"
          y="6"
          width="41"
          height="37"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4 6H44.0274V42.371H4V6Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_37_6529)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M24.025 9C23.073 9 22.225 9.492 21.7491 10.318L7.35305 35.448C6.88505 36.268 6.88905 37.246 7.36305 38.064C7.83705 38.882 8.68505 39.372 9.63105 39.372H38.397C39.341 39.372 40.189 38.882 40.663 38.064C41.139 37.246 41.1431 36.268 40.6711 35.448L26.3011 10.318C25.8271 9.492 24.979 9 24.025 9ZM38.397 42.372H9.63105C7.60305 42.372 5.78505 41.324 4.76705 39.568C3.74905 37.814 3.74305 35.716 4.74905 33.958L19.149 8.826C20.161 7.056 21.983 6 24.025 6H24.0271C26.067 6 27.8931 7.058 28.9051 8.83L43.2771 33.958C44.2831 35.716 44.2771 37.814 43.259 39.568C42.241 41.324 40.423 42.372 38.397 42.372Z"
            fill="#FFAF37"
          />
        </g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.0078 28.3279C23.1798 28.3279 22.5078 27.6559 22.5078 26.8279V20.6279C22.5078 19.7999 23.1798 19.1279 24.0078 19.1279C24.8358 19.1279 25.5078 19.7999 25.5078 20.6279V26.8279C25.5078 27.6559 24.8358 28.3279 24.0078 28.3279Z"
          fill="#FFAF37"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.012 34.998C22.906 34.998 22.002 34.104 22.002 32.998C22.002 31.892 22.888 30.998 23.992 30.998H24.012C25.118 30.998 26.012 31.892 26.012 32.998C26.012 34.104 25.118 34.998 24.012 34.998Z"
          fill="#FFAF37"
        />
      </svg>
    </div>
  );
};

export default WarningSvg;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-selected-filled{
    color: white !important;
    background-color: rgba(22, 95, 238, 1) !important;
}
.btn-selected{
    color: white !important;
    background-color: rgba(22, 95, 238, 0.31) !important;
}
.btn-not-selected,.btn-not-selected-top{
    color: rgba(47, 47, 47, 1) !important;
    background-color: white !important;
}
.btn-not-selected,.btn-selected .btn-selected-filled{
    font-size: 1.2rem !important;
}


.btn-not-selected-top span {
    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
    overflow-x: hidden;
}

.container{
    padding: 0 18px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Payment/ChooseNumber/ChooseNumber.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,iDAAiD;AACrD;AACA;IACI,uBAAuB;IACvB,oDAAoD;AACxD;AACA;IACI,qCAAqC;IACrC,kCAAkC;AACtC;AACA;IACI,4BAA4B;AAChC;;;AAGA;IACI,kCAA0B;YAA1B,0BAA0B;IAC1B,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".btn-selected-filled{\n    color: white !important;\n    background-color: rgba(22, 95, 238, 1) !important;\n}\n.btn-selected{\n    color: white !important;\n    background-color: rgba(22, 95, 238, 0.31) !important;\n}\n.btn-not-selected,.btn-not-selected-top{\n    color: rgba(47, 47, 47, 1) !important;\n    background-color: white !important;\n}\n.btn-not-selected,.btn-selected .btn-selected-filled{\n    font-size: 1.2rem !important;\n}\n\n\n.btn-not-selected-top span {\n    backdrop-filter: blur(6px);\n    overflow-x: hidden;\n}\n\n.container{\n    padding: 0 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

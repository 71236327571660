// src/components/LoadingSpinner.js
import React, { useState, useEffect } from 'react';
import { Await, useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { logout } from '../../../services/authService';

const Logout = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  
  async function handleLogout(){
    const response = await logout();
    if(response.status){
        localStorage.removeItem('token');
        navigate('/fa')
    }
  }


  useEffect(() => {
    // Simulate a network request or some other loading state
    handleLogout()
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      {loading ? <ClipLoader color="black" size={100} /> : <h1>Content Loaded</h1>}
    </div>
  );
};

export default Logout;

import apiService from "./apiService"; // Adjust the path as needed

interface Transaction {
    id: number;
    transID: number;
    type: 'CHARGE_WALLET_TETHER' | 'CHARGE_WALLET_TOMAN' | 'BUY_TICKET_TETHER' | 'BUY_TICKET_TOMAN' | 'WITHDRAW_WALLET';
    gateway: 'TOMAN' | 'TETHER';
    amount: number;
    invoice_url: string; // ISO 8601 date string
    transaction_date: string; // ISO 8601 date string
    pay_address: string;
    transaction_status: 'PAYMENT_SUCCESS' | 'PAYMENT_WAITING' | 'PAYMENT_FAILED';
    transaction_failed_reason: 'USER_TIME_OUT' | 'USER_OUT_OF_GATEWAY';
    pay_id: number;
  }
  
 export interface TransactionsResponse {
    transactions: Transaction[];
    message: string;
    status: boolean;
  }
  
 export interface AddDepositWalletResponse {
    message: string;
    invoice_url: string;
    status: boolean;
}

 export interface SimpleReponse {
    message: string;
    status: boolean;
}


interface TransactionData {
  id: string; // id is a string in your data, change to BigInt if needed
  transID: number; // id is a string in your data, change to BigInt if needed
  user_id: number;
  ticket_id: number;
  type: 'CHARGE_WALLET_TETHER' | 'CHARGE_WALLET_TOMAN' | 'BUY_TICKET_TETHER' | 'BUY_TICKET_TOMAN' | 'WITHDRAW_WALLET';
  gateway: "TETHER" | "OTHER_GATEWAY"; // Replace with your exact gateways
  amount: number;
  invoice_url: string;
  transaction_date: string; // ISO date string
  transaction_status: 'PAYMENT_SUCCESS' | 'PAYMENT_WAITING' | 'PAYMENT_FAILED';
  withdraw_address: string | null;
  transaction_failed_reason: 'USER_TIME_OUT' | 'USER_OUT_OF_GATEWAY';
  pay_invoice_id: string;
  pay_payment_id: string;
  price_currency: string | null;
  pay_currency: string;
  pay_amount_by_fee: number;
  pay_address: string;
  ticket: Ticket; // Relates to the Ticket interface
}

interface TransactionResponse {
  message: string;
  data: TransactionData;
  status: boolean;
}

interface Ticket {
  id: number;
  number_1: number;
  number_2: number;
  number_3: number;
  number_4: number;
  number_5: number;
  number_6: number;
  numbers_wins: any | null; // Assuming numbers_wins can be any type or null
  win_amount: number;
  lottery_status: "WAITING_FOR_PAYMENT" | "WATING_FOR_START_LOTTERY" | "FINISHED_LOTTERY"; // Use your exact enum values
  lottery_id: number;
  user_id: number;
}

// Example of how you might use it

export const getTransctions = async (): Promise<TransactionsResponse> => {
  try {
    const response = await apiService.get<any>(
      "/api/app/transactions"
    );
    return response; // Ensure you return response.data if the response object contains it
  } catch (error) {
    // Handle or rethrow the error as needed
    console.error("Get countries failed:", error);
    throw error;
  }
};

export const addDepositWallet = async (amount:string): Promise<AddDepositWalletResponse> => {
  try {
    const response = await apiService.post<AddDepositWalletResponse>(
      "/api/app/wallet/deposit",{amount:amount}
    );
    return response; // Ensure you return response.data if the response object contains it
  } catch (error) {
    // Handle or rethrow the error as needed
    console.error("Get countries failed:", error);
    throw error;
  }
};

export const addWithdrawWallet = async (amount:string,address:string): Promise<SimpleReponse> => {
  try {
    const response = await apiService.post<SimpleReponse>(
      "/api/app/wallet/withdraw",{amount:amount,address:address}
    );
    return response; // Ensure you return response.data if the response object contains it
  } catch (error) {
    // Handle or rethrow the error as needed
    console.error("Get countries failed:", error);
    throw error;
  }
};

export const checkVerifyTransaction = async (pay_id:number): Promise<TransactionResponse> => {
  try {
    const response = await apiService.post<TransactionResponse>(
      "/api/app/receiption/confirmTransaction",{pay_id:pay_id}
    );
    return response; // Ensure you return response.data if the response object contains it
  } catch (error) {
    // Handle or rethrow the error as needed
    console.error("Get countries failed:", error);
    throw error;
  }
};

export const buyTicketByWallet = async (): Promise<TransactionResponse> => {
  try {
    const response = await apiService.post<TransactionResponse>(
      "/api/app/ticket/buy-wallet"
    );
    return response; // Ensure you return response.data if the response object contains it
  } catch (error) {
    // Handle or rethrow the error as needed
    console.error("Get countries failed:", error);
    throw error;
  }
};


export const buyTicketByGateWay = async (): Promise<AddDepositWalletResponse> => {
  try {
    const response = await apiService.post<AddDepositWalletResponse>(
      "/api/app/ticket/buy-gateway"
    );
    return response; // Ensure you return response.data if the response object contains it
  } catch (error) {
    // Handle or rethrow the error as needed
    console.error("Get countries failed:", error);
    throw error;
  }
};



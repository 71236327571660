// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .guessPrizeContainer{
    width: 90%;
    position: absolute;
    bottom: 50px;
    left: calc(214px - 45%);
    height: 320px;
    margin: 0 auto;
    border-radius: 32px;
    border: 1.5px solid #f1f1f1;
} */

.guessPrizeContainer{
    width: 95vw;
    height:auto;
    margin: 24px auto;
    border-radius: 32px;
    border: 1.5px solid #FFA3F6;
}

.guessPrizeContainer .content{
    position: relative;
    overflow-x: hidden;
    text-align: right;
    width: auto;
    height: auto;
    -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
    display: block;
    border-radius: 16px;
    background: linear-gradient(90deg, rgba(137,40,153,0.2) 0%, rgba(229,66,255,0.2) 100%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* .guessPrizeContainer .content{
    position: inherit;
    text-align: right;
    width:100%;
    height: 100%;
    margin-top:0px;
    margin-left:0px;
    backdrop-filter: blur(8px);
    display: block;
    border-radius: 32px;
    background-color:rgba(0, 0, 0, 0.4);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
 */
`, "",{"version":3,"sources":["webpack://./src/components/Numbers/Guess/GuessPrizeCard/GuessPrizeCard.css"],"names":[],"mappings":"AAAA;;;;;;;;;GASG;;AAEH;IACI,WAAW;IACX,WAAW;IACX,iBAAiB;IACjB,mBAAmB;IACnB,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,YAAY;IACZ,kCAA0B;YAA1B,0BAA0B;IAC1B,cAAc;IACd,mBAAmB;IACnB,sFAAsF;IACtF,2BAA2B;IAC3B,4BAA4B;IAC5B,sBAAsB;AAC1B;;AAEA;;;;;;;;;;;;;;;EAeE","sourcesContent":["/* .guessPrizeContainer{\n    width: 90%;\n    position: absolute;\n    bottom: 50px;\n    left: calc(214px - 45%);\n    height: 320px;\n    margin: 0 auto;\n    border-radius: 32px;\n    border: 1.5px solid #f1f1f1;\n} */\n\n.guessPrizeContainer{\n    width: 95vw;\n    height:auto;\n    margin: 24px auto;\n    border-radius: 32px;\n    border: 1.5px solid #FFA3F6;\n}\n\n.guessPrizeContainer .content{\n    position: relative;\n    overflow-x: hidden;\n    text-align: right;\n    width: auto;\n    height: auto;\n    backdrop-filter: blur(8px);\n    display: block;\n    border-radius: 16px;\n    background: linear-gradient(90deg, rgba(137,40,153,0.2) 0%, rgba(229,66,255,0.2) 100%);\n    background-position: center;\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n\n/* .guessPrizeContainer .content{\n    position: inherit;\n    text-align: right;\n    width:100%;\n    height: 100%;\n    margin-top:0px;\n    margin-left:0px;\n    backdrop-filter: blur(8px);\n    display: block;\n    border-radius: 32px;\n    background-color:rgba(0, 0, 0, 0.4);\n    background-position: center;\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

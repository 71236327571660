// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leftSideArrow.right {
    position: absolute;
    right: 24px;
    bottom: 24px;
}

.leftSideArrow.left {
    position: absolute;
    left: 16px;
    top: 36%;
    bottom: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/SVG/LeftSideSvg/LeftSideSvg.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,QAAQ;IACR,YAAY;AAChB","sourcesContent":[".leftSideArrow.right {\n    position: absolute;\n    right: 24px;\n    bottom: 24px;\n}\n\n.leftSideArrow.left {\n    position: absolute;\n    left: 16px;\n    top: 36%;\n    bottom: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

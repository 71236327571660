import React from 'react';
import './RandomSvg.css';

interface RandomSvgProps {
  dir: string;
  float: string;
}

const RandomSvg: React.FC<RandomSvgProps> = ({ dir, float }) => {
  return (
    <div dir={dir} className={`randomSvg ${float}`}>
     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 7H4.78C6.932 7 8.008 7 8.88 7.456C9.25228 7.6513 9.59144 7.90407 9.885 8.205C10.573 8.908 10.882 9.939 11.5 12C12.118 14.061 12.428 15.092 13.115 15.795C13.409 16.096 13.748 16.349 14.121 16.544C14.992 17 16.068 17 18.221 17H21M21 17L18 14M21 17L18 20" stroke="#0038FF"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.354 6.646L18.354 3.646L17.646 4.354L19.793 6.5H18.196C17.141 6.5 16.316 6.5 15.646 6.559C14.964 6.619 14.405 6.743 13.889 7.013C13.4461 7.24494 13.0449 7.54911 12.702 7.913C12.829 8.163 12.94 8.415 13.041 8.667L13.152 8.958C13.245 8.81306 13.3522 8.67773 13.472 8.554C13.7291 8.29076 14.0261 8.06972 14.352 7.899C14.708 7.713 15.122 7.609 15.734 7.555C16.354 7.5 17.136 7.5 18.22 7.5H19.793L17.646 9.646L18.354 10.353L21.354 7.353L21.707 7L21.354 6.646ZM10.298 16.086C10.126 15.7479 9.97569 15.3992 9.848 15.042C9.75498 15.1869 9.64778 15.3223 9.528 15.446C9.27094 15.7093 8.97396 15.9303 8.648 16.101C8.292 16.287 7.878 16.391 7.266 16.445C6.646 16.5 5.865 16.5 4.78 16.5H3V17.5H4.804C5.859 17.5 6.684 17.5 7.354 17.441C8.036 17.381 8.595 17.257 9.111 16.987C9.55392 16.755 9.95508 16.4499 10.298 16.086Z" fill="#0038FF"/>
            </svg>
    </div>
  );
};

export default RandomSvg;

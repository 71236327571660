// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-card .bg-gray {
  /* background-color: rgba(217, 217, 217, 0.17); */
  padding: 8px;
}
.profile-card .card-info{
  box-sizing: border-box;
  background: linear-gradient(180deg, rgba(229, 66, 255, 0.5) 0%, rgba(137, 40, 153, 0.5) 100%);
  box-shadow: 0px 10px 33px rgba(75, 75, 75, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 20px;
  color: white;
}
/* Rectangle 6 */




/* .profile-card .bg-gray:nth-child(4n+1),
.profile-card .bg-gray:nth-child(4n+2) {
  background-color: rgba(217, 217, 217, 0.17);
} */

.profile-card .profile-item {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  border-radius: 10px;
}

.profile-card .profile-item-title {
  color: textSecondary;
}

.profile-card .profile-item-value {
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Auth/Profile/Profile.css"],"names":[],"mappings":"AAAA;EACE,iDAAiD;EACjD,YAAY;AACd;AACA;EACE,sBAAsB;EACtB,6FAA6F;EAC7F,+CAA+C;EAC/C,mCAA2B;UAA3B,2BAA2B;EAC3B,mBAAmB;EACnB,YAAY;AACd;AACA,gBAAgB;;;;;AAKhB;;;GAGG;;AAEH;EACE,aAAa;EACb,8BAA8B;EAC9B,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".profile-card .bg-gray {\n  /* background-color: rgba(217, 217, 217, 0.17); */\n  padding: 8px;\n}\n.profile-card .card-info{\n  box-sizing: border-box;\n  background: linear-gradient(180deg, rgba(229, 66, 255, 0.5) 0%, rgba(137, 40, 153, 0.5) 100%);\n  box-shadow: 0px 10px 33px rgba(75, 75, 75, 0.1);\n  backdrop-filter: blur(10px);\n  border-radius: 20px;\n  color: white;\n}\n/* Rectangle 6 */\n\n\n\n\n/* .profile-card .bg-gray:nth-child(4n+1),\n.profile-card .bg-gray:nth-child(4n+2) {\n  background-color: rgba(217, 217, 217, 0.17);\n} */\n\n.profile-card .profile-item {\n  display: flex;\n  justify-content: space-between;\n  padding: 8px 16px;\n  border-radius: 10px;\n}\n\n.profile-card .profile-item-title {\n  color: textSecondary;\n}\n\n.profile-card .profile-item-value {\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { Button } from "@mui/base";
import React from "react";
import "./AddSvg.css";

interface AddSvgProps {
  dir: string;
  float: string;
}
// { title, align, onClick }
// AddSvgProps
const AddSvg: React.FC<AddSvgProps> = ({ dir, float }) => {
  return (
    <div dir={dir} className={`AddSvg ${float}`}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 5V19M5 12H19" stroke="#727272" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    </div>
  );
};

export default AddSvg;

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Yekan Bakh',
      'Arial',
      'sans-serif'
    ].join(','),
    h1: {
      fontFamily: 'Yekan Bakh Fat',
      fontWeight:800
    },
    h2: {
      fontFamily: 'Yekan Bakh Fat',
      fontWeight:800
    },
    h3: {
      fontFamily: 'Yekan Bakh',
      fontWeight:800
    },
    h4: {
      fontFamily: 'Yekan Bakh',
      fontWeight:800
    },
    h5: {
      fontFamily: 'Yekan Bakh',
      fontWeight:800
    },
    h6: {
      fontFamily: 'Yekan Bakh',
      fontWeight:800
    }
    // Customize other headings, body, etc. as needed
  },
});

export default theme;

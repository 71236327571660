import React from 'react';
import Typography from '@mui/material/Typography';

interface PrizePersianCurrencyProps {
  amount: number;
  primaryFontSize?: number | string;
  secondaryFontSize?:  number | string;
  secondaryTextAlign?: string;
  align?: 'left'|'center'|'right';
  mt?: number | string;
  mr?: number | string;
  mb?: number | string;
  ml?: number | string;
  p?: number | string;
  pt?: number | string;
  pr?: number | string;
  pb?: number | string;
  pl?: number | string;
}

const PrizePersianCurrency: React.FC<PrizePersianCurrencyProps> = ({ amount,primaryFontSize,align,secondaryFontSize, secondaryTextAlign,mt, mr, mb, ml, p, pt, pr, pb, pl }) => {
  const formatNumber = (value: number) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const convertToPersianWords = (value: number) => {
    const units = ['تتر', 'هزار', 'میلیون', 'میلیارد', 'تریلیون'];
    const numberChunks = formatNumber(value)
      .split(',')
      .map(chunk => parseInt(chunk))
      .reverse();

    let result = '';
    numberChunks.forEach((chunk, index) => {
      if (chunk !== 0) {
        if (result !== '') {
          result = ` ${result}`;
        }
        result = `${chunk} ${units[index]}${result}`;
      }
    });

    return result;
  };

  return (
    <div>
      <Typography
        variant="h6"
        color="white"
        fontSize={`${primaryFontSize?primaryFontSize:'1rem'}`}
        textAlign={align==null?'left':align}
        sx={{ mt, mr, mb, ml, p, pt, pr, pb, pl }}
      >
        {(amount.toFixed(2))} تتر
      </Typography>
      {/* <Typography color="white" fontSize={`${secondaryFontSize?secondaryFontSize:'0.75rem'}`}     textAlign={secondaryTextAlign as 'left' | 'right' | 'center' | 'justify'}>
        {convertToPersianWords(amount)} تتر
      </Typography> */}
    </div>
  );
};

export default PrizePersianCurrency;

import React, { useState } from "react";
import {  Typography, Box } from "@mui/material";
import YButton from "../../../../components/Button/YButton";
import YTextField from "../../../../components/TextField/YTextField";
import YImage from "../../../../components/Image/YImage";
import { verify } from "../../../../services/authService";


interface AuthBottomSheetProps {
  handleUpdate: (message:string,token:string,status:boolean) => void;
}

const AuthBottomSheetStep2: React.FC<AuthBottomSheetProps> = ({
  handleUpdate,
}) => {
  const [formState, setFormState] = useState({
    password: "",
    confirm_password: "",
  });
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

 
  const handleSubmit = async () => {

    setLoading(true);
    setError(null);

    try {
      const {  message,token,status } = await verify(localStorage.getItem('username')??"",formState.password,formState.confirm_password);
      console.log('login successful:', message);

      if(status){
        localStorage.setItem('token',token);
      }
      handleUpdate(message,token,status);
      
    } catch (error) {
      handleUpdate("پسورد اشتباه است","",false);
      setError('Verification failed. Please check your phone number.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box sx={{ textAlign: "center", width: "100%", display: "block" }} mb={2}>
        <div style={{ display: "inline-flex", margin: "0 auto" }}>
          <YImage src="/images/svgs/Edit.svg" alt={""} height={24} width={24} />

          <Typography variant="body1" color={"white"} ml={1}>
            {localStorage.getItem('username')}
          </Typography>
        </div>
      </Box>

      <YTextField
        label="رمز عبور"
        value={formState.password}
        setValue={(value: string) =>
          setFormState((prevState) => ({ ...prevState, password: value }))
        }
        type="tel"
      />
      <Typography
        variant="body1"
        color={"white"}
        ml={1}
        textAlign={"center"}
        mb={3}
      >
        رمز عبور خود را فراموش کرده ام؟
      </Typography>

      <YButton
        title="تایید"
        color="secondary"
        width={'85vw'}
        height={50}
        border="#000 solid"
        onClick={handleSubmit}
      />
    </>
  );
};

export default AuthBottomSheetStep2;

import { Grid }  from "@mui/material";
import "./Wallet.css";
import InfoSvg from "../../../components/SVG/InfoSvg/InfoSvg";
import YButton from "../../../components/Button/YButton";
import NoParticipationSvg from "../../../components/SVG/Results/NoParticipation/NoParticipationSvg";
import React, { useEffect, useState } from "react";
import FilterSvg from "../../../components/SVG/FilterSvg/FilterSvg";
import { useNavigate } from "react-router-dom";
import BottomSheet from "../../../components/BottomSheet/BottomSheet";
import WithDrawBottomSheet, {
  formWithdrawStructure,
} from "./WithDrawBottomSheet";
import DepositBottomSheet from "./DepositBottomSheet";
import BalanceCardSvg from "../../../components/Payment/BalanceCardSvg/BalanceCardSvg";
import WalletTransitionItem from "../../../components/Payment/WalletTransitionItem/WalletTransitionItem";
import { getProfile, UserProfileResponse } from "../../../services/authService";
import {
  getTransctions,
  addDepositWallet,
  TransactionsResponse,
  addWithdrawWallet,
} from "../../../services/paymentService";
import NotifyAlert from "../../../components/NotifyAlert/NotifyAlert";
import YTypoGraphy from "../../../components/Text/YTypography/YTypoGraphy";
import { t } from "i18next";

const WalletPage = () => {
  const [results, setResults] = useState<any[]>([]);
  const [openWithDraw, setOpenWithDraw] = useState(false);
  const [openDeposit, setOpenDeposit] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState<UserProfileResponse | null>(null);
  const [transactionsData, setTransactionsData] =
    useState<TransactionsResponse>();
  const [showAlert, setShowAlert] = useState({
    isEnable: false,
    message: "",
    status: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    // const initialArray = [
    // { type: "success" },
    // { type: "danger" },
    // { type: "warning" },
    // ];
    // setResults(initialArray);
    getProfileFetch();
    getTransactionsFetch();
    setLoading(true);
    setError(null);
    localStorage.setItem("last_url", "/fa/payment/wallet");
  }, []);

  const getProfileFetch = async () => {
    try {
      const profileData = await getProfile();
      setProfile(profileData);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getTransactionsFetch = async () => {
    try {
      const transactionsData = await getTransctions();
      setTransactionsData(transactionsData);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  function withdrawBottomSheet() {
    setOpenWithDraw(!openWithDraw);
  }

  function depositBottomSheet() {
    setOpenDeposit(!openDeposit);
  }

  function guessNumberHandle() {
    navigate("/fa/payment/choose-number");
  }

  async function depositHandleCallback(amount: string) {
    setOpenDeposit(false);
    if (amount === "" || parseFloat(amount) < 1) {
      setShowAlert({
        isEnable: true,
        message: "مبلغ باید بالاتر از ۱ تتر باشد",
        status: false,
      });
    } else {
      setShowAlert({
        isEnable: true,
        message: "در حال انتقال به درگاه پرداخت",
        status: true,
      });

      setTimeout(async () => {
        const depositWallet = await addDepositWallet(amount);
        // console.log("deposit "+depositWallet);
        window.location.href = depositWallet.invoice_url;
        setShowAlert({ isEnable: false, message: "", status: true });
      }, 2000);
    }
  }

  async function withdrawHandleCallback(data: formWithdrawStructure) {
    setOpenWithDraw(false);
    if (1 >= parseFloat(data.amount)) {
      setShowAlert({
        isEnable: true,
        message: "مبلغ باید حداقل ۱ تتر باشد.",
        status: false,
      });
    } else if (
      parseFloat((profile?.user.balance_tether ?? 0).toString()) <=
      parseFloat(data.amount)
    ) {
      setShowAlert({
        isEnable: true,
        message: "درخواست برداشت بیش از موجودی کیف پول نمیتواند باشد.",
        status: false,
      });
    } else if (data.address === "") {
      setShowAlert({
        isEnable: true,
        message: "آدرس برای برداشت وارد نشده است",
        status: false,
      });
    } else if (data.amount === "" || parseFloat(data.amount) < 1) {
      setShowAlert({
        isEnable: true,
        message: "مبلغ باید بالاتر از ۱ تتر باشد",
        status: false,
      });
    } else {
      const depositWallet = await addWithdrawWallet(data.amount, data.address);
      if (!depositWallet.status)
        setShowAlert({
          isEnable: true,
          message: "ثبت برداشت با مشکل مواجه شد",
          status: false,
        });
      else
        setShowAlert({
          isEnable: true,
          message: "درخواست برداشت ثبت شد",
          status: true,
        });
    }
    getTransactionsFetch();
    setTimeout(async () => {
      setShowAlert({ isEnable: false, message: "", status: true });
    }, 2000);
  }

  return (
    <>
      {showAlert.isEnable ? (
        <NotifyAlert
          dir={""}
          float={""}
          color={showAlert.status ? `success` : "danger"}
          content={showAlert.message}
        />
      ) : (
        ""
      )}

      <Grid container mt={10} display={"block"} textAlign={"center"}>
        <Grid display={"inline-flex"}>
          <YTypoGraphy
            variant="h3"
            fontWeight={700}
            fontSize={"1.3rem"}
            textAlign={"center"}
            color={'white'}
            mt={0}
            mb={0}
            mr={2}
            pl={1}
          >
            {t('wallet')}
          </YTypoGraphy>
        </Grid>

        <Grid
          container
          className="balanceContainer"
          sx={{ position: "relative" }}
        >
          <YTypoGraphy variant="h4" className="amount">
            {profile?.user.balance_tether.toFixed(2)}
            <span>USDT</span>
          </YTypoGraphy>

          <YTypoGraphy variant="h5" className="balance">
          {t('wallet_balance')}
          </YTypoGraphy>

          <Grid
            container
            className="DepositWithDrawContainer"
            spacing={2}
            mb={0}
            p={2}
          >
            <Grid item xs={6}>
              <YButton
                mt={0}
                color={"deposit-withdraw"}
                align={"center"}
                radius={"radius-secondary"}
                width={"38vw"}
                height={42}
                mb={0.5}
                title={t('charge')}
                onClick={depositBottomSheet}
              />
            </Grid>

            <Grid item xs={6}>
              <YButton
                mt={0}
                color={"deposit-withdraw"}
                align={"center"}
                radius={"radius-secondary"}
                width={"38vw"}
                height={42}
                mb={0.5}
                title={t('withdraw')}
                onClick={withdrawBottomSheet}
              />
            </Grid>
          </Grid>

          <BalanceCardSvg dir={""} float={""} />

          {/* <path
              d="M36.1245 6.74492C38.266 3.09182 42.2133 0.84741 46.4964 0.847412L114.616 0.847413C118.942 0.847417 122.952 3.13637 125.128 6.84709L159.107 64.8102C161.283 68.5209 161.29 73.0858 159.128 76.7753L125.068 134.875C122.926 138.528 118.979 140.772 114.696 140.772L46.576 140.772C42.2502 140.772 38.2399 138.483 36.0646 134.773L2.08471 76.8096C-0.0906231 73.0989 -0.0983004 68.534 2.0646 64.8445L36.1245 6.74492Z"
              fill="#B353FF"
            /> */}
        </Grid>
      </Grid>

      <Grid container pr={2} mt={2} pl={2}>
        <Grid item xs={11}>
          <YTypoGraphy variant="h5" fontSize={"1rem"} color={'white'}>
            {t('transaction_list')}
          </YTypoGraphy>
        </Grid>
        <Grid item mr={0.5} textAlign={"left"}>
          <div>
            <FilterSvg dir={""} float={""}  color={'white'}/>
          </div>
        </Grid>
      </Grid>

      {(transactionsData?.transactions?.length ?? 0) > 0 ? (
        <>
          {transactionsData!.transactions.map((item, index) => (
            <WalletTransitionItem
              key={index}
              transID={item.transID}
              date={item.transaction_date}
              amount={item.amount}
              type={item.type}
              gateway={item.gateway}
              pay_address={item.pay_address}
              transaction_failed_reason={item.transaction_failed_reason}
              transaction_status={item.transaction_status}
              invoice_url={item.invoice_url}
            />
          ))}
        </>
      ) : (
        <>
          <Grid textAlign={"center"} mt={15}>
            <NoParticipationSvg dir={""} float={""} />
          </Grid>

          <YTypoGraphy variant="body1" textAlign={"center"} pr={5} pl={5} color={'white'}>
            هنوز مشارکتی نداشتی! اعداد رو حدس بزن و شانست رو امتحان کن
          </YTypoGraphy>

          <YButton
            mt={2}
            color={"primary"}
            align={"center"}
            radius={"radius-primary"}
            width={"45vw"}
            height={50}
            mb={0.5}
            onClick={guessNumberHandle}
            title={t('guess_number')}
          />
        </>
      )}

      <BottomSheet
        title={t('deposit')}
        description="لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است"
        open={openDeposit}
        setOpen={setOpenDeposit}
        isDark={true}
        hasWarning={true}
        isTransparent={false}
        children={
          <DepositBottomSheet
            depositHandleCallback={depositHandleCallback}
            balance={parseFloat(profile?.user.balance_tether.toFixed(2)??'0')}
          />
        }
      />

      <BottomSheet
        title={t('withdraw')}
        description="لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است"
        open={openWithDraw}
        setOpen={setOpenWithDraw}
        isDark={true}
        hasWarning={true}
        isTransparent={false}
        children={
          <WithDrawBottomSheet
            withdrawHandleCallback={withdrawHandleCallback}
            balance={parseFloat(profile?.user.balance_tether.toFixed(2)??'0')}
          />
        }
      />
    </>
  );
};

export default WalletPage;

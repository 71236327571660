import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import CountUp from 'react-countup';
import './CounterPrizeComponent.css';

interface CounterPrizeComponentProps {
  targetNumber: number|string;
  isLottery?: boolean;
  isMainPage?: boolean;
  fontSize?: number;
  hasAnimation?: boolean;
}

const CounterPrizeComponent: React.FC<CounterPrizeComponentProps> = ({ targetNumber, isLottery ,isMainPage,fontSize=28,hasAnimation=true}) => {
  const [displayNumber, setDisplayNumber] = useState(1);
  const [isCounting, setIsCounting] = useState(true);

  useEffect(() => {
    if(!hasAnimation){
      setIsCounting(false)
    }
    let count = 0;
    const interval = setInterval(() => {
      setDisplayNumber(prev => (prev < 50 ? prev + 1 : 1));
      count++;
      if (count >= 100) {
        clearInterval(interval);
        setIsCounting(false);
      }
    }, 10); // Adjust the speed as needed

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`counterContainer ${(targetNumber!==0||isMainPage) &&fontSize==28? 'bg-blue' :targetNumber!==0||isMainPage &&fontSize==16 ? 'bg-blue-medium':''}`}>
      
      <Typography variant="h4" color={'white'} className="counter-number" fontSize={fontSize} textAlign={'center'} display={'block'} position={'relative'}>
        {isCounting ? (
          displayNumber.toString().padStart(2, '0') // Ensure two-digit display
        ) : typeof targetNumber== 'number'&& hasAnimation?(
            
          <CountUp
            start={displayNumber}
            end={targetNumber}
            duration={2}
            // formattingFn={(value) => value.toString().padStart(0, '0')} // Format the end value as two digits
          />
        )
        // : Number.isNaN(targetNumber)?(
          // <span style={{ fontSize: '1rem' }}>•</span>
        // )
        :typeof targetNumber=='number' ?(
          <span>{targetNumber}</span>
        )
        : ('$')}
      </Typography>
    </div>
  );
};

export default CounterPrizeComponent;

import { Button } from "@mui/base";
import React from "react";
import "./EditSvg.css";

interface EditSvgProps {
  dir: string;
  float: string;
}
// { title, align, onClick }
// EditSvgProps
const EditSvg: React.FC<EditSvgProps> = ({ dir, float }) => {
  return (
    <div dir={dir} className={`editSvg ${float}`}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Iconly/Light/Edit">
          <g id="Edit">
            <path
              id="Stroke 1"
              d="M13.7474 20.4428H21"
              stroke="#2F2F2F"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Stroke 3"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.78 3.79479C13.5557 2.86779 14.95 2.73186 15.8962 3.49173C15.9485 3.53296 17.6295 4.83879 17.6295 4.83879C18.669 5.46719 18.992 6.80311 18.3494 7.82259C18.3153 7.87718 8.81195 19.7645 8.81195 19.7645C8.49578 20.1589 8.01583 20.3918 7.50291 20.3973L3.86353 20.443L3.04353 16.9723C2.92866 16.4843 3.04353 15.9718 3.3597 15.5773L12.78 3.79479Z"
              stroke="#2F2F2F"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Stroke 5"
              d="M11.0209 6.00098L16.4731 10.1881"
              stroke="#2F2F2F"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default EditSvg;

import { Grid, Typography } from '@mui/material';
import './PrizeCircle.css';

interface PrizeCircleProps {
  count: number;
  total?: number; // make total optional
  mt?: number | string; // Define custom margin-top prop
  mr?: number | string; // Define custom margin-right prop
  mb?: number | string; // Define custom margin-bottom prop
  ml?: number | string; // Define custom margin-left prop
  p?: number | string; // Define custom padding prop
  pt?: number | string; // Define custom padding-top prop
  pr?: number | string; // Define custom padding-right prop
  pb?: number | string; // Define custom padding-bottom prop
  pl?: number | string; // Define custom padding-left prop
}

const PrizeCircle = ({
  count,
  total = 6,
  mt,
  mr,
  mb,
  ml,
  p,
  pt,
  pr,
  pb,
  pl
}: PrizeCircleProps): JSX.Element => {
  const messages = [
    "یک حدس درست",
    "دو حدس درست",
    "سه حدس درست",
    "چهار حدس درست",
    "پنج حدس درست",
    "شش حدس درست"
  ];

  const spans = Array.from({ length: total }, (_, index) => (
    <span key={index} className={`prizeBlueCircle ${index < count ? 'active' : ''}`}></span>
  ));

  return (
    <Grid
      container
      columnGap={1}
      alignItems="center"
      sx={{
        mt,
        mr,
        mb,
        ml,
        p,
        pt,
        pr,
        pb,
        pl
      }}
      className='prizeBlueContainer'
    >
      {spans}
      {count > 0 && (
        <Typography className="message" fontSize={'0.9rem'} color={'white'} mt={0.4}>
          {messages[count - 1]}
        </Typography>
      )}
    </Grid>
  );
};

export default PrizeCircle;

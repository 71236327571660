import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Button,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import { styled } from "@mui/system";
import "./ChoosedNumber.css";
import YButton from "../../../components/Button/YButton";
import AddSvg from "../../../components/SVG/AddSvg/AddSvg";
import NotifyAlert from "../../../components/NotifyAlert/NotifyAlert";
import ChoosedNumberPay from "./ChoosedNumberPay";
import ChoosedNumberComponent from "./ChoosedNumberComponent";
import BottomSheet from "../../../components/BottomSheet/BottomSheet";
import ChoosedPayBottomSheet from "./ChoosedPayBottomSheet";
import { useNavigate } from "react-router-dom";
import {
  activeLottery,
  ChosenNumber,
  getChoosedNumbers,
} from "../../../services/lotteryService";
import { getProfile } from "../../../services/authService";
import {
  buyTicketByGateWay,
  buyTicketByWallet,
} from "../../../services/paymentService";

const numbers = Array.from({ length: 49 }, (_, i) => i + 1);

const NumberButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0.5),
  width: "auto",
  height: "auto",
  aspectRatio: "1/1",
  borderRadius: "10px",
  // backgroundColor:'#fff',
  // color:'rgba(47, 47, 47, 1)',
  boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.08)",
}));

const ChoosedNumber: React.FC = () => {
  const [chosenNumberList, setChosenNumberList] = useState<ChosenNumber[]>([]);
  const [lottery, setLottery] = useState<activeLottery>({
    lottery_date: new Date(),
    lottery_status: "",
    default_number: 0,
    ticket_price: 0,
  });

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const [showAlert, setShowAlert] = useState({
    isEnable: false,
    message: "",
    status: false,
  });

  function handleAddGuessNumber() {
    navigate("/fa/payment/choose-number");
  }

  const navigateRoute = (path: string) => {
    navigate(path);
  };

  async function getNumbers() {
    const numbers = await getChoosedNumbers({ status: "ALL" });
    if (numbers?.chosenNumbers && numbers.chosenNumbers.length > 0)
      setChosenNumberList(numbers?.chosenNumbers);
    else setChosenNumberList([]);

    if (numbers?.activeLottery) setLottery(numbers?.activeLottery);
    // setTimeout(async () => {
    //   setShowAlert({ isEnable: false, message: "", status: false });
    // }, 2000);
  }

  async function handlePayment() {
    const profile = await getProfile();
    const isPayWithWallet =
      parseFloat((chosenNumberList.length * lottery.ticket_price).toFixed(2)) <=
      parseFloat(profile.user.balance_tether.toFixed(2));
    console.log(profile);

  
      if (isPayWithWallet) {
      const buyWallet = await buyTicketByWallet();
      if (buyWallet.status) {
        setShowAlert({
          isEnable: true,
          message: "بلیط با موفقیت خریداری شد",
          status: true,
        });
        getNumbers();
        setTimeout(async () => {
          navigate("/fa");
          setShowAlert({ isEnable: false, message: "", status: true });
        }, 2000);
      } else {
        setShowAlert({
          isEnable: true,
          message: buyWallet.message,
          status: false,
        });
        setTimeout(async () => {
          setShowAlert({ isEnable: false, message: "", status: false });
        }, 2000);
      }
    } else {
      if (true) {
        setShowAlert({
          isEnable: true,
          message: "در حال انتقال به درگاه پرداخت",
          status: true,
        });

        setTimeout(async () => {
          const buyGateway = await buyTicketByGateWay();
          localStorage.setItem("last_url", "/fa/payment/wallet");
          // console.log("deposit "+depositWallet);
          window.location.href = buyGateway.invoice_url;
          setShowAlert({ isEnable: false, message: "", status: true });
        }, 2000);
      }
    }
  }

  useEffect(() => {
    getNumbers();
  }, []);

  function handleCallbackUpdateProfile() {}

  return (
    <Box className="container">
      {showAlert.isEnable ? (
        <NotifyAlert
          dir={""}
          float={""}
          color={showAlert.status ? `success` : "danger"}
          content={showAlert.message}
        />
      ) : (
        ""
      )}

      <Typography
        color={"rgba(47, 47, 47, 1)"}
        variant="h2"
        fontWeight={400}
        fontSize={"1.5rem"}
        textAlign={"center"}
        mt={1}
        mb={4}
      >
        اعداد انتخاب شده
      </Typography>
      {chosenNumberList.map((item, index) => (
        <ChoosedNumberComponent
          number_id={item.id}
          numbers={item.numbers}
          navigateRoute={navigateRoute}
          mt={2}
          mb={4}
        />
      ))}

      {chosenNumberList.length === 0 && (
        <YButton
          title={"افزودن"}
          color={"add"}
          align={"center"}
          radius={"radius-primary"}
          width={'90vw'}
          height={50}
          mb={4.5}
          mt={2}
          onClick={handleAddGuessNumber}
          iconPosition="right"
          icon={<AddSvg dir={""} float={""} />}
        />
      )}

      <ChoosedNumberPay
        numberOfGuess={chosenNumberList.length}
        ticketAmount={lottery.ticket_price}
        totalAmount={parseFloat(
          (chosenNumberList.length * lottery.ticket_price).toFixed(2)
        )}
      />

      <BottomSheet
        title="پرداخت دستی"
        open={open}
        setOpen={setOpen}
        isDark={true}
        children={
          <ChoosedPayBottomSheet handleUpdate={handleCallbackUpdateProfile} />
        }
      />

      <Box className="flex justify-between items-center w-full mt-4">
        <Grid container spacing={2} mb={5}>
          <Grid item xs={12} mt={3}>
            <YButton
              color={"primary"}
              align={"center"}
              radius={"radius-primary"}
              width={"85vw"}
              height={50}
              mb={0.5}
              title="پرداخت با ارزدیجیتال"
              onClick={() => handlePayment()}
              // onClick={() => setOpen(true)}
            />
          </Grid>

          {/* <Grid item xs={6}>
            <YButton
              mt={2}
              color={"primary"}
              align={"center"}
              radius={"radius-primary"}
              width={"45vw"}
              height={50}
              mb={0.5}
              title="پرداخت سیستمی"
              onClick={() => setOpen(true)}
            />
          </Grid>

          <Grid item xs={6}>
            <YButton
              mt={2}
              color={"primary"}
              align={"center"}
              radius={"radius-primary"}
              width={"45vw"}
              height={50}
              mb={0.5}
              title="پرداخت دستی"
              onClick={() => setOpen(true)}
            />
          </Grid> */}
        </Grid>
      </Box>
    </Box>
  );
};

export default ChoosedNumber;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.withDrawBalanceContainer {
  position: relative;
}
.withDrawBalanceContainer .lbl-balance {
    font-size: 18px;
    color: white;
    position: absolute;
    font-weight: 700;
    top: 14px;
    right: 16px;
}

.withDrawBalanceContainer .amount-dollar {
  position: absolute;
  top: 8px;
  left: 70px;
  font-size: 22px;
  color: white;
}

.withDrawBalanceContainer .amount-dollar span {
    font-weight: 400;
    font-family: 'Yekan Bakh';
    font-size: 14px;
    padding: 4px;;
}

.withDrawBalanceContainer .amount-toman {
  position: absolute;
  top: 38px;
  left: 70px;
  font-size: 18px;
  font-weight: 40;
  direction: rtl;
  color: white;
}

`, "",{"version":3,"sources":["webpack://./src/pages/Payment/Wallet/WithDrawBottomSheet.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;IACI,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,SAAS;IACT,WAAW;AACf;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,eAAe;EACf,YAAY;AACd;;AAEA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,eAAe;IACf,YAAY;AAChB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,eAAe;EACf,eAAe;EACf,cAAc;EACd,YAAY;AACd","sourcesContent":[".withDrawBalanceContainer {\n  position: relative;\n}\n.withDrawBalanceContainer .lbl-balance {\n    font-size: 18px;\n    color: white;\n    position: absolute;\n    font-weight: 700;\n    top: 14px;\n    right: 16px;\n}\n\n.withDrawBalanceContainer .amount-dollar {\n  position: absolute;\n  top: 8px;\n  left: 70px;\n  font-size: 22px;\n  color: white;\n}\n\n.withDrawBalanceContainer .amount-dollar span {\n    font-weight: 400;\n    font-family: 'Yekan Bakh';\n    font-size: 14px;\n    padding: 4px;;\n}\n\n.withDrawBalanceContainer .amount-toman {\n  position: absolute;\n  top: 38px;\n  left: 70px;\n  font-size: 18px;\n  font-weight: 40;\n  direction: rtl;\n  color: white;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.counterContainer{
    background-color: rgba(129, 129, 129, 0.43);
    width: 41px;
    /* height: 80px; */
    height: 44px;
    border-radius: 8px;
}
.counterContainer.bg-blue{
    background-color: rgba(22, 95, 238, 1);
    width: 41px;
    height: 44px;
}

.counterContainer.bg-blue-medium{
    background-color: rgba(22, 95, 238, 1);
    width: 35px;
    height: 38px;
}

.counter-number{
    margin:4px auto !important;
    padding-top: 12px;
    filter: blur(8px); /* Adjust the blur level to something more subtle */
    overflow: hidden;
}

.counterContainer.bg-blue .counter-number,.counterContainer.bg-blue-medium .counter-number{
    overflow: hidden;
    filter: none;
    margin:4px auto !important;
    /* padding-top: 12px; */
}

.counterContainer span {
    display: block;

}

.counterContainer span::first-letter {
    visibility:visible
}

`, "",{"version":3,"sources":["webpack://./src/components/Numbers/Counter/CounterPrizeComponent.css"],"names":[],"mappings":"AAAA;IACI,2CAA2C;IAC3C,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,sCAAsC;IACtC,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,sCAAsC;IACtC,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,0BAA0B;IAC1B,iBAAiB;IACjB,iBAAiB,EAAE,mDAAmD;IACtE,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,0BAA0B;IAC1B,uBAAuB;AAC3B;;AAEA;IACI,cAAc;;AAElB;;AAEA;IACI;AACJ","sourcesContent":[".counterContainer{\n    background-color: rgba(129, 129, 129, 0.43);\n    width: 41px;\n    /* height: 80px; */\n    height: 44px;\n    border-radius: 8px;\n}\n.counterContainer.bg-blue{\n    background-color: rgba(22, 95, 238, 1);\n    width: 41px;\n    height: 44px;\n}\n\n.counterContainer.bg-blue-medium{\n    background-color: rgba(22, 95, 238, 1);\n    width: 35px;\n    height: 38px;\n}\n\n.counter-number{\n    margin:4px auto !important;\n    padding-top: 12px;\n    filter: blur(8px); /* Adjust the blur level to something more subtle */\n    overflow: hidden;\n}\n\n.counterContainer.bg-blue .counter-number,.counterContainer.bg-blue-medium .counter-number{\n    overflow: hidden;\n    filter: none;\n    margin:4px auto !important;\n    /* padding-top: 12px; */\n}\n\n.counterContainer span {\n    display: block;\n\n}\n\n.counterContainer span::first-letter {\n    visibility:visible\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.depositBalanceContainer {
    position: relative;
  }
  .depositBalanceContainer .lbl-balance {
      font-size: 18px;
      color: white;
      position: absolute;
      font-weight: 700;
      top: 14px;
      right: 16px;
  }
  
  .depositBalanceContainer .amount-dollar {
    position: absolute;
    top: 8px;
    left: 70px;
    font-size: 22px;
    color: white;
  }
  
  .depositBalanceContainer .amount-dollar span {
      font-weight: 400;
      font-family: 'Yekan Bakh';
      font-size: 14px;
      padding: 4px;;
  }
  
  .depositBalanceContainer .amount-toman {
    position: absolute;
    top: 38px;
    left: 70px;
    font-size: 18px;
    font-weight: 40;
    direction: rtl;
    color: white;
  }
  
  `, "",{"version":3,"sources":["webpack://./src/pages/Payment/Wallet/DepositBottomSheet.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;EACpB;EACA;MACI,eAAe;MACf,YAAY;MACZ,kBAAkB;MAClB,gBAAgB;MAChB,SAAS;MACT,WAAW;EACf;;EAEA;IACE,kBAAkB;IAClB,QAAQ;IACR,UAAU;IACV,eAAe;IACf,YAAY;EACd;;EAEA;MACI,gBAAgB;MAChB,yBAAyB;MACzB,eAAe;MACf,YAAY;EAChB;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,eAAe;IACf,eAAe;IACf,cAAc;IACd,YAAY;EACd","sourcesContent":[".depositBalanceContainer {\n    position: relative;\n  }\n  .depositBalanceContainer .lbl-balance {\n      font-size: 18px;\n      color: white;\n      position: absolute;\n      font-weight: 700;\n      top: 14px;\n      right: 16px;\n  }\n  \n  .depositBalanceContainer .amount-dollar {\n    position: absolute;\n    top: 8px;\n    left: 70px;\n    font-size: 22px;\n    color: white;\n  }\n  \n  .depositBalanceContainer .amount-dollar span {\n      font-weight: 400;\n      font-family: 'Yekan Bakh';\n      font-size: 14px;\n      padding: 4px;;\n  }\n  \n  .depositBalanceContainer .amount-toman {\n    position: absolute;\n    top: 38px;\n    left: 70px;\n    font-size: 18px;\n    font-weight: 40;\n    direction: rtl;\n    color: white;\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

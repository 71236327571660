// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-container{
    
}

.bottom-card{
    background-color: rgba(0, 0, 0, 0.7);
    width: 100vw;
    /* width: 428px; */
    height: 185px;
    position:fixed;
    bottom: 0;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
}
@media only screen and (min-width : 992px) {
    .bottom-card{
        width: 428px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Numbers/BottomValueCard/BottomValueCard.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,oCAAoC;IACpC,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,cAAc;IACd,SAAS;IACT,4BAA4B;IAC5B,6BAA6B;AACjC;AACA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":[".card-container{\n    \n}\n\n.bottom-card{\n    background-color: rgba(0, 0, 0, 0.7);\n    width: 100vw;\n    /* width: 428px; */\n    height: 185px;\n    position:fixed;\n    bottom: 0;\n    border-top-left-radius: 40px;\n    border-top-right-radius: 40px;\n}\n@media only screen and (min-width : 992px) {\n    .bottom-card{\n        width: 428px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from "react";
import { Grid, Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/system";
import "./../ChooseNumber.css";
import YButton from "../../../../components/Button/YButton";
import RandomSvg from "../../../../components/SVG/RandomSvg/RandomSvg";
import { useNavigate } from "react-router-dom";
import NotifyAlert from "../../../../components/NotifyAlert/NotifyAlert";
import DeleteSvg from "../../../../components/SVG/DeleteSvg/DeleteSvg";
import ChoosedNumber from "../../ChoosedNumber/page";
import { addChoosedNumber, editChoosedNumber, getChoosedNumbers, getSingleChoosedNumber } from "../../../../services/lotteryService";

const numbers = Array.from({ length: 49 }, (_, i) => i + 1);

const NumberButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0.5),
  width: "auto",
  height: "auto",
  aspectRatio: "1/1",
  minWidth: "55px",
  borderRadius: "10px",
  // backgroundColor:'#fff',
  // color:'rgba(47, 47, 47, 1)',
  boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.08)",
}));

const EditChooseNumber: React.FC = () => {
  const defaultChoosedNumber = 25;
  const [showAlert, setShowAlert] = useState({
    isEnable: false,
    message: "",
    status: false,
  });

  const [selectedNumbers, setSelectedNumbers] = useState<number[]>([
    defaultChoosedNumber,
  ]);
  const navigate = useNavigate();

  const handleNumberClick = (number: number) => {
    const isDefault = number === defaultChoosedNumber;

    if (selectedNumbers.includes(number) && !isDefault) {
      setSelectedNumbers(selectedNumbers.filter((n) => n !== number));
    } else if (
      selectedNumbers.length < 6 &&
      !selectedNumbers.includes(number)
    ) {
      setSelectedNumbers([...selectedNumbers, number]);
    }
    console.log(selectedNumbers);
  };

  const handleRandomSelect = () => {
    console.log(selectedNumbers);
    console.log(selectedNumbers.length);
    let randNumber = Math.floor(Math.random() * 49);
    if (selectedNumbers.includes(randNumber)) {
      handleRandomSelect();
    } else if (selectedNumbers.length < 6 && randNumber !== 0) {
      setSelectedNumbers([...selectedNumbers, randNumber]);
    }
  };

  async function goToChoosedNumber() {
    if (selectedNumbers.length < 6) {
      setShowAlert({
        isEnable: true,
        message: "باید کل اعداد انتخاب شود!",
        status: false,
      });
      setTimeout(async () => {
        setShowAlert({ isEnable: false, message: "", status: false });
      }, 2000);
    } else{
      if(localStorage.getItem('edit_number_id')!==null){
      const added = await editChoosedNumber(parseInt(localStorage.getItem('edit_number_id')??'0'),selectedNumbers);
      if(added){
        if(added.status)
        navigate("/fa/payment/choosed-number");
        setShowAlert({ isEnable: true, message:added.message, status: false }); 
      }else{
        setShowAlert({ isEnable: true, message:'خطایی رخ داد', status: false}); 
      }
      }
    }
    setTimeout(async () => {
      setShowAlert({ isEnable: false, message: '', status: false });
    }, 2000); 
  }


  async function getChoosed(){
      if(localStorage.getItem('edit_number_id')!==null){
        const choosed = await getSingleChoosedNumber(parseInt(localStorage.getItem('edit_number_id')??'0'))
        if(choosed?.chosenNumbers?.numbers!=undefined)
          setSelectedNumbers(choosed?.chosenNumbers.numbers)
        else
          navigate('/fa/payment/choose-number')
        // if()
        // const cgetSingleChoosedNumber(choosed?.chosenNumbers[0].numbers)
      }
      
  }


  useEffect(() => {
      getChoosed()
  }, []);
  

  return (
    <Box className="container">
      {showAlert.isEnable ? (
        <NotifyAlert
          dir={""}
          float={""}
          color={showAlert.status ? `success` : "danger"}
          content={showAlert.message}
        />
      ) : (
        ""
      )}

      <Grid container spacing={1}>
      <Grid item xs={6}>
          <Typography
            color={"rgba(47, 47, 47, 1)"}
            fontWeight={400}
            fontSize={"1.2rem"}
            textAlign={"center"}
            mt={1}
          >
            اعداد شانس این دوره
          </Typography>
          <Typography
            color={"rgba(47, 47, 47, 1)"}
            fontSize={"14px"}
            textAlign={"center"}
            mt={0.5}
          >
            شش عدد بعدی را حدس بزنید
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <br />
          <YButton
            title={"انتخاب تصادفی"}
            color={"primary-outline"}
            align={"center"}
            radius={"radius-primary"}
            onClick={handleRandomSelect}
            width={175}
            height={45}
            mb={0.5}
            mr={0.5}
            iconPosition={"right"}
            icon={<RandomSvg dir={""} float={""} />}
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        justifyContent="left"
        // direction={"row-reverse"}
        mt={2}
        mb={4}
      >
        
        {Array.from(
          { length: 6 - selectedNumbers.length },
          (_, i) => i + 1
        ).map((number) => (
          <Grid item key={number} xs={2}>
            <NumberButton
              variant={"contained"}
              // variant={selectedNumbers.includes(number) ? 'contained' : 'outlined'}
              className={"btn-not-selected-top"}
              // className={ number===1?'btn-selected-filled':'btn-not-selected'}
              style={{ border: "0.8px solid rgba(149, 149, 149, 1)" }}
            >
              {number}
            </NumberButton>
          </Grid>
        ))}


      {selectedNumbers.slice().reverse().map((number, index) => (
          <Grid item key={number} xs={2}>
            <NumberButton
              variant={"contained"}
              className={"btn-selected-filled"}
              style={{ border: "0.8px solid rgba(149, 149, 149, 1)" }}
            >
              {number}
            </NumberButton>

            <DeleteSvg
              dir={""}
              float={""}
              isRelative={true}
              mt={6}
              mr={25}
              onClick={() => handleNumberClick(number)}
              color={index + 1 === selectedNumbers.length ? "white" : undefined} // Apply white color to the first item
            />
          </Grid>
        ))}
        
      </Grid>

      <hr
        style={{
          width: "100%",
          borderTop: "1px dashed black",
          margin: "16px 0",
        }}
      />

      <Grid
        container
        spacing={0.5}
        justifyContent="left"
        direction={"row-reverse"}
      >
        {numbers.map((number) => (
          <Grid item key={number} spacing={5} xs={2}>
            <NumberButton
              variant={"contained"}
              // variant={selectedNumbers.includes(number) ? 'contained' : 'outlined'}
              className={
                selectedNumbers.includes(number)
                  ? "btn-selected"
                  : "btn-not-selected"
              }
              onClick={() => handleNumberClick(number)}
            >
              {number}
            </NumberButton>
          </Grid>
        ))}
      </Grid>

      <Box className="flex justify-between items-center w-full mt-4">
        <YButton
          mt={2}
          color={"primary"}
          align={"center"}
          radius={"radius-primary"}
          width={"395px"}
          height={50}
          mb={0.5}
          title="ادامه"
          onClick={goToChoosedNumber}
          // onClick={() => console.log('Selected numbers:', selectedNumbers)}
        />
      </Box>
    </Box>
  );
};

export default EditChooseNumber;

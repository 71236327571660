import React from "react";
import { Box, Paper, Card, Grid } from "@mui/material";
import ResultItemNumber from "./ResultItemNumber";
import YButton from "../../Button/YButton";
import { useNavigate } from "react-router-dom";
import YTypoGraphy from "../../Text/YTypography/YTypoGraphy";
import { t } from "i18next";

interface ResultItemProps {
  date: string;
  luckyNumbers: number[];
  userChoosedNumbers?: number[];
  userGuess: number[];
  amountWon: number;
  numberOfCorrectGuesses: number;
  lotteryStatus:
    | "WAITING_FOR_PAYMENT"
    | "WATING_FOR_START_LOTTERY"
    | "FINISHED_LOTTERY"; // Use your exact enum values
  isWin: boolean;
  lotteryRemainDate: string;
}

const ResultItem: React.FC<ResultItemProps> = ({
  date,
  luckyNumbers,
  userChoosedNumbers,
  userGuess,
  amountWon,
  numberOfCorrectGuesses,
  lotteryStatus,
  isWin,
  lotteryRemainDate,
}) => {
  const navigate = useNavigate();

  function handleChooseNumber() {
    navigate("/fa/payment/choose-number");
  }
  
  function handleIncreaseGuess() {
    navigate("/fa/payment/choosed-number");
  }

  return (
    <Paper
      elevation={2}
      sx={{
        background:
          // "linear-gradient(90deg, rgba(229, 66, 255, 0.8) 0%, rgba(137, 40, 153, 0.8) 100%)",
          "#0000000D",
        padding: 0,
        borderRadius: 4,
        maxWidth: "95%",
        margin: "16px auto",
      }}
    >
      <Box
        display={"flex"}
        sx={{
          background:
            "#ffffff38",
          // backgroundColor: `${
          //   lotteryStatus === "FINISHED_LOTTERY" && isWin //success
          //     ? "rgba(0, 158, 16, 1)"
          //     : !isWin && lotteryStatus === "FINISHED_LOTTERY" //danger
          //     ? "rgba(209, 0, 0, 1)"
          //     : lotteryStatus === "WATING_FOR_START_LOTTERY" || lotteryStatus === "WAITING_FOR_PAYMENT" //warning
          //     ? "rgba(255, 153, 0, 1)"
          //     : ""
          // }`,
          color: "white",
          padding: 1,
          textAlign: "center",
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
        }}
      >
        <YTypoGraphy variant="h6" fontSize={"0.875rem"} mr={1} mt={1}>
          {lotteryStatus === "FINISHED_LOTTERY" && isWin
            ? "چه خوش شانس! برنده شدید"
            : !isWin && lotteryStatus === "FINISHED_LOTTERY"
            ? "اینبار شانس باهات یار نبود!"
            : lotteryStatus === "WATING_FOR_START_LOTTERY"
            ? "هنوز وقتش نشده"
            : lotteryStatus === "WAITING_FOR_PAYMENT"
            ? "در انتظار پرداخت"
            : ""}
        </YTypoGraphy>
        <YTypoGraphy
          variant="subtitle1"
          textAlign={"left"}
          sx={{ flexGrow: 1, marginLeft: "8px" }}
        >
          تاریخ {date}
        </YTypoGraphy>
      </Box>
      <Box sx={{ padding: 1 }}>
        <Card
          sx={{
            backgroundColor: "rgba(239, 239, 239, 1)",
            width: "264px",
            textAlign: "center",
            margin: "8px auto",
            display: "block",
            borderRadius: "16px",
          }}
        >
          <YTypoGraphy variant="h5" fontSize={"1rem"} mt={2}>
          {t('guess_numbers')}
          </YTypoGraphy>
          <Grid container spacing={1} p={2}>
            {luckyNumbers
              .slice()
              .reverse()
              .map((number, index) => (
                <Grid item>
                  <ResultItemNumber
                    luckyNumbers={luckyNumbers}
                    userGuess={[]}
                    defaultColor={"rgba(47, 47, 47, 1)"}
                    number={number}
                    index={index}
                    isBlur={
                      lotteryStatus === "WATING_FOR_START_LOTTERY" ||
                      lotteryStatus === "WAITING_FOR_PAYMENT"
                    }
                  />
                </Grid>
              ))}
          </Grid>
        </Card>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <YTypoGraphy variant="h5" color={'white'} mt={3} mr={1} fontSize={"1.1rem"}>
              حدس اول:
            </YTypoGraphy>
          </Grid>

          <Grid item xs={8} textAlign={"left"}>
            <Grid container spacing={1} mt={1}>
              {userChoosedNumbers
                ?.slice()
                .reverse()
                .map((number, index) => (
                  <Grid item>
                    <ResultItemNumber
                      luckyNumbers={userChoosedNumbers}
                      userGuess={userGuess}
                      number={number}
                      index={index}
                      isBlur={false}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={4} textAlign={"left"}>
            <YTypoGraphy
              variant="h5"
              fontSize={"1.2rem"}
              textAlign={"right"}
              mr={1}
              sx={{
                color:
                  lotteryStatus === "FINISHED_LOTTERY" && isWin
                    ? "#fff"
                    // ? "rgba(0, 158, 16, 1)"
                    : !isWin && lotteryStatus === "FINISHED_LOTTERY"
                    ? "rgba(209, 0, 0, 1)"
                    : lotteryStatus === "WATING_FOR_START_LOTTERY"
                    ? "rgba(255, 153, 0, 1)"
                    : "",
                marginTop: 1,
              }}
            >
              {lotteryStatus === "FINISHED_LOTTERY" && isWin ? (
                <>
                  <svg
                    width="24"
                    height="22"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 7L10 17L5 12"
                      stroke="#009E10"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span style={{ position: "absolute", fontWeight: 500 }}>
                    {numberOfCorrectGuesses} حدس درست
                  </span>
                </>
              ) : !isWin && lotteryStatus === "FINISHED_LOTTERY" ? (
                <>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_37_5697"
                      maskUnits="userSpaceOnUse"
                      x="2"
                      y="3"
                      width="21"
                      height="19"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2 3H22.0137V21.1855H2V3Z"
                        fill="white"
                      />
                    </mask>
                    <g mask="url(#mask0_37_5697)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.0125 4.5C11.5365 4.5 11.1125 4.746 10.8745 5.159L3.67652 17.724C3.44253 18.134 3.44453 18.623 3.68152 19.032C3.91852 19.441 4.34253 19.686 4.81553 19.686H19.1985C19.6705 19.686 20.0945 19.441 20.3315 19.032C20.5695 18.623 20.5715 18.134 20.3355 17.724L13.1505 5.159C12.9135 4.746 12.4895 4.5 12.0125 4.5ZM19.1985 21.186H4.81553C3.80152 21.186 2.89253 20.662 2.38353 19.784C1.87453 18.907 1.87153 17.858 2.37453 16.979L9.57452 4.413C10.0805 3.528 10.9915 3 12.0125 3H12.0135C13.0335 3 13.9465 3.529 14.4525 4.415L21.6385 16.979C22.1415 17.858 22.1385 18.907 21.6295 19.784C21.1205 20.662 20.2115 21.186 19.1985 21.186Z"
                        fill="#D10000"
                      />
                    </g>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.0039 14.164C11.5899 14.164 11.2539 13.828 11.2539 13.414V10.314C11.2539 9.89996 11.5899 9.56396 12.0039 9.56396C12.4179 9.56396 12.7539 9.89996 12.7539 10.314V13.414C12.7539 13.828 12.4179 14.164 12.0039 14.164Z"
                      fill="#D10000"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.006 17.499C11.453 17.499 11.001 17.052 11.001 16.499C11.001 15.946 11.444 15.499 11.996 15.499H12.006C12.559 15.499 13.006 15.946 13.006 16.499C13.006 17.052 12.559 17.499 12.006 17.499Z"
                      fill="#D10000"
                    />
                  </svg>

                  <span style={{ position: "absolute", fontWeight: 500 }}>
                    متاسفانه حدس درستی نداشتید{" "}
                  </span>
                </>
              ) : lotteryStatus === "WATING_FOR_START_LOTTERY" ? (
                <>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.665 3.5C5.135 3.5 3.5 5.233 3.5 7.916V16.084C3.5 18.767 5.135 20.5 7.665 20.5H16.333C18.864 20.5 20.5 18.767 20.5 16.084V7.916C20.5 5.233 18.865 3.5 16.334 3.5H7.665ZM16.333 22H7.665C4.276 22 2 19.622 2 16.084V7.916C2 4.378 4.276 2 7.665 2H16.334C19.723 2 22 4.378 22 7.916V16.084C22 19.622 19.723 22 16.333 22Z"
                      fill="#E78A00"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.39 14.7678C15.259 14.7678 15.127 14.7338 15.006 14.6628L11.615 12.6398C11.389 12.5038 11.249 12.2588 11.249 11.9958V7.63379C11.249 7.21979 11.585 6.88379 11.999 6.88379C12.413 6.88379 12.749 7.21979 12.749 7.63379V11.5698L15.775 13.3728C16.13 13.5858 16.247 14.0458 16.035 14.4018C15.894 14.6368 15.645 14.7678 15.39 14.7678Z"
                      fill="#E78A00"
                    />
                  </svg>

                  <span
                    style={{
                      position: "absolute",
                      fontSize: "1.1rem",
                      paddingTop: "4px",
                      paddingRight: "4px",
                      fontWeight: 500,
                    }}
                  >
                    {lotteryRemainDate} مانده تا قرعه کشی
                  </span>
                </>
              ) : (
                ""
              )}
            </YTypoGraphy>

            {lotteryStatus === "WATING_FOR_START_LOTTERY" ? (
              <YButton
                title={"افزایش شانس"}
                color={"increase-guess"}
                align={"center"}
                radius={"radius-primary"}
                width={"84vw"}
                height={50}
                mr={0.8}
                mb={0.5}
                mt={1}
                onClick={handleChooseNumber}
              />
            ) : (
              ""
            )}

            {lotteryStatus === "WAITING_FOR_PAYMENT" ? (
              <YButton
                title={"افزایش شانس"}
                color={"increase-guess"}
                align={"center"}
                radius={"radius-primary"}
                width={"90vw"}
                height={50}
                mb={0.5}
                mt={1}
                onClick={handleIncreaseGuess}
              />
            ) : (
              ""
            )}
          </Grid>
          {lotteryStatus === "FINISHED_LOTTERY" ? (
            <Grid item xs={8} textAlign={"left"}>
              <YTypoGraphy
                variant="h5"
                fontSize={"1rem"}
                ml={2}
                sx={{ color: "white", marginTop: 1 }}
              >
                {amountWon.toLocaleString()} تتر
              </YTypoGraphy>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Box>
    </Paper>
  );
};

export default ResultItem;
